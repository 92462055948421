// Footer.js
import React from 'react';
import "./footer.css"

const Footer = () => {
  return (
    <div className='footerCont'>
      <p>&copy; 2023 United Nations Development Programme</p>
    </div>
  );
};

export default Footer;

import React, { useEffect, useRef, useState } from "react";
import { HiOutlineChevronDown } from "react-icons/hi";

export const Inputs = {
  UseInput: ({ type, placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);
    const input = (
      <input
        onChange={(e) => setValue(e.target.value)}
        className={className}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name=""
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );
    return [value, input, setValue];
  },
  UseAlphabeticInput: ({ placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
      const inputValue = e.target.value;

      // Replace all characters except alphabets
      // const formattedValue = inputValue.replace(/[^a-zA-Z]/g, '');
      const formattedValue = inputValue.replace(/[^a-zA-Z\s]/g, '');

      setValue(formattedValue);
    };

    const input = (
      <input
        onChange={onChange}
        className={className}
        type="text" // Use 'text' type to allow only alphabetic characters
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name=""
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );

    return [value, input, setValue];
  },
  UseEmailInput: ({ type, placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
      const inputValue = e.target.value;
      // Regular expression for email validation
      const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      // Check if the input value matches the email regex
      const isValid = emailRegex.test(inputValue);

      setValue(inputValue);

      // Apply a border color to indicate validity
      e.target.style.borderColor = isValid ? '' : 'red';
    };

    const input = (
      <input
        onChange={onChange}
        className={className}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name=""
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );

    return [value, input, setValue];
  },
  UseNumberInput: ({ type, placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
      let inputValue = e.target.value;
      // Check if the input value is numeric
      if (!isNaN(inputValue)) {
        if (inputValue >= 0 && inputValue <= 100) {
          setValue(inputValue);
        }
      }
    };

    const input = (
      <input
        onChange={onChange}
        className={className}
        type={type}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        name=""
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );

    return [value, input, setValue];
  },
  UseMobileNumberInput: ({ placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
      let inputValue = e.target.value;

      // If the field is empty, allow user to type '+92'
      if (inputValue.length === 0) {
        inputValue = '+92';
      } else {
        // Remove all non-numeric characters
        inputValue = inputValue.replace(/[^+0-9]/g, '');

        // Ensure that '+' is allowed only at the beginning
        if (!inputValue.startsWith('+92')) {
          inputValue = '+92' + inputValue.slice(3);
        }

        // Limit input length to 12 characters for Pakistani numbers
        inputValue = inputValue.slice(0, 13);
      }

      setValue(inputValue);
    };


    const input = (
      <input
        onChange={onChange}
        className={className}
        type="text"
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder={placeholder}
        value={value}
        name=""
        disabled={disabled}
      />

    );

    return [value, input, setValue];
  },
  UseTimeLineNumberInput: ({ placeholder, className, defaultValue, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    const onChange = (e) => {
      let inputValue = e.target.value;
      // if(placeholder === "month"){
      inputValue = Math.min(inputValue, 12).toString();
      // }
      // Replace all characters except digits and dashes
      inputValue = inputValue.replace(/[^\d-]/g, '');
      // Limit input length to 12 characters for Pakistani numbers
      if (inputValue.length > 2) {
        inputValue = inputValue.slice(0, 2);
      }

      setValue(inputValue);
    };

    const input = (
      <input
        onChange={onChange}
        className={className}
        type="text"
        // placeholder={placeholder}
        value={value}
        disabled={disabled}
        name=""
        autoComplete="new-password"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />
    );

    return [value, input, setValue];
  },
  UseSelectSector: ({ placeholder, options, feildName, inputDiv }) => {
    const [isSectorDropDownVisible, setIsSectorDropDownVisible] = useState(false);
    const [value, setValue] = useState(placeholder);
    const [valueIcon, setValueIcon] = useState('');
    const [id, setId] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setTimeout(() => {
            setSearchTerm('');
            setIsSectorDropDownVisible(false);
          }, 250);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);

    const selectHandle = (selectedId) => {
      if (selectedId !== "0") {
        const find = options.find((e) => e.id === selectedId);
        setValue(find.name);
        setId(find.id);
        setValueIcon(find.File_Url);
      } else {
        // Handle error
        // console.log("Please select " + feildName);
      }
      setSearchTerm('');
      setIsSectorDropDownVisible(false);
    };
    const filteredOptions = options.filter((option) =>
      option?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );

    const customSelect = (
      <>
        <div className={`CustomSelectInputCont pointer-cursor ${inputDiv.sector ? 'CustomSelectInputContErrorBorder' : 'CustomSelectInputCont'}`} onClick={() => setIsSectorDropDownVisible(true)}>
          {valueIcon ? <div className="selectInputImg">
            <img className="sector-icon" src={`https://admin.sdg.hexalyze.com${valueIcon}`} alt="" />
          </div> : null}
          <div className="selectInput">
            <span>{value.length > 20 ? value.slice(0, 20) + "..." : value}</span>
          </div>
          <div className="selectInputDownIconCont">
            <HiOutlineChevronDown className="selectInputDownIcon" />
          </div>
        </div>
        {isSectorDropDownVisible && (
          <div ref={dropdownRef} className="CustomselectInputOptionCont pointer-cursor">
            <input
              type="text"
              className="searchable-drop"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="filteredOptions">
              {filteredOptions.map((v, i) => (
                <span key={i} onClick={() => selectHandle(v.id)}>
                  <img className="CustomselectInputOptionContImg" src={`https://admin.sdg.hexalyze.com${v.File_Url}`} alt="" />
                  {v.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </>
    );

    return [id, customSelect, value];
  },
  UseSelectCountry: ({ placeholder, options, feildName, className, inputDiv }) => {
    const [isCountryDropDownVisible, setIsCountryDropDownVisible] = useState(false);
    const [value, setValue] = useState(placeholder);
    const [id, setId] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setTimeout(() => {
            setSearchTerm('');
            setIsCountryDropDownVisible(false);
          }, 250);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);

    const selectHandle = (selectedId) => {
      if (selectedId !== '0') {
        const find = options.find((e) => e.id === selectedId);
        setValue(find.name);
        setId(find.id);
      } else {
        // Handle error
        // console.log("Please select " + feildName);
      }
      setSearchTerm('');
      setIsCountryDropDownVisible(false);
    };

    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const customSelect = (
      <>
        <div
          className={`CustomSelectInputCont pointer-cursor ${inputDiv.country ? 'CustomSelectInputContErrorBorder' : 'CustomSelectInputCont'
            }`}
          onClick={() => setIsCountryDropDownVisible(true)}
        >
          <div className="selectInput">
            <span>{value}</span>
          </div>
          <div className="selectInputDownIconCont">
            <HiOutlineChevronDown className="selectInputDownIcon" />
          </div>
        </div>
        {isCountryDropDownVisible && (
          <div ref={dropdownRef} className="CustomselectInputOptionCont pointer-cursor">
            <input
              type="text"
              className="searchable-drop"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="filteredOptions">
              {filteredOptions.map((v, i) => (
                <span key={i} onClick={() => selectHandle(v.id)}>
                  {v.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </>
    );

    return [id, customSelect, setValue];
  },
  UseSelectCity: ({ placeholder, options, feildName, inputDiv }) => {
    const [isCityDropDownVisible, setIsCityDropDownVisible] = useState(false);
    const [value, setValue] = useState(placeholder);
    const [id, setId] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    const dropdownRef = useRef(null);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setTimeout(() => {
            setSearchTerm('');
            setIsCityDropDownVisible(false);
          }, 250);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);

    const CityDropDown = () => {
      setIsCityDropDownVisible(!isCityDropDownVisible);
    };

    const selectHandle = (selectedId) => {
      if (selectedId !== '0') {
        const find = options.find((e) => e.id === selectedId);
        setValue(find.name);
        setId(find.id);
      } else {
        // Handle error
        // console.log("Please select " + feildName);
      }
      setIsCityDropDownVisible(false);
    };

    const filteredOptions = options.filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const customSelect = (
      <>
        <div
          className={`CustomSelectInputCont pointer-cursor ${inputDiv.city ? 'CustomSelectInputContErrorBorder' : 'CustomSelectInputCont'
            }`}
          onClick={options.length >= 1 ? CityDropDown : null}
        >
          <div className="selectInput">
            <span>{value}</span>
          </div>
          <div className="selectInputDownIconCont">
            <HiOutlineChevronDown className="selectInputDownIcon" />
          </div>
        </div>
        {isCityDropDownVisible && (
          <div ref={dropdownRef} className="CustomselectInputOptionCont pointer-cursor">
            <input
              type="text"
              className="searchable-drop"
              placeholder="Search..."
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="filteredOptions">
              {filteredOptions.map((v, i) => (
                <span key={i} onClick={() => selectHandle(v.id)}>
                  {v.name}
                </span>
              ))}
            </div>
          </div>
        )}
      </>
    );

    return [id, customSelect, setValue];
  },
  CurrencySelect: ({ options, togglePriceDropDown, inputDiv }) => {
    const dropdownRef = useRef(null);
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsDropDownVisible(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [dropdownRef]);
    const [value, setValue] = useState("");
    const [id, setID] = useState("");
    const [isDropDownVisible, setIsDropDownVisible] = useState(false);
    const toggleDropDown = () => {
      setIsDropDownVisible(!isDropDownVisible);
    };
    const selectedIcon = options?.find((e) => e.selected);
    useEffect(() => {
      console.log('selectedIcon', selectedIcon);
      if (selectedIcon && options) {
        setID(selectedIcon.id);
      }
    }, [selectedIcon, options]);

    const formatNumberWithCommas = (input) => {
      const number = typeof input === 'number' ? input.toString() : input || "";
      if (number === "NaN") return '';
      if (!number) return ''; // Handle empty values
      const parts = number.replace(/,/g, '').toString().split('.');
      const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      let decimalPart = parts[1]; // Handle cases where there's no decimal part
      // Limit the decimal part to two digits
      if (decimalPart && decimalPart.length > 2) {
        decimalPart = decimalPart.slice(0, 2);
      }
      // Combine integer and decimal parts with a dot
      const formattedNumber = (decimalPart === '' || (decimalPart && decimalPart.length > 0)) ? `${integerPart}.${decimalPart}` : integerPart;
      return formattedNumber;
    };

    const customSelect = (
      <div className={`priceCostCont ${inputDiv.project_cost ? 'priceCostContErrorBorder' : 'priceCostCont'}`}>
        <div className='dropDownCont pointer-cursor' onClick={() => toggleDropDown()}>
          {selectedIcon?.selected &&
            <p style={{ color: '#FDBA74' }}>{selectedIcon?.symbol}</p>
          }
          <img className='dropDownIcon' src='/assits/Icon/priceCostDropDownIcon.png' alt='Dollar-Icon' />
        </div>
        {isDropDownVisible && (
          <div ref={dropdownRef} className='priceCostContDropDown'>
            {options?.map((v, i) => {
              return (
                <div style={{ fontWeight: 'bolder', cursor: "pointer", margin: '3%', width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }} className='currencyOption pointer-cursor' key={i} onClick={() => { setID(v.id); togglePriceDropDown(v.id); setIsDropDownVisible(!isDropDownVisible); }}>
                  <p>{v?.symbol}</p>
                </div>
              );
            })}
          </div>
        )}
        <div className='inputbackGround'>
          <input
            className='input border'
            type='text'
            placeholder={'9999'}
            value={formatNumberWithCommas(value)}
            // onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))}
            onChange={(e) => {
              const inputValue = e.target.value.replace(/\D/g, ''); // Only allow numbers
              const truncatedValue = inputValue.slice(0, 20); // Truncate to 16 characters
              setValue(truncatedValue.replace(/[^0-9.]/g, ''));
            }}
            name=""
            autoComplete="new-password"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
        </div>
      </div>
    );
    return [id, value, customSelect, setValue];
  },
  UseSelectCategory: ({ placeholder, options, feildName, secendInputError }) => {
    const [isCategoryDropDownVisible, setIsCategoryDropDownVisible] = useState(false);
    const [value, setValue] = useState(placeholder);
    const [id, setId] = useState(0);
    const toggleDropDown = () => {
      setIsCategoryDropDownVisible(!isCategoryDropDownVisible);
    };
    const selectHandle = (id) => {
      if (id !== "0") {
        const find = options.find((e) => e.id === id);
        setValue(find.name);
        setId(find.id);
      } else {
        // error
        // console.log("please select " + feildName);
      }
      setIsCategoryDropDownVisible(false);
    };
    const customSelect = (
      <>
        <div className={`step2CustomSelectInputCont pointer-cursor ${secendInputError.goals ? 'step2CustomSelectInputContError' : 'step2CustomSelectInputCont'}`} onClick={toggleDropDown}>
          <div className="selectInputImg">
            <img src="../assits/CategoryIcon.png" alt="" />
          </div>
          <div className="selectInput">
            <span>{value.length > 20 ? value.slice(0, 20) + "..." : value}</span>
          </div>
          <div className="selectInputDownIconCont">
            <HiOutlineChevronDown className="selectInputDownIcon" />
          </div>
        </div>
        {isCategoryDropDownVisible && (
          <div className="step2CustomselectInputOptionCont pointer-cursor">
            {options?.map((v, i) => {
              return (
                <span key={i} onClick={() => selectHandle(v.id)}>{v.name}</span>
              );
            })}
          </div>
        )}
      </>
    );
    return [id, customSelect, setValue];
  },
};

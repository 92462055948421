import React from 'react';
import Select from 'react-select';

const MultiSelect = ({ options, selectedOptions, onChange, placeholder }) => {
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        color: '#006EB5',
        // cursor: isSelected ? "pointer-cursor" : 'pointer-cursor',
        // cursor: isFocused ? "pointer-cursor" : 'pointer-cursor',
        // cursor: isDisabled ? "pointer-cursor" : 'pointer-cursor',
      };
    },
  };
  return (
    <Select
      options={options}
      isMulti
      placeholder={placeholder}
      value={selectedOptions}
      onChange={onChange}
      styles={colourStyles}
    />
  );
};

export default MultiSelect;

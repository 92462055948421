// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* PieChart.css */

/* Styles for the container */
.scetorAndBar-chart-container {
    border: 1px solid #ccc; /* Add border to the container */
    border-radius: 5px; /* Add rounded corners */
    padding: 10px; /* Add padding for spacing */
    max-width: 100%; /* Make the chart responsive */
  }
  /* Styles for the chart element */
  #scetorAndBarchart-element {
    width: 100%; /* Ensure the chart fills the container */
    border: 1px solid black !important;
  }
  `, "",{"version":3,"sources":["webpack://./src/App/dashboards/sectorPerfomansAndBarChat.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB,6BAA6B;AAC7B;IACI,sBAAsB,EAAE,gCAAgC;IACxD,kBAAkB,EAAE,wBAAwB;IAC5C,aAAa,EAAE,4BAA4B;IAC3C,eAAe,EAAE,8BAA8B;EACjD;EACA,iCAAiC;EACjC;IACE,WAAW,EAAE,yCAAyC;IACtD,kCAAkC;EACpC","sourcesContent":["/* PieChart.css */\n\n/* Styles for the container */\n.scetorAndBar-chart-container {\n    border: 1px solid #ccc; /* Add border to the container */\n    border-radius: 5px; /* Add rounded corners */\n    padding: 10px; /* Add padding for spacing */\n    max-width: 100%; /* Make the chart responsive */\n  }\n  /* Styles for the chart element */\n  #scetorAndBarchart-element {\n    width: 100%; /* Ensure the chart fills the container */\n    border: 1px solid black !important;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState, useEffect } from 'react';
import PieChart from './PieChart';
import MapChartComponent from './MapChart';
import Table from './Table';
import { useQuery } from '@apollo/client';
import API from '../../graphql';
import Card from './card';
import './card.css';
import MultiSelect from './MultiSelect';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { useNavigate } from 'react-router-dom';
import StackedBarChart from './BarChart'
import NetProfitMarginChart from './NetProfitMarginChart';
import SectorPerformanceChart from './SectorPerformanceChart';
import ProjectStatusChart from './ProjectStatusChart';
import PercentageCard from './percentageCard';
import { GetAllPublishedProjects } from '../../services';
import PercentagebySector from "./PercentagebySector";
import PublishedProjectsChartSectorBased from "./PublishedProjectsChartSectorBased";
import PublishedProjectsChartCityBased from "./PublishedProjectsChartCityBased";
import PublishedProjectsChartInvestmentIntent from "./PublishedProjectsChartInvestmentIntent";


function DataDisplay() {
  const navigate = useNavigate()

  //Categories
  const categories = useQuery(API.ApiCategories);
  const [projectsList, loadingPublishedData] = GetAllPublishedProjects();
  const [loading, setLoadingData] = useState(false)
  const [projectSubmitToPublishedRatio, setProjectSubmitToPublishedRatio] = useState((0));
  const [totalProjects, setTotalProjects] = useState((0));
  const [ProjectsList, setProjectsList] = useState((0))
  const [projectDetails, setProjectDetails] = useState((0));
  const [totalPublishedProjects, setTotalPublishedProjects] = useState((0));
  const [totalCategories, setTotalCategories] = useState(0);
  const [selectedSectorOptions, setSelectedSectorOptions] = useState([]);
  const [selectedProjectOptions, setSelectedProjectOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [SectorOptions, setSectorOptions] = useState([]);

  useEffect(() => {
    if (projectsList) {
      const SectorOptions = projectsList?.reduce((uniqueOptions, item) => {
        const sectorName =
          item?.attributes?.sector?.data?.attributes?.Sector_Name;

        if (sectorName && !uniqueOptions.some(option => option.value === sectorName)) {
          uniqueOptions.push({
            label: sectorName,
            value: sectorName,
          });
        }

        return uniqueOptions;

      }, []);
      setSectorOptions(SectorOptions)
    }
  }, [projectsList]);

  useEffect(() => {
    if (projectsList) {
      const ProjectOptions = projectsList?.map(item => {
        if (selectedSectorOptions?.length) {
          const name =
            item?.attributes?.sector?.data?.attributes?.Sector_Name;
          if (selectedSectorOptions.some(obj => obj.value === name)) {
            return {
              label: item?.attributes?.Project_name,
              value: item?.attributes?.Project_name
            }
          }
          return null;
        } else {
          return {
            label: item?.attributes?.Project_name,
            value: item?.attributes?.Project_name
          }
        }
      }).filter(item => item !== null);
      setSelectedProjectOptions([])
      setProjectOptions(ProjectOptions)
    }
  }, [projectsList, selectedSectorOptions]);

  // categories
  useEffect(() => {
    if (categories) {
      const categoriesList = categories?.data?.categories?.data;
      const numberOfCategories = categoriesList?.length;
      setTotalCategories(numberOfCategories);
    }
  }, [categories]);

  // investments 
  const investments = useQuery(API.ApiInvestments);

  //Sectors
  const sectors = useQuery(API.ApiSectors);
  const sectorScores = useQuery(API.ApiSectorScores);
  const [totalSectors, setTotalSectors] = useState(0);
  useEffect(() => {
    if (sectors) {
      const sectorsList = sectors?.data?.sectors?.data;
      const numberOfSectors = sectorsList?.length;
      setTotalSectors(numberOfSectors);
    }
  }, [sectors]);

  //Goals
  const goals = useQuery(API.ApiGoals);
  const [totalGoals, setTotalGoals] = useState(0);
  useEffect(() => {
    if (goals) {
      const goalsList = goals?.data?.goals?.data;
      const numberOfgoals = goalsList?.length;
      setTotalGoals(numberOfgoals);
    }
  }, [goals]);

  //Projects to review
  const projectsToReview = useQuery(API.ApiAllProjectToReview);
  const [totalProjectsToReview, setTotalProjectsToReview] = useState(0);
  useEffect(() => {
    if (projectsToReview) {
      const projectsToReviewList = projectsToReview?.data?.projects?.data;
      const numberOfProjectsToReview = projectsToReviewList?.length;
      setTotalProjectsToReview(numberOfProjectsToReview);
    }
  }, [projectsToReview]);

  // BenchMarkings
  const benchMarkings = useQuery(API.ApiBenchMarkings);


  useEffect(() => {
    if (projectsList) {
      setLoadingData(true);
      const numberOfProjects = projectsList?.length || 0;
      const PublishedProjectsList = projectsList?.filter((e) => e?.attributes?.Project_Status === 'Published');
      const numberOfTotalPublishedProjects = PublishedProjectsList?.length || 0;
      const ProjectSubmitToPublishedRatio = (numberOfTotalPublishedProjects / numberOfProjects) * 100;
      const ProjectDetails = projectsList?.map(item => {
        const sectorName = item?.attributes?.sector?.data?.attributes?.Sector_Name;
        const projectName = item?.attributes?.Project_name;
        if (selectedSectorOptions?.length && !selectedProjectOptions?.length) {
          // Check if item matches selectedSectorOptions
          if (selectedSectorOptions.some(obj => obj.value === sectorName)) {
            return {
              id: item?.id,
              'Project_Name': projectName,
              'Sector_name': item?.attributes?.sector?.data?.attributes?.Sector_Name,
              'Projec_Status': item?.attributes?.Project_Status,
              'Country': item?.attributes?.country?.data?.attributes?.Country_Name,
              'City': item?.attributes?.city?.data?.attributes?.City_Name,
              'CityLatitude': item?.attributes?.city?.data?.attributes?.Latitude,
              'CityLongitude': item?.attributes?.city?.data?.attributes?.Longitude,
              'Net_Profit_Margin': item?.attributes?.Net_Profit_Margin,
              'EBIT_Margin': item?.attributes?.EBIT_Margin,
              'Project_Cost': item?.attributes?.Project_Cost,
              'GP_Margin': item?.attributes?.GP_Margin,
              'Ticket_Size': item?.attributes?.Ticket_Size,
              'Timeline_Year': item?.attributes?.TimeLine_Year,
              'Timeline_Month': item?.attributes?.TimeLine_Month,
              'Contact Person': item?.attributes?.Contact_Person,
              'Email': item?.attributes?.Email,
              'Latitude': item?.attributes?.Latitude === null ? "0" : item?.attributes?.Latitude,
              'Longitude': item?.attributes?.Longitude === null ? "0" : item?.attributes?.Longitude
            };
          }
          return null
        } else if (selectedProjectOptions?.length) {
          // Check if item matches selectedProjectOptions
          if (selectedProjectOptions.some(obj => obj.value === projectName)) {
            return {
              id: item?.id,
              'Project_Name': projectName,
              'Sector_name': item?.attributes?.sector?.data?.attributes?.Sector_Name,
              'Projec_Status': item?.attributes?.Project_Status,
              'Country': item?.attributes?.country?.data?.attributes?.Country_Name,
              'City': item?.attributes?.city?.data?.attributes?.City_Name,
              'CityLatitude': item?.attributes?.city?.data?.attributes?.Latitude,
              'CityLongitude': item?.attributes?.city?.data?.attributes?.Longitude,
              'Net_Profit_Margin': item?.attributes?.Net_Profit_Margin,
              'EBIT_Margin': item?.attributes?.EBIT_Margin,
              'Project_Cost': item?.attributes?.Project_Cost,
              'GP_Margin': item?.attributes?.GP_Margin,
              'Ticket_Size': item?.attributes?.Ticket_Size,
              'Timeline_Year': item?.attributes?.TimeLine_Year,
              'Timeline_Month': item?.attributes?.TimeLine_Month,
              'Contact Person': item?.attributes?.Contact_Person,
              'Email': item?.attributes?.Email,
              'Latitude': item?.attributes?.Latitude === null ? "0" : item?.attributes?.Latitude,
              'Longitude': item?.attributes?.Longitude === null ? "0" : item?.attributes?.Longitude
            };
          }
          return null
        } else {
          return {
            id: item?.id,
            'Project_Name': projectName,
            'Sector_name': item?.attributes?.sector?.data?.attributes?.Sector_Name,
            'Projec_Status': item?.attributes?.Project_Status,
            'Country': item?.attributes?.country?.data?.attributes?.Country_Name,
            'City': item?.attributes?.city?.data?.attributes?.City_Name,
            'CityLatitude': item?.attributes?.city?.data?.attributes?.Latitude,
            'CityLongitude': item?.attributes?.city?.data?.attributes?.Longitude,
            'Net_Profit_Margin': item?.attributes?.Net_Profit_Margin,
            'EBIT_Margin': item?.attributes?.EBIT_Margin,
            'Project_Cost': item?.attributes?.Project_Cost,
            'GP_Margin': item?.attributes?.GP_Margin,
            'Ticket_Size': item?.attributes?.Ticket_Size,
            'Timeline_Year': item?.attributes?.TimeLine_Year,
            'Timeline_Month': item?.attributes?.TimeLine_Month,
            'Contact Person': item?.attributes?.Contact_Person,
            'Email': item?.attributes?.Email,
            'Latitude': item?.attributes?.Latitude === null ? "0" : item?.attributes?.Latitude,
            'Longitude': item?.attributes?.Longitude === null ? "0" : item?.attributes?.Longitude
          };
        }
      }).filter(item => item !== null);


      setProjectsList(projectsList)
      setProjectDetails(ProjectDetails);
      setProjectSubmitToPublishedRatio(ProjectSubmitToPublishedRatio);
      setTotalProjects(numberOfProjects);
      setTotalPublishedProjects(numberOfTotalPublishedProjects);
      setLoadingData(false);
    }
  }, [projectsList, selectedSectorOptions, selectedProjectOptions]);

  const projectsCompletions = useQuery(API.ApiListOfCompletedProject);
  const [projectCompletionData, setProjectCompletionData] = useState([]);

  const projectCompletionList = projectsCompletions?.data?.projectCompletions?.data;
  const asyncFuncForUseEffect = async () => {
    if (projectCompletionList) {
      const ProjectCompletionData = projectCompletionList?.map(item => {
        const sectorName = item?.attributes?.sectorId?.data?.attributes?.Sector_Name
        const projectName = item?.attributes?.projectId?.data?.attributes?.Project_name

        if (selectedSectorOptions?.length && !selectedProjectOptions?.length) {
          // Check if item matches selectedSectorOptions
          if (selectedSectorOptions.some(obj => obj.value === sectorName)) {
            return {
              id: item?.id,
              'Project Name': item?.attributes?.projectId?.data?.attributes?.Project_name,
              'Sector': item?.attributes?.sectorId?.data?.attributes?.Sector_Name,
              'Category': item?.attributes?.categoryId?.data?.attributes?.Category_Name,
              'Goal Title': item?.attributes?.goalId?.data?.attributes?.Title,
              'Description': item?.attributes?.goalId?.data?.attributes?.Description,
              'Percentage': item?.attributes?.Completed_Percentage,
              'isCompleted': item?.attributes?.isCompleted,
              'Comments': item?.attributes?.Comments,
            };
          }
          return null
        } else if (selectedProjectOptions?.length) {
          // Check if item matches selectedProjectOptions
          if (selectedProjectOptions.some(obj => obj.value === projectName)) {
            return {
              id: item?.id,
              'Project Name': item?.attributes?.projectId?.data?.attributes?.Project_name,
              'Sector': item?.attributes?.sectorId?.data?.attributes?.Sector_Name,
              'Category': item?.attributes?.categoryId?.data?.attributes?.Category_Name,
              'Goal Title': item?.attributes?.goalId?.data?.attributes?.Title,
              'Description': item?.attributes?.goalId?.data?.attributes?.Description,
              'Percentage': item?.attributes?.Completed_Percentage,
              'isCompleted': item?.attributes?.isCompleted,
              'Comments': item?.attributes?.Comments,
            };
          }
          return null
        } else {
          return {
            id: item?.id,
            'Project Name': item?.attributes?.projectId?.data?.attributes?.Project_name,
            'Sector': item?.attributes?.sectorId?.data?.attributes?.Sector_Name,
            'Category': item?.attributes?.categoryId?.data?.attributes?.Category_Name,
            'Goal Title': item?.attributes?.goalId?.data?.attributes?.Title,
            'Description': item?.attributes?.goalId?.data?.attributes?.Description,
            'Percentage': item?.attributes?.Completed_Percentage,
            'isCompleted': item?.attributes?.isCompleted,
            'Comments': item?.attributes?.Comments,
          };
        }
      }).filter(item => item !== null);

      setProjectCompletionData(ProjectCompletionData)

      // const isCompletedGoals = projectCompletionList?.filter((e) => !e?.attributes?.isCompleted);
    }
  }
  useEffect(() => {
    asyncFuncForUseEffect()
  }, [projectsCompletions, selectedProjectOptions, selectedSectorOptions]);
  const [Percentage, setPercentage] = useState([])
  const [Category, setCategory] = useState([])
  const [Sector, setSector] = useState([])
  const [GroupedPerojects, setGroupedPerojects] = useState([])
  useEffect(() => {
    let tempPercentage = projectCompletionData.map(item => item?.Percentage)
    let tempCategory = projectCompletionData.map(item => item?.Category)
    let tempSector = projectCompletionData.map(item => item?.Sector)
    setPercentage(tempPercentage)
    setCategory(tempCategory)
    setSector(tempSector)
  }, [projectCompletionData])

  const [groupedProjects, setGroupedProjects] = useState({});
  useEffect(() => {
    // Process the projecList data
    if (projectDetails && projectDetails.length > 0) {
      const groupedProjects = projectDetails.reduce((acc, project) => {
        const sectorName = project['Sector_name'];
        if (sectorName) {
          if (!acc[sectorName]) {
            acc[sectorName] = {
              publishedCount: 0,
              totalCount: 0,
            };
          }
          acc[sectorName].totalCount++;
          if (project['Projec_Status'] === 'Published') {
            acc[sectorName].publishedCount++;
          }
        }
        return acc;
      }, {});
      setGroupedProjects(groupedProjects);
    }
  }, [projectDetails]);
  const handleMultiSelectChange = (selected) => {
    // setSelectedSectorOptions(selected);
  };
  return (
    <div className='dashboardCenter' style={{ overflow: 'none' }}>
      <div style={{ backgroundColor: '#fff', width: '100%', height: '13vh' }}>
        <Navbar />
      </div>
      <div style={{ backgroundColor: '#fff', width: '100%', height: '87vh', overflowY: 'auto' }} className='DashBoardMainCont'>
        <div className='DashBoardMainContainer'>
          <div className='bannerMainCont'>
            <div className='bannerCont'>
              <div className='BannerHeadingCont'>
                <h1 className='bannerHeading'>
                  Sustainable Development Goals <span>Investments
                    Dashboard</span> <img src='/assits/homeHeadingImg.png' alt='' />
                </h1>
              </div>
              <div className='bannerImgCont'>
                <img className='bannerImg' src='/assits/bannerImg.png' alt='BannerImg' />
                <img className='bannerImg-res' src='/assits/bannerImg_res.png' alt='BannerImg' />
              </div>
            </div>
          </div>
          <div className='DashboardHeadingButtonCont'>
            <div className='DashboardheadingCont'>
              <div>
                <h2>Welcome to our Platform</h2>
                <img src='/assits/dashboardImg/thumbUpIconDashboard.png' alt='Heading-Icon' />
              </div>
              <span>Empower your investments to cultivate a brighter future.</span>
            </div>
            <div className='DashboardButtonCont'>
              <div className='DashboardSubmitProjectButton pointer-cursor' onClick={() => navigate("/create-new-project")}>
                <img src='/assits/dashboardImg/dashboardSubmintProjectButtonImg.png' alt='SubmitProject-Icon' />
                <span>Submit the Project</span>
              </div>
              <div className='DashboardInvestProjectButton pointer-cursor' onClick={() => navigate('/projects')}>
                <img src='/assits/dashboardImg/dashboardInvestProjectButtonImg.png' alt='InvestProject-Icon' />
                <span>Invest in the Project </span>
              </div>
            </div>
          </div>
          <div className='dasboardCardCont'>
            <Card title="Number of Categories" value={totalCategories || 0} loading={loading} />
            <Card title="Number of Sectors" value={totalSectors || 0} loading={loading} />
            <Card title="Number of Goals" value={totalGoals || 0} loading={loading} />
            <Card title="Number of Published Projects" value={totalProjects || 0} loading={loading} />
            <Card title="Number of Projects to Review" value={totalProjectsToReview || 0} loading={loading} />
          </div>
          {/* <div className='dashboardTableCont'>
            <div className='dashboardTableHeadingAndFilterCont'>
              <div className='dashboardTableHeadingCont'>
                <img src='/assits/dashboardImg/dashboardFilterImg.png' alt='' />
                <span>Filters</span>
              </div>
              <div>
                <div className='DashboardfilterSeletCont'>
                  <div className='dashboardTableFilterCont'>
                    <MultiSelect
                      options={projectOptions}
                      placeholder="Filter for Projects"
                      selectedOptions={selectedProjectOptions}
                      onChange={setSelectedProjectOptions}
                    />
                  </div>
                  <div className='dashboardTableFilterCont'>
                    <MultiSelect
                      options={SectorOptions}
                      placeholder="Filter for Sector"
                      selectedOptions={selectedSectorOptions}
                      onChange={setSelectedSectorOptions}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {!!projectDetails?.length ? <div className='chartsContainer'>
            <div className='chartCont' style={{ maxWidth: '100%' }}>
              <div className='mapTitlAndImgCont'>
                <img src='/assits/dashboardImg/mapHeadingIcon.png' alt='' />
                <span>Projects Across Pakistan</span>
              </div>
              <div>
                <MapChartComponent data={projectDetails} />
              </div>
            </div>
          </div> : null}
          <div className='chartsContainer'>
            <div className='chartCont'>
              <div className=''>
                <div className='mapTitlAndImgCont'>
                  <img src='/assits/dashboardImg/dashboardChartIcon.png' alt='' />
                  <span>Published projects based on Sector</span>
                </div>
                <PublishedProjectsChartSectorBased projectDetails={projectDetails || []} />
              </div>
            </div>
            <div className='chartCont'>
              <div className=''>
                <div className='mapTitlAndImgCont'>
                  <img src='/assits/dashboardImg/dashboardChartIcon.png' alt='' />
                  <span>Published projects based on City</span>
                </div>
                <PublishedProjectsChartCityBased projectDetails={projectDetails || []} />
              </div>
            </div>
            <div className='chartCont'>
              <div className=''>
                <div className='mapTitlAndImgCont'>
                  <img src='/assits/dashboardImg/dashboardChartIcon.png' alt='' />
                  <span>Published Projects Investment Intent based on Sector</span>
                </div>
                <PublishedProjectsChartInvestmentIntent investments={investments || []} />
              </div>
            </div>
          </div>
          <div className='chartsContainer'>
            <div className='chartCont' style={{ maxWidth: '100%' }}>
              {(benchMarkings?.data && sectorScores?.data) ? <div className=''>
                <div className='mapTitlAndImgCont'>
                  <img src='/assits/dashboardImg/dashboardChartTitleImg.png' alt='' />
                  <span>Percentage by Sector</span>
                </div>
                <PercentagebySector sectorScores={sectorScores} benchMarkings={benchMarkings} />
              </div> : null}
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default DataDisplay;
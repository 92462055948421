import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js-dist-min';
import './PieChart.css'

const ProjectStatusChart = ({ data }) => {
  // Reference to the chart container
  const chartRef = useRef(null);

  // Data preprocessing: Count the number of projects in each status
  const projectStatusCounts = {};
  data.forEach((project) => {
    if (project.Projec_Status in projectStatusCounts) {
      projectStatusCounts[project.Projec_Status]++;
    } else {
      projectStatusCounts[project.Projec_Status] = 1;
    }
  });

  // Extract status names and counts
  const statusNames = Object.keys(projectStatusCounts);
  const statusCounts = Object.values(projectStatusCounts);

  // Create a trace for the pie chart
  const trace = {
    values: statusCounts,
    labels: statusNames,
    type: 'pie',
  };

  // Define the layout for the chart
  const layout = {
    title: 'Project Status Distribution',
    margin: { t: 0, l: 0, r: 0, b: 0 },
    showlegend: true,
    height: 215,
    width: '20%'
  };

  // Render the chart using Plotly
  useEffect(() => {
    if (chartRef.current) {
      Plotly.newPlot(chartRef.current, [trace], layout);
    }
  }, [data]);

  return (
    <div className="pie-chart-container">
      <div ref={chartRef} className="chart-element"></div>
    </div>
  );
};

export default ProjectStatusChart;

import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist-min';
import './PieChart.css'; // Import your CSS file

function PieChart({ data, elementId, Measure, Tag }) {
  useEffect(() => {
    if (data) {
      const values = Measure;
      const labels = Tag;

      const trace = {
        values: values,
        labels: labels,
        type: 'pie',
        textinfo: 'percent',
        textposition: 'inside',
        hoverinfo: 'label+percent',
      };

      const layout = {
        margin: { t: 0, l: 0, r: 0, b: 0 },
        showlegend: true,
        height: 215,
        width: 650
      };

      const plotData = [trace];

      Plotly.newPlot(elementId, plotData, layout);
    }
  }, [data, elementId, Measure, Tag]);

  return (
    <div className="pie-chart-container">
      <div id={elementId} className="chart-element"></div>
    </div>
  );
}

export default PieChart;

import React, { useState } from 'react';
import "./style.css";
import { Link } from 'react-router-dom';

const MobileNevBar = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [menuIconSrc, setMenuIconSrc] = useState('/assits/Icon/navbarMenuIcon.png');

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
        setMenuIconSrc(isDialogOpen ? '/assits/Icon/navbarMenuIcon.png' : '/assits/Icon/navMenuCloseIcon.png');
    };


    return (
        <div className='navCont'>
            <div className='LogoCont'>
                <Link className='link' to="/"><img className='image' src="/assits/mainLogo.png" alt='Navbar Logo' /></Link>
            </div>
            <div className='navSearchCont'>
                <div>
                    <img className='SearchIcon' src="/assits/Icon/SearchIcon.png" alt='Navbar-Search-Icon' />
                </div>
                <div className='menuIconCont' onClick={toggleDialog}>
                    <img src={menuIconSrc} alt='Mobile-Menu-Icon' />
                </div>
            </div>
            {isDialogOpen && (
                <div className='dailogBox'>
                    <div className='nevOptionCont'>
                        <div>
                            <Link className='link pointer-cursor' onClick={toggleDialog} to="/">
                                <span>HOME</span>
                                <img className='navOptionIcon' src='/assits/Icon/mobileNavbarOptionIcon.png' alt='Arrow-Icon' />
                            </Link>
                        </div>
                        <div>
                            <Link className='link pointer-cursor' onClick={toggleDialog} to="/whoweare">
                                <span>WHO WE ARE</span>
                                <img className='navOptionIcon' src='/assits/Icon/mobileNavbarOptionIcon.png' alt='Arrow-Icon' />
                            </Link>
                        </div>
                        <div>
                            <Link className='link pointer-cursor' onClick={toggleDialog} to="/">

                                <span>WHAT WE DO</span>
                                <img className='navOptionIcon' src='/assits/Icon/mobileNavbarOptionIcon.png' alt='Arrow-Icon' />
                            </Link>
                        </div>
                        <div>
                            <Link className='link pointer-cursor' onClick={toggleDialog} to="/">

                                <span>OUR IMPACT</span>
                                <img className='navOptionIcon' src='/assits/Icon/mobileNavbarOptionIcon.png' alt='Arrow-Icon' />
                            </Link>
                        </div>
                        <div>
                            <Link className='link pointer-cursor' onClick={toggleDialog} to="/">

                                <span>CONTACT US</span>
                                <img className='navOptionIcon' src='/assits/Icon/mobileNavbarOptionIcon.png' alt='Arrow-Icon' />
                            </Link>
                        </div>
                    </div>
                    <div className='lineCont'>
                        <div className='line'></div>
                    </div>
                    <div className='navOptionIconCont'>
                        <div className='iconCont'>
                            <img src='/assits/Icon/twitterIcon.png' alt='twitterIcon' />
                            <img src='/assits/Icon/facebookIcon.png' alt='facebookIcon' />
                            <img src='/assits/Icon/youtubeIcon.png' alt='youtubeIcon' />
                            <img src='/assits/Icon/instaIcon.png' alt='instaIcon' />
                            <img src='/assits/Icon/linkedinIcon.png' alt='linkedinIcon' />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
};

export default MobileNevBar;
import React, { useEffect, useState } from 'react'
import "./projectInvestment.css"
import { useNavigate, useParams } from 'react-router-dom'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { useQuery } from "@apollo/client";
import API from '../../../graphql';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CreateNewInvestment, CreateNewInvestmentAttachemnt } from '../../../services';
import ThanksModal from '../../components/thanksModal';
import { useGeolocated } from "react-geolocated";
import InvestmentFileSelector from '../../components/InvestmentCustomFileSelector';
import Footer from '../../components/footer';
import clientUpload from '../../../helpers/uploader';

const ProjectInvestment = () => {
    const [coords, setCoords] = useState(JSON.parse(window?.localStorage.getItem("items")));
    // ==============================
    const { projectdetailId } = useParams();
    const [isThanksModalOpen, setisThanksModalOpen] = useState(false);
    const countriesQuery = useQuery(API.ApiCountries);
    const [countries, setCountries] = useState([]);
    const [investmentId, setInvestmentId] = useState(null)
    const [newProjectInvestment, addNewProjectInvestment] = CreateNewInvestment();
    const navigate = useNavigate()
    const [isCountryDropDownVisible, setIsCountryDropDownVisible] = useState(false);
    const [investmentIntent, setInvestmentIntent] = useState(null);
    const [selectcountryError, setSelectcountryError] = useState(true)
    const [attchs, addNewInvestmentFiles] = CreateNewInvestmentAttachemnt()
    const [value, setValue] = useState("Select from the options");
    const [id, setId] = useState(0);
    const CountryDropDown = () => {
        setIsCountryDropDownVisible(!isCountryDropDownVisible);
    };
    useEffect(() => {
        const countriesData = countriesQuery?.data?.countries?.data;

        if (countries?.length < 1 && countriesData?.length > 0) {
            let array = [...countries];
            for (let key in countriesData) {
                const prop = countriesData[key];
                array.push({
                    id: prop?.id,
                    name: prop?.attributes?.Country_Name,
                    shId: prop?.attributes?.SharePointId,
                });
            }
            setCountries(array);
        }

    }, [countriesQuery]);
    const selectHandle = (id) => {
        if (id !== "0") {
            // console.log(id)
            const find = countries.find((e) => e.id === id);
            setValue(find.name);
            setId(find.id)
            setSelectcountryError(true)
        } else {
            // error
            // console.log("please select");
        }
        setIsCountryDropDownVisible(false);
    }
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const validateEmail = (email) => {
        const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return regex.test(email);
    };
    const [mobileNumber, setMobileNumber] = useState('');
    const [isMobileNumberValid, setIsMobileNumberValid] = useState(true);
    const validateMobileNumber = (number) => {
        // const regex = /^\+\d{1,20}$/; // Match any phone number with country code (up to 20 digits)
        const regex = /^\+92\d{10}$/; // Match any phone number with country code (up to 20 digits)
        // const regex = /^03[0-4]\d{8}$/; // Pakistani mobile number format without +92
        return regex.test(number);
    };
    const [investorName, setInvestorName] = useState('');
    const [isInvestorNameValid, setIsInvestorNameValid] = useState(true);
    const validateInvestorName = (name) => {
        const regex = /^[A-Za-z]{3,}$/;
        return regex.test(name);
    }
    const [isAddress, setAddress] = useState('');
    const [isAddressEmpty, setIsAddressEmpty] = useState(true);
    const validateAddress = (address) => {
        return address.trim() !== '';
    }
    const [files, setFiles] = useState([])
    const handleFileSelect = (selectedFiles) => {
        setFiles(selectedFiles)
    };
    // console.log("files", files)
    useEffect(() => {
        // Extract relevant information from the data, such as city, region, country, etc.
        if (!coords) {
            let key = process.env.REACT_APP_search_address_ip
            if (key) {
                fetch(`https://ipinfo.io?token=${key}`)
                    .then(response => response.json())
                    .then(data => {
                        let leti = data.loc.slice(0, data.loc.indexOf(','))
                        let longi = data.loc.slice(data.loc.indexOf(',') + 1)
                        setCoords({
                            let: leti,
                            long: longi
                        })
                        window?.localStorage.setItem("items", JSON.stringify({
                            let: leti,
                            long: longi
                        }))
                    })
                    .catch(error => {
                        console.error("Error fetching location:", error);
                    });
            }
        }
    }, [])
    const submintValidation = async () => {
        try {
            let flag = true;
            if (!investorName || !isInvestorNameValid) {
                setIsInvestorNameValid(false)
                flag = false;
            }
            if (!mobileNumber || !isMobileNumberValid) {
                setIsMobileNumberValid(false)
                flag = false;
            }
            if (!email || !isEmailValid) {
                setIsEmailValid(false)
                flag = false;
            }
            if (!id || !value) {
                setSelectcountryError(false)
                flag = false;
            }
            if (!validateAddress(isAddress)) {
                setIsAddressEmpty(false)
                flag = false;
            }
            if (flag) {
                const proj = await addNewProjectInvestment({
                    variables: {
                        "Investor_Name": investorName,
                        "Investor_Email": email,
                        "Investor_Number": mobileNumber,
                        "country": Number(id),
                        "Investor_Address": isAddress,
                        "Investor_Intent": investmentIntent,
                        "projectId": Number(projectdetailId),
                        "SharePointId": 123,
                        "Latitude": coords?.let ? String(coords?.let) : "24.8608",
                        "Longitude": coords?.long ? String(coords?.long) : "67.0104",
                    }
                })
                if (proj?.data) {
                    if (files?.length) {
                        for (let key in files) {
                            clientUpload
                                .mutate({
                                    mutation: API.ApiUploadFiles,
                                    variables: {
                                        file: files[key]
                                    }
                                })
                                .then(async (res) => {
                                    const attLinks = res?.data?.upload.data;
                                    const get = await addNewInvestmentFiles({
                                        variables: {
                                            "url": attLinks?.attributes?.url,
                                            "name": attLinks?.attributes?.name,
                                            "ext": attLinks?.attributes?.ext,
                                            "size": String(attLinks?.attributes?.size),
                                            "investmentId": Number(proj?.data.createInvestment.data.id)
                                        }
                                    })
                                    // console.log("addAttachmentData", get)
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        }
                    }
                    setInvestmentId(proj?.data.createInvestment.data.id)
                    // navigate(-1)
                    setisThanksModalOpen(true)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className='projectInvestmentCenter'>
            <div className='ProjectInvestmentContainer'>
                {isThanksModalOpen && <ThanksModal
                    investmentId={investmentId}
                    investmentFlag={true}
                />}
                <div className="ProjectInvestmentHeadCont">
                    <div className="ProjectInvestmentLogoCont">
                        <div className="ProjectInvestmentMainLogoCont pointer-cursor" onClick={() => navigate("/")}>
                            <img src="/assits/mainLogo.png" alt="Logo" />
                        </div>
                        <img className="Project-Logo" src="/assits/projectDetailMainLogo.png" alt="projectDetail-Logo" />
                        <h1 className="ProjectInvestmentHeading">Project Investment</h1>
                    </div>
                    <div className="ProjectInvestmentCloseCont" onClick={() => navigate(-1)}>
                        <div className="closeLink">
                            <img className='pointer-cursor' src="/assits/Icon/closeIcon.png" alt="Delet-Icon" />
                            <span className='pointer-cursor'>Close</span>
                        </div>
                    </div>
                </div>
                <div className='ProjectInvestmentbodyCont'>
                    <div className='ProjectInvestmentBannerCont'>
                        <h1>Invested in Impact: Shaping a Brighter Tomorrow, Today <img src='/assits/Icon/projectInvestmentHeadingIcon.png' alt='ProjectInvestmentHeadingIcon' /></h1>
                    </div>
                    <div className='ProjectInvestmentFormCont'>
                        <div className='InvestmentFormCont1'>
                            <div className='InvestmentFormCont1InnerChild'>
                                <div>
                                    <span>Investor Name</span>
                                    <input
                                        type="text"
                                        className={!isInvestorNameValid ? "InvestmentInputError" : "inputBorder"}
                                        placeholder='Investor Name'
                                        onChange={(e) => {
                                            const newName = e.target.value;
                                            setInvestorName(newName);
                                            setIsInvestorNameValid(validateInvestorName(newName));
                                        }} />
                                </div>
                                <div>
                                    <span>Mobile No</span>
                                    <input
                                        type="text"
                                        name="MobileNo"
                                        placeholder='+92************'
                                        value={mobileNumber}
                                        className={!isMobileNumberValid ? "InvestmentInputError" : "inputBorder"}
                                        onChange={(e) => {
                                            let newMobileNumber = e.target.value;
                                            if (newMobileNumber) {
                                                if (!newMobileNumber.startsWith('+92')) {
                                                    newMobileNumber = '+92' + newMobileNumber;
                                                }
                                                newMobileNumber = newMobileNumber.slice(0, 13);
                                            }
                                            setMobileNumber(newMobileNumber);
                                            setIsMobileNumberValid(validateMobileNumber(newMobileNumber));
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='InvestmentFormCont1InnerChild'>
                                <div>
                                    <span>Email</span>
                                    <input
                                        type="text"
                                        className={!isEmailValid ? "InvestmentInputError" : "inputBorder"}
                                        placeholder='example@gmail.com'
                                        onChange={(e) => {
                                            const newEmail = e.target.value;
                                            setEmail(newEmail);
                                            setIsEmailValid(validateEmail(newEmail));
                                        }} />
                                </div>
                                <div className='ConteryCont' style={{ marginRight: "0px" }}>
                                    <span>Country</span>
                                    <div className='ConteryInputCont' style={{ marginRight: "0px" }}>
                                        <div className={!selectcountryError ? "ProjectInvestmentCustomSelectInputContError pointer-cursor" : "ProjectInvestmentCustomSelectInputCont pointer-cursor"} onClick={CountryDropDown}>
                                            <span>{value}</span>
                                            <HiOutlineChevronDown className="ProjectInvestmentselectInputDownIcon" />
                                        </div>
                                        {isCountryDropDownVisible && (
                                            <div className="ProjectInvestmentCustomselectInputOptionCont">
                                                {countries?.map((v, i) => {
                                                    return (
                                                        <span key={i} className='pointer-cursor' onClick={() => selectHandle(v.id)}>
                                                            {v.name}
                                                        </span>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='InvestmentFormCont2'>
                            <div className='InvestmentFormCont2InnerChild'>
                                <div>
                                    <span>Investor Address</span>
                                    <textarea
                                        className={!isAddressEmpty ? "InvestmentFormCont2AddressInputError" : "InvestmentFormCont2AddressInput"}
                                        onChange={(e) => {
                                            const NewAddress = e.target.value;
                                            setAddress(NewAddress);
                                            setIsAddressEmpty(validateAddress(NewAddress)); // Check for empty address
                                        }}
                                        name="w3review" placeholder='Add Address' rows="5" cols="28">
                                    </textarea>
                                </div>
                                <div>
                                    <span>Investor Intent</span>
                                    <ReactQuill className='InvestmentFormCont2IntentInput' value={investmentIntent} onChange={setInvestmentIntent} />
                                </div>
                            </div>
                            <div>
                                <span className="InvesmintatachmentHeadingCont">Add Attachments <h5> ( Word | Pdf | Excel )</h5></span>
                                <InvestmentFileSelector onFileSelect={handleFileSelect} />
                            </div>
                        </div>
                        <div className='InvestmentFormButtonCont'>
                            <button className='InvestmentFormButton pointer-cursor' onClick={() => submintValidation()}>Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ProjectInvestment;
import React, { useState, useEffect } from 'react';
import "./style.css";
import { Inputs } from '../../input';

const OverView = ({ projectDetails, projectGoals, sectorsList, categories, setMainPercentage, mainPercentage }) => {
    // const [step1, setStep1] = useState(null);
    const [openStep1, setOpenStep1] = useState(null); // State for open step1 goal
    const [openStep2, setOpenStep2] = useState(null); // State for open step2 goal

    const handleStep1RowClick = (index) => {
        if (openStep1 === index) {
            setOpenStep1(null);
        } else {
            setOpenStep1(index);
        }
    };

    const handleStep2RowClick = (index) => {
        if (openStep2 === index) {
            setOpenStep2(null);
        } else {
            setOpenStep2(index);
        }
    };

    const [projectName, ProjectNameInput, setProjectName] = Inputs.UseInput({
        type: "text",
        placeholder: "Project Name",
        className: "inputStep2",
        defaultValue: "",
        disabled: true
    });
    const [sector, SectorInput, setSector] = Inputs.UseInput({
        type: "text",
        placeholder: "Transportation",
        className: "inputStep2 placeholder",
        defaultValue: "",
        disabled: true
    });
    const [category, setCategory] = useState([]);
    const [isFirstVisible, setFirstVisible] = useState(null);

    const toggleFirstVisible = (id) => {
        const array = [...category];
        for (let e in array) {
            if (array[e].id === id) {
                array[e].selected = !array[e].selected;
            }
            // else {
            //     array[e].selected = false
            // }
        }
        setCategory(array);
    };

    useEffect(() => {
        // Set proevios Data in Fields
        if (projectDetails) {
            setProjectName(projectDetails?.pName);
            const sectorName = sectorsList?.filter((e) => e.id === projectDetails?.sector);
            setSector(sectorName[0].name);
        }
        if (projectGoals) {
            const goalsData = projectGoals.goals;
            const data = categories;
            let array = [...category];
            for (let key in goalsData) {
                if (goalsData[key].haveSubGoal) {
                    const copy = [...goalsData[key].subGaolsList];
                    for (let sgl in copy) {
                        const abc = copy[sgl]?.subGoalDescList?.filter((e) => { if (e.completed) { return true; } else return false; });
                        if (abc?.length) {
                            copy[sgl].subGoalDescCompleted = true;
                        } else {
                            copy[sgl].subGoalDescCompleted = false;
                        }
                    }
                }
                let match;
                if (array?.length) {
                    match = array.find((e) => e.id === goalsData[key].categoryId);
                }
                if (!match) {
                    let cat = data.find((e) => e.id === goalsData[key].categoryId);
                    cat.selected = true;
                    array.push(cat);
                }
            }
            const sortedArray = array.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
            setCategory(sortedArray);
            let totalMian = 0;
            for (let k in array) {
                totalMian += Number(array[k].percentage);
            }
            setMainPercentage(totalMian);
        }

    }, [projectDetails, projectGoals]);

    // Function to adjust the heights of all textareas
    const adjustTextareaHeights = () => {
        const textareas = document.querySelectorAll('.auto-resize-textarea');
        for (let i = 0; i < textareas.length; i++) {
            const textarea = textareas[i];
            textarea.style.height = 'auto';
            if (textarea.value) {
                textarea.style.height = (textarea.scrollHeight === 32 ? 0 : textarea.scrollHeight) + 'px';
            } else {
                textarea.style.height = '0px';
            }
        }
    };

    return (
        <div className='step3Cont'>
            <div className='scrollCont' id='tableContScroll'>
                <div className='barsCont'>
                    {category?.length && category.map((e, i) => {
                        let nmbr = 1;
                        if (e.name.toLowerCase() === "esg factors") {
                            return (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(e.id)}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                        <img src={`../assits/${e.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                                    </div>
                                    {e.selected ? <table className='overViewTable'>
                                        <thead>
                                            <tr>
                                                <td colSpan={3}>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td>Goals</td>
                                                <td>Goal Description to Sub-Goals / Nested-Goals </td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td>Completed</td>
                                                <td>Percentage</td>
                                                <td className='commintTd'>Comment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectGoals?.goals?.length ? projectGoals.goals?.map((v, j) => {
                                                let truncatedDescription = v.Description;
                                                if (truncatedDescription?.length > 300) {
                                                    truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                }
                                                if (v.categoryId === e.id) {
                                                    // console.log("categoryId", v) 
                                                    return (
                                                        <>
                                                            <tr key={j} className='esgFectorgoalsFormTbodyTr' onClick={() => handleStep1RowClick(j)}>
                                                                <td colSpan={2}></td>
                                                                <td >{nmbr++}</td>
                                                                <td>
                                                                    <img src='../assits/tableMarkIcon.png' alt='' />
                                                                </td>
                                                                <td className='goalsTitleCont'>
                                                                    {v.Title}
                                                                </td>
                                                                <td className='goal-descriptionTd'>
                                                                    <img
                                                                        className='pointer-cursor'
                                                                        src={`../assits/${j === openStep1 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                                        alt=''
                                                                    />
                                                                </td>
                                                                {/* <td>{v.Criteria_Type}</td> */}
                                                                {/* <td>{v.completed ? "Yes" : "No"}</td> */}
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='commintTd'>
                                                                </td>
                                                            </tr>

                                                            {v.subGaolsList.map((elem, idx) => {
                                                                return <> <tr className={`step1Tr ${openStep1 === j ? '' : 'hidden'}`} onClick={() => handleStep2RowClick(idx)}>
                                                                    <td className='lineMarkTd'><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                    <td className='step1No' colSpan={2}>{idx + 1}</td>
                                                                    <td>
                                                                        <img src='../assits/tableMarkIcon.png' alt='' />
                                                                    </td>
                                                                    <td>
                                                                        {elem.Title}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            className='pointer-cursor'
                                                                            src={`../assits/${idx === openStep2 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                                            alt=''
                                                                        />
                                                                    </td>
                                                                    <td></td>
                                                                    <td>{elem.completed ? "Yes" : "No"}</td>
                                                                    <td>
                                                                        {elem.subGoalDescCompleted ? elem.subGoalPercentage : 0}%
                                                                    </td>
                                                                    <td className='overviewSubGoalsBtn'></td>
                                                                </tr>
                                                                    {
                                                                        elem.subGoalDescList?.map((item, ind) => {
                                                                            return <tr className={`step2Tr ${openStep1 === j && openStep2 === idx ? '' : 'hidden'}`}>
                                                                                <td></td>
                                                                                <td className='lineMarkTd'><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                                <td>{ind + 1}</td>
                                                                                <td>
                                                                                    <img src='../assits/tableMarkIcon.png' alt='' />
                                                                                </td>
                                                                                <td colSpan={2} >
                                                                                    {item.Title}
                                                                                </td>
                                                                                <td>{item.Criteria_Type}</td>
                                                                                <td>
                                                                                    {item.completed ? "Yes" : "No"}
                                                                                </td>
                                                                                <td className='commintTd'>
                                                                                    <div className='commintTdDiv'>
                                                                                        <span>
                                                                                            {item.comment}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>;
                                                                        })
                                                                    }</>;
                                                            })}
                                                        </>
                                                    );
                                                }
                                            }) : null}
                                        </tbody >
                                    </table > : null}
                                </div>
                            );
                        } else if (e.name.toLowerCase() === "sdg lens alignment") {
                            return (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(e.id)}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                        <img src={`../assits/${e.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                                    </div>
                                    {e.selected ? <table className='overViewTable sdg-table' >
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td className='goalTd'>Goals</td>
                                                <td className='goal-descriptionTd'>Goals Description</td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td>Percentage</td>
                                                <td>Completed</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectGoals?.goals?.length ? projectGoals.goals?.map((v, i) => {
                                                let truncatedDescription = v.Description;
                                                if (truncatedDescription?.length > 300) {
                                                    truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                }
                                                if (v.categoryId === e.id) {
                                                    return <React.Fragment key={i} >
                                                        <tr key={i} className='goalsFormTbodyTr' >
                                                            <td>{nmbr++}</td>
                                                            <td>
                                                                <img src='../assits/tableMarkIcon.png' alt='' />
                                                            </td>
                                                            <td className='goalsTitleCont'>{v.Title}</td>
                                                            <td>{truncatedDescription}</td>
                                                            <td>{v.Criteria_Type}</td>
                                                            <td>{v.completed ? v.percentage : 0} %</td>
                                                            {/* {Number(v.ScreeningPercentage) === 0 ? (
                                                                <td>{v.completed ? v.percentage : 0} %</td>
                                                            ) : (
                                                                <td>{v.completed ? v.ScreeningPerValue : 0} %</td>
                                                            )} */}
                                                            <td>
                                                                {v.completed ? "Yes" : "No"}
                                                            </td>
                                                        </tr>
                                                        {v.completed ? <React.Fragment>
                                                            {v.Criteria ? <tr className={`goalsFormTbodyTr`}>
                                                                <td colSpan={7}>{v.Criteria}</td>
                                                            </tr> : null}
                                                            <tr className={`goalsFormTbodyTr`}>
                                                                <td colSpan={7}>
                                                                    <textarea
                                                                        className='tableInput auto-resize-textarea'
                                                                        type='text'
                                                                        disabled
                                                                        value={v.comment}
                                                                        rows={1}
                                                                        onInput={adjustTextareaHeights}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </React.Fragment> : null}
                                                    </React.Fragment  >;
                                                }
                                            }) : null}
                                        </tbody>
                                    </table> : null}
                                </div>
                            );
                        } else {
                            return (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(e.id)}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                        <img src={`../assits/${e.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                                    </div>
                                    {e.selected ? <table className='overViewTable'>
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td className='goalTd'>Goals</td>
                                                <td className='goal-descriptionTd'>Goals Description</td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td>Percentage</td>
                                                <td>Completed</td>
                                                <td className='commintTd'>Comment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projectGoals?.goals?.length ? projectGoals.goals?.map((v, i) => {
                                                let truncatedDescription = v.Description;
                                                if (truncatedDescription?.length > 300) {
                                                    truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                }
                                                if (v.categoryId === e.id) {
                                                    return (
                                                        <tr key={i} className='goalsFormTbodyTr' >
                                                            <td>{nmbr++}</td>
                                                            <td>
                                                                <img src='../assits/tableMarkIcon.png' alt='' />
                                                            </td>
                                                            <td className='goalsTitleCont'>{v.Title}</td>
                                                            <td>{truncatedDescription}</td>
                                                            <td>{v.Criteria_Type}</td>
                                                            <td>{v.completed ? v.percentage : 0} %</td>
                                                            {/* {Number(v.ScreeningPercentage) === 0 ? (
                                                                <td>{v.completed ? v.percentage : 0} %</td>
                                                            ) : (
                                                                <td>{v.completed ? v.ScreeningPerValue : 0} %</td>
                                                            )} */}
                                                            <td>
                                                                {v.completed ? "Yes" : "No"}
                                                            </td>
                                                            <td className='commintTd'>
                                                                <div className='commintTdDiv'>
                                                                    <span>
                                                                        {v.comment}
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            }) : null}
                                        </tbody>
                                    </table> : null}
                                </div>
                            );
                        }
                    })}

                </div>
            </div>
        </div>
    );
};

export default OverView;

import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message) {
        return message
      }
      if (locations) {
        return locations
      }
      if (path) {
        return path
      }
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // )
    }
    );
  if (networkError) return networkError;
});

const httpLink = new HttpLink({
  // uri: process.env.REACT_APP_DEV_URL
  uri: process.env.REACT_APP_PRO_URL
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

export default client;

import React from 'react';
import "./navigation.css"
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "../screens/home";
import WhoWeAre from '../screens/whoWeAre';
import SubmitProjectList from '../screens/submitProjectList';
import MultistepApp from '../screens/createNewProject';
import ProjectDetail from "../screens/projectDetails";
import ProjectInvestment from '../screens/projectInvestment';
import Dashboard from '../dashboards/DataDisplay'

const Navigation = () => {
  return (
      <Router>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            {/* <Route path="/dashboard" element={<Home />} /> */}
            <Route path="/whoweare" element={<WhoWeAre />} />
            <Route path="/projects" element={<SubmitProjectList />} />
            <Route path="/create-new-project" element={<MultistepApp />} />
            {/* Define the dynamic route with projectdetailId */}
            <Route path="/ProjectDetails/:projectdetailId" element={<ProjectDetail />} />
            <Route path="/ProjectInvestment/:projectdetailId" element={<ProjectInvestment />} />
          </Routes>
      </Router>
  );
};

export default Navigation;

import React, { useEffect, useState } from 'react';
import "./projectDetail.css";
import { useNavigate, useParams } from 'react-router-dom';
import { ApiListOfCompletedProject, FilterFilesAttachments, FilterProCompletionSubGoal, FilterProCompletionSubGoalDesc, GetAllPublishedProjects } from '../../../services';
import { useLazyQuery, useQuery } from '@apollo/client';
import { filterFileAttachments, filterProjectById, listOfCompletedProject } from '../../../graphql/queries';
import API from '../../../graphql';
import Footer from '../../components/footer';
import { Box, CircularProgress } from '@mui/material';
import client from '../../../helpers';

const ProjectDetail = () => {
  const [loadGoals, setLoadGoals] = useState(false);
  const [completedPercentage, setcompletedPercentage] = useState(false);
  const [details, setDetails] = useState();
  const [criticalGols, setCriticalGols] = useState([]);
  const [criticalSubGols, setCriticalSubGols] = useState([]);
  // const [goals, setGoals] = useState();
  const [goalsWithChild, setGoalsgoalsWithChild] = useState();
  const [categoryList, setCategoryList] = useState(null);
  // const [filterGolsData, setFilterGolsData] = useState(null)
  const navigate = useNavigate();
  const { projectdetailId } = useParams();
  const sectors = useQuery(API.ApiSectors);
  const categories = useQuery(API.ApiCategories);
  const projectData = useQuery(filterProjectById, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: projectdetailId
    }
  });
  const projectAttachments = useQuery(filterFileAttachments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      "filter": {
        "projectId": {
          "id": {
            "eq": projectdetailId
          }
        }
      }
    }
  });
  const { data, error, loading } = useQuery(listOfCompletedProject, {
    fetchPolicy: "network-only",
    variables: {
      "filter": {
        "projectId": {
          "id": {
            "eq": projectdetailId
          }
        }
      }
    }
  });
  const [filterCompletedSubGaols, CompletedSubGaols] = FilterProCompletionSubGoal();
  const [filterCompletedSubGaolsDesc, CompletedSubGaolsDesc] = FilterProCompletionSubGoalDesc();
  const [attachments, setAttachments] = useState([]);
  const asynceFuncForUseEffect = async () => {
    setLoadGoals(true);
    const CategoryData = categories?.data.categories.data;
    const projectComp = [...data?.projectCompletions?.data];
    projectComp.sort((a, b) => (a.attributes?.CategorySequence ?? Infinity) - (b.attributes?.CategorySequence ?? Infinity));
    if (!projectComp?.length) {
      setLoadGoals(false);
      return false;
    }
    let concatenatedArray = [...criticalSubGols];
    let concatenatedSubGoalsArray = [...criticalGols];
    let completed_percentage = 0;
    const finalList = [];
    if (projectComp?.length) {
      const projectCompIds = projectComp.map((e) => e.id);
      const listOfCompSubGoals = await CompletedSubGaols({
        "filter": {
          "project_completion": {
            "id": {
              "in": projectCompIds
            }
          }
        }
      });
      if (listOfCompSubGoals?.data) {
        const listOfCSG = listOfCompSubGoals?.data?.projectCompletionSubGoals?.data;
        if (listOfCSG?.length) {
          const listOfCSGIds = listOfCSG.map((e) => e.id);
          const listOfGoalsDesc = await CompletedSubGaolsDesc({
            "filter": {
              "project_completion_sub_goal_id": {
                "id": {
                  "in": listOfCSGIds
                }
              }
            }
          });
          const listOfCSGD = listOfGoalsDesc?.data?.projCompSubGoalDescs?.data;
          for (let key in projectComp) {
            const arraySBGD = [];
            for (let SBD in listOfCSG) {
              const currentCSG = listOfCSG[SBD];
              if (currentCSG?.attributes?.project_completion?.data.id === projectComp[key].id) {
                let copy = { ...listOfCSG[SBD] };
                const filteredListOfCSGD = listOfCSGD?.filter(item => item?.attributes?.project_completion_sub_goal_id?.data?.id === copy.id);
                filteredListOfCSGD.sort((a, b) => (a.attributes?.CategorySequence ?? Infinity) - (b.attributes?.CategorySequence ?? Infinity));
                const allCriticalSubGoals = filteredListOfCSGD.filter(item => item?.attributes?.Criteria_Type === 'Critical');
                // Assuming allCriticalSubGoals is an array
                concatenatedArray = allCriticalSubGoals.length > 0
                  ? [...concatenatedArray, ...allCriticalSubGoals]
                  : concatenatedArray;
                setCriticalSubGols(concatenatedArray);
                let copy1 = { id: copy.id, ...copy.attributes, listOfSubGoalsDesc: filteredListOfCSGD };
                let obj = {
                  attributes: { ...copy1 }
                };
                arraySBGD.push(obj);
              }
            };
            let mainGoals = { ...projectComp[key] };
            let copy = { id: mainGoals.id, ...mainGoals.attributes };
            arraySBGD.sort((a, b) => (a.attributes?.CategorySequence ?? Infinity) - (b.attributes?.CategorySequence ?? Infinity));
            completed_percentage += (arraySBGD || []).reduce((sum, goal) =>
              sum + ((goal?.attributes?.isCompleted ? +goal?.attributes?.Percentage_sub : 0)), 0);
            let goalId = { ...copy?.goalId?.data?.attributes, haveSubGoals: true, listOfSubGoals: arraySBGD, isCompleted: copy.isCompleted };
            let obj = {
              data: {
                attributes: { ...goalId }
              }
            };
            let finalCopy = { ...copy, goalId: obj };
            finalList.push(finalCopy);
          }
        }
      }
      setGoalsgoalsWithChild(finalList);
      setLoadGoals(false);
    }
    if (CategoryData?.length) {
      let array = [];
      for (let key in CategoryData) {
        const selectedArrayGoals = projectComp?.filter((e) => e?.attributes.categoryId.data.attributes.SharePointId === CategoryData[key].attributes.SharePointId);
        completed_percentage += (selectedArrayGoals || []).reduce((sum, goal) =>
          sum + ((goal?.attributes?.isCompleted ? +goal?.attributes?.Completed_Percentage : 0)), 0);
        const allCriticalGoals = selectedArrayGoals.filter(item => item?.attributes?.Criteria_Type === 'Critical');
        concatenatedSubGoalsArray = allCriticalGoals.length > 0
          ? [...concatenatedSubGoalsArray, ...allCriticalGoals]
          : concatenatedSubGoalsArray;
        setCriticalGols(concatenatedSubGoalsArray);
        array.push({
          Category_Name: CategoryData[key].attributes.Category_Name,
          SharePointId: CategoryData[key].attributes.SharePointId,
          CategorySequence: CategoryData[key].attributes.CategorySequence,
          id: CategoryData[key].id,
          isSelected: true,
          goals: selectedArrayGoals
        });
      }
      const sortedArray = array.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
      setCategoryList(sortedArray);
      setLoadGoals(false);
    }
    setcompletedPercentage(completed_percentage);
  };
  useEffect(() => {
    if (!loading) {
      if (!loadGoals) {
        asynceFuncForUseEffect();
      }
      if (projectData?.data) {
        const id = projectData?.data?.project?.data?.id;
        const data = projectData?.data?.project.data.attributes;
        const sectorsList = sectors?.data?.sectors?.data;
        const selectedSectors = sectorsList?.find(item => item.id === data?.sector?.data?.id);
        setDetails({
          projectName: data?.Project_name,
          sector: data?.sector.data?.attributes.Sector_Name,
          sectorIcons: selectedSectors?.attributes?.File_Url,
          country: data?.country.data?.attributes.Country_Name,
          city: data?.city.data?.attributes.City_Name,
          EBIT_Margin: data?.EBIT_Margin,
          gpMargin: data?.GP_Margin,
          netProfit: data?.Net_Profit_Margin,
          TimeLine_Year: data?.TimeLine_Year,
          TimeLine_Month: data?.TimeLine_Month,
          ticketSize: data?.Ticket_Size,
          contactPerson: data?.Contact_Person,
          mobileNumber: data?.Mobile_No,
          email: data?.Email,
          id: id,
          Project_Cost: data?.Project_Cost,
          currency: data?.currencyId.data?.attributes.Title,
          currencySymbol: data?.currencyId.data?.attributes.Symbol,
          status: data?.Project_Status,
          ProjectComments: data?.ProjectComments,
          RecommendationStatus: data?.RecommendationStatusId.data?.attributes.Title,
        });
      }
    }
    if (projectAttachments?.data) {
      const listOfAttach = projectAttachments?.data?.attachments?.data;
      if (listOfAttach.length) {
        setAttachments(listOfAttach);
      }
    }
  }, [categories, projectData, data, loading, projectAttachments]);

  const toggleFirstVisible = (id) => {
    const list = [...categoryList];
    for (let key in list) {
      if (list[key].SharePointId === id) {
        list[key].isSelected = !list[key].isSelected;
      }
    }
    const sortedArray = list.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
    setCategoryList(sortedArray);
  };
  //table pa click kar ka scroll karwany ki logic
  useEffect(() => {
    // Add event listeners when the component is mounted
    const projectDetailbarsCont = document.getElementById('projectDetailbarsCont');
    if (projectDetailbarsCont) {
      let isDragging = false;
      let startX, scrollLeft;

      const handleMouseDown = (e) => {
        // console.log('Mouse Down');
        isDragging = true;
        startX = e.pageX - projectDetailbarsCont.offsetLeft;
        scrollLeft = projectDetailbarsCont.scrollLeft;
      };

      const handleMouseUp = () => {
        // console.log('Mouse Up');
        isDragging = false;
      };

      const handleMouseLeave = () => {
        // console.log('Mouse Leave');
        isDragging = false;
      };

      const handleMouseMove = (e) => {
        // console.log('Mouse Move');
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - projectDetailbarsCont.offsetLeft;
        const walk = (x - startX) * 3; // You can adjust the scrolling speed here
        projectDetailbarsCont.scrollLeft = scrollLeft - walk;
      };

      projectDetailbarsCont.addEventListener('mousedown', handleMouseDown);
      projectDetailbarsCont.addEventListener('mouseup', handleMouseUp);
      projectDetailbarsCont.addEventListener('mouseleave', handleMouseLeave);
      projectDetailbarsCont.addEventListener('mousemove', handleMouseMove);

      // Clean up event listeners when the component is unmounted
      return () => {
        projectDetailbarsCont.removeEventListener('mousedown', handleMouseDown);
        projectDetailbarsCont.removeEventListener('mouseup', handleMouseUp);
        projectDetailbarsCont.removeEventListener('mouseleave', handleMouseLeave);
        projectDetailbarsCont.removeEventListener('mousemove', handleMouseMove);
      };
    }
  }, []); // The empty dependency array ensures this effect runs only once

  // Function to handle price cost
  function formatProjectCost(cost) {
    if (cost >= 1e15) {
      return (cost / 1e15).toFixed(1) + ' Q';
    } else if (cost >= 1e12) {
      return (cost / 1e12).toFixed(1) + ' T';
    } else if (cost >= 1e9) {
      return (cost / 1e9).toFixed(1) + ' B';
    } else if (cost >= 1e6) {
      return (cost / 1e6).toFixed(1) + ' M';
    } else if (cost >= 1e3) {
      return (cost / 1e3).toFixed(1) + ' K';
    } else {
      return cost;
    }
    // else {
    //   console.log("cost",cost)
    //   return cost.toFixed(2);
    // }
  }
  // Function to handle file download
  const handleDownloadClick = (fileUrl, fileName) => {
    // Create a link element
    const downloadLink = document.createElement('a');
    // Set the download link's href to the file URL
    downloadLink.href = "https://admin.sdg.hexalyze.com" + fileUrl;
    // Use the 'download' attribute to set the default file name
    downloadLink.setAttribute('download', fileName);
    // Trigger a click event to start the download
    downloadLink.click();
  };
  // Function to handle esg Factors open close
  const [openStep1, setOpenStep1] = useState(null); // State for open step1 goal
  const [openStep2, setOpenStep2] = useState(null); // State for open step2 goal

  const handleStep1RowClick = (index) => {
    if (openStep1 === index) {
      setOpenStep1(null);
    } else {
      setOpenStep1(index);
    }
  };

  const handleStep2RowClick = (index) => {
    if (openStep2 === index) {
      setOpenStep2(null);
    } else {
      setOpenStep2(index);
    }
  };

  const checkRecommendationAndCritical = () => {
    const allCriticalGoals = [...criticalGols, ...criticalSubGols];
    if (allCriticalGoals && allCriticalGoals.length && allCriticalGoals.every(item => item?.attributes?.isCompleted)) {
      return `This project is ${details?.RecommendationStatus} and all critical goals are achieved`;
    }
    if (allCriticalGoals && allCriticalGoals.length && allCriticalGoals.some(item => !item?.attributes?.isCompleted)) {
      return `This project is ${details?.RecommendationStatus} and all critical goals are not achieved`;
    }
    return '';
  };

  return (
    <div className='projectDetailsCenter'>
      <div className='ProjectDetailContainer'>
        <div className="projectDetailHeadCont projectDetailWebNavbar">
          <div className="projectDetailLogoCont">
            <div className="projectDetailMainLogoCont pointer-cursor" onClick={() => navigate("/")}>
              <img src="/assits/mainLogo.png" alt="Logo" />
            </div>
            <img className="Project-Logo" src="/assits/projectDetailMainLogo.png" alt="projectDetail-Logo" />
            <div className='tooltip'>
              <div>
                {details?.projectName.length > 10 ? (
                  <>
                    <h1 className="projectDetailHeading">
                      {details?.projectName.slice(0, 10) + "..."}   |
                    </h1>
                    <span className='tooltiptext'>{details?.projectName}</span>
                  </>
                ) : (
                  <h1 className="projectDetailHeading">{details?.projectName}   |</h1>
                )}
              </div>
            </div>
            <button onClick={() => navigate(`/ProjectInvestment/${projectdetailId}`)} className='projectDetailHeadingButton pointer-cursor'><img src='/assits/Icon/projectDetailButtonIcon.png' alt='projectDetail-buttonIcon' /> Invest in the Project</button>
          </div>
          <div className="projectDetailCloseCont" onClick={() => navigate(-1)}>
            <div className="closeLink">
              <img className='pointer-cursor' src="/assits/Icon/closeIcon.png" alt="Delet-Icon" />
              <span className='pointer-cursor'>Close</span>
            </div>
          </div>
        </div>
        <div className='projectDetailMobileNavbar'>
          <div className="projectDetailHeadCont">
            <div className="projectDetailLogoCont">
              <div className="projectDetailMainLogoCont pointer-cursor">
                <img src="/assits/mainLogo.png" alt="Logo" />
              </div>
            </div>
            <div className="projectDetailCloseCont" onClick={() => navigate(-1)}>
              <div className="closeLink">
                <img className='pointer-cursor' src="/assits/Icon/closeIcon.png" alt="Delet-Icon" />
                <span className='pointer-cursor'>Close</span>
              </div>
            </div>
          </div>
          <div className='projectDetailHeadingButtonCont'>
            <div className="projectDetailHeadingButtonContMobile">
              <div>
                <img className="projectDetail-Logo" src="/assits/projectDetailMainLogo.png" alt="projectDetail-Logo" />
                <h1 className="projectDetailHeading">Project Details |</h1>
              </div>
              <div>
                <button onClick={() => navigate(`/ProjectInvestment/${projectdetailId}`)} className='projectDetailHeadingButtonMobile'><img src='/assits/Icon/projectDetailButtonIcon.png' alt='projectDetail-buttonIcon' /> Invest in the Project</button>
              </div>
            </div>
          </div>
        </div>
        <div className='projectDetailBodyMainCont'>
          <div className='projectDetailBodyCont'>
            <div className='section1'>
              <div className='projectDetailInfoCont'>
                <img src='/assits/projectIcon.png' alt='project-Logo' />
                <span>Project Info</span>
              </div>
              <div className='projectDetailInfoDiv1'>
                <div className='tooltip'>
                  <h4>Project Name</h4>
                  {details?.projectName.length > 10 ? (
                    <>
                      <span className='tooltiptext'>{details?.projectName}</span>
                      <span className='tooltipPopup'>
                        {details?.projectName.slice(0, 10) + "..."}
                      </span>
                    </>
                  ) : (
                    <span>{details?.projectName}</span>
                  )}
                </div>
                <div className='tooltip'>
                  <h4>Sector</h4>
                  {details?.sector?.length > 10 ? (
                    <>
                      <span className='tooltiptext sectorSpan'>
                        {details?.sector}</span>
                      <span className='tooltipPopup sectorSpan'>
                        <img
                          className='projectDetailInfoDiv1SectorIcon sector-icon'
                          src={`https://admin.sdg.hexalyze.com${details?.sectorIcons}`}
                          alt='sector-Icon'
                        />
                        {details?.sector.slice(0, 10) + "..."}
                      </span>
                    </>
                  ) : (
                    <span className='sectorSpan'>
                      <img
                        className='projectDetailInfoDiv1SectorIcon sector-icon'
                        src={`https://admin.sdg.hexalyze.com${details?.sectorIcons}`}
                        alt='sector-Icon'
                      />
                      {details?.sector}
                    </span>
                  )}
                </div>
                <div>
                  <h4>Country</h4>
                  <span>{details?.country || ""}</span>
                </div>
                <div>
                  <h4>City</h4>
                  <span>{details?.city || ""}</span>
                </div>
                {/* <div>
                  <h4>IRR</h4>
                  <span>{details?.irr} <img className='projectDetailInfoDiv1ParsentIcon' src='/assits/inputParsentIcon.png' alt='ParsentIcon' /></span>
                </div> */}
                {/* <div>
                  <h4>ROI</h4>
                  <span>{details?.roi} <img className='projectDetailInfoDiv1ParsentIcon' src='/assits/inputParsentIcon.png' alt='ParsentIcon' /></span>
                </div> */}
                <div>
                  <h4>EBIT Margin</h4>
                  <span>{details?.EBIT_Margin} <img className='projectDetailInfoDiv1ParsentIcon' src='/assits/inputParsentIcon.png' alt='ParsentIcon' /></span>
                </div>
                <div>
                  <h4>GP Margin</h4>
                  <span>{details?.gpMargin} <img className='projectDetailInfoDiv1ParsentIcon' src='/assits/inputParsentIcon.png' alt='ParsentIcon' /></span>
                </div>
                <div>
                  <h4>Net Profit Margin</h4>
                  <span>{details?.netProfit} <img className='projectDetailInfoDiv1ParsentIcon' src='/assits/inputParsentIcon.png' alt='ParsentIcon' /></span>
                </div>
              </div>
              <div className='projectDetailInfoDiv1'>
                <div>
                  <h4>Timeline</h4>
                  <span>
                    {(details?.TimeLine_Year || 0) + " Year" + (details?.TimeLine_Year > 1 ? "s" : "") + " " +
                      (details?.TimeLine_Month || 0) + " Month" + (details?.TimeLine_Month > 1 ? "s" : "")}
                  </span>
                  {/* <span>{(details?.TimeLine_Year || 0) + " Year " + (details?.TimeLine_Month || 0) + " Month"}</span> */}
                </div>
                <div>
                  <h4>Project Cost</h4>
                  <span className='sectorSpan'>
                    <b style={{ color: '#FDBA74', marginRight: '5px' }}>{details?.currencySymbol}</b>
                    {formatProjectCost(details?.Project_Cost || 0)}
                  </span>
                </div>
                <div className='tooltip'>
                  <h4>Contact Person</h4>
                  {details?.contactPerson.length > 20 ? (
                    <>
                      <span className='tooltiptext'>{details?.contactPerson}</span>
                      <span className='tooltipPopup'>
                        {details?.contactPerson.slice(0, 20) + "..."}
                      </span>
                    </>
                  ) : (
                    <span>{details?.contactPerson}</span>
                  )}
                </div>
                <div>
                  <h4>Mobile No</h4>
                  <span>{details?.mobileNumber || ""}</span>
                </div>
                <div className='tooltip'>
                  <h4>Email</h4>
                  {details?.email.length > 20 ? (
                    <>
                      <span className='tooltiptext'>{details?.email}</span>
                      <span className='tooltipPopup'>
                        {details?.email.slice(0, 20) + "..."}
                      </span>
                    </>
                  ) : (
                    <span>{details?.email}</span>
                  )}
                </div>
                <div>
                  <h4>Outcome</h4>
                  <span>{details?.RecommendationStatus}</span>
                </div>
                <div>
                  <h4>Total Percentage</h4>
                  <span>100 %</span>
                </div>
              </div>
              <div className='projectDetailInfoDiv1'>
                <div>
                  <h4>Completed Percentage</h4>
                  <span>{completedPercentage ? completedPercentage.toFixed(2) : 0} %</span>
                </div>
                {checkRecommendationAndCritical() ? <div style={{ width: '365px' }}>
                  <h4>Result</h4>
                  <span>{checkRecommendationAndCritical()}</span>
                </div> : null}
                <div />
                <div />
                <div />
              </div>
              <div className='AttachedFileMainCont' >
                <h4>Attached Documents</h4>
                <div className='AttachedFileName'>
                  {attachments && attachments.length ? (
                    attachments.map((v, i) => (
                      <div key={i} className='AttachedFileNameCont'>
                        <span>{v?.attributes?.name}</span>
                        <img
                          className='pointer-cursor'
                          src='/assits/Icon/attachmintDownloadIcon.png'
                          alt={v?.attributes?.name}
                          onClick={() => handleDownloadClick(v?.attributes?.url, v?.attributes?.name)}
                        />
                      </div>
                    ))
                  ) : (
                    <div>
                      <span className='noFileAttachmentmessage'>No file attached!</span>
                    </div>
                  )}
                </div>
              </div>
              <div className='AttachedFileMainCont'>
                {(details && details.ProjectComments) ? <div className="comment-container">
                  <h4>Project Comments</h4>
                  <span className="comment-text">{details.ProjectComments}</span>
                </div> : null}
              </div>

            </div>
            <div className='section2'>
              <div className='projectDetailInfoCont'>
                <img src='/assits/goalsIcon.png' alt='project-Logo' />
                <span>Goals & Descriptions</span>
              </div>
              {loadGoals ? (
                <div className="projectDetailsLoder">
                  <img className='loaderImg' src='/assits/loaderGif.gif' alt='LoaderImg' />
                </div>
              ) : (
                <div className='projectDetailbarsCont' id='projectDetailbarsCont'>
                  <div className='projectDetailbar'>
                    {categoryList && categoryList?.length && categoryList.map((v, i) => {
                      let nmbr = 1;
                      if (v.Category_Name.toLowerCase() === "esg factors") {
                        return (goalsWithChild && goalsWithChild.find(item => item?.categoryId?.data?.id === v.id)) ? (
                          <div key={i}>
                            <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${v.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(v.SharePointId)}>
                              <div>
                                <span style={{ color: `${v.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {v.Category_Name}</span>
                              </div>
                              <img src={`../assits/${v.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                            </div>
                            {(v.isSelected) ? (
                              <div className='contant'>
                                <table className='esgFectorProjectDetailTable'>
                                  <thead>
                                    <tr>
                                      <td colSpan={3}>S.No</td>
                                      <td>
                                        <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                      </td>
                                      <td className='goalTd'>Goals</td>
                                      <td className='goal-descriptionTd'>Sub-Goals / Nested-Goals</td>
                                      <td className='Criteria-TypeTd'>Criteria Type</td>
                                      <td className='goals-Completed'>Percentage</td>
                                      <td className='goals-Completed'>Completed</td>
                                      <td className='goals-Comment'>Comment</td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {goalsWithChild ? goalsWithChild?.map((g, i) => {
                                      if (g.categoryId?.data?.id === v.id) {
                                        return (
                                          <>
                                            <tr key={i} className='goalsFormTbodyesgFectorTr' onClick={() => handleStep1RowClick(i)}>
                                              <td >{nmbr++}</td>
                                              <td></td>
                                              <td></td>
                                              <td >
                                                <img src='../assits/tableMarkIcon.png' alt='' />
                                              </td>
                                              <td className='goalsTitleCont'>
                                                {g?.goalId?.data?.attributes?.Title}
                                              </td>
                                              <td>
                                                <img
                                                  className='pointer-cursor'
                                                  src={`../assits/${i === openStep1 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                  alt=''
                                                />
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>
                                              </td>
                                            </tr>
                                            {g?.goalId?.data?.attributes?.listOfSubGoals.map((elem, idx) => {
                                              return <>
                                                <tr className={`step1Tr ${openStep1 === i ? '' : 'hidden'}`} onClick={() => handleStep2RowClick(idx)} style={{ backgroundColor: "#d7e3ed" }}>
                                                  <td className='lineMarkTd'><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                  <td className='step1No' colSpan={2}>{idx + 1}</td>
                                                  <td>
                                                    <img src='../assits/tableMarkIcon.png' alt='' />
                                                  </td>
                                                  <td >
                                                    {elem?.attributes?.sub_goal_id?.data?.attributes?.Title}
                                                  </td>
                                                  <td>
                                                    <img
                                                      className='pointer-cursor'
                                                      src={`../assits/${idx === openStep2 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                      alt=''
                                                    />
                                                  </td>
                                                  <td></td>
                                                  <td>{elem?.attributes?.Percentage_sub ? parseFloat(elem.attributes.Percentage_sub).toFixed(2) + '%' : '0%'}</td>
                                                  <td>{elem?.attributes?.isCompleted ? "Yes" : "No"}</td>
                                                  <td></td>
                                                </tr>
                                                {elem?.attributes?.listOfSubGoalsDesc && elem?.attributes?.listOfSubGoalsDesc?.map((item, ind) => {
                                                  return (
                                                    <tr key={ind} className={`step2Tr ${openStep1 === i && openStep2 === idx ? '' : 'hidden'}`}>
                                                      <td></td>
                                                      <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                      <td>{ind + 1}</td>
                                                      <td>
                                                        <img src='../assits/tableMarkIcon.png' alt='' />
                                                      </td>
                                                      <td colSpan={2} >
                                                        {item?.attributes?.sub_goal_description_id?.data?.attributes?.Title}
                                                      </td>
                                                      <td>{item?.attributes?.Criteria_Type}</td>
                                                      <td></td>
                                                      <td>
                                                        {item?.attributes?.isCompleted ? "Yes" : "No"}
                                                      </td>
                                                      <td>
                                                        {item?.attributes?.Comments || "Nill"}
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </>;
                                            })}
                                          </>
                                        );
                                      }
                                    }) : null}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>) : null;
                      } else if (v.Category_Name.toLowerCase() === "sdg lens alignment") {
                        return (!!v?.goals?.length) ? <div key={i}>
                          <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${v.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(v.SharePointId)}>
                            <div>
                              <span style={{ color: `${v.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {v.Category_Name}</span>
                            </div>
                            <img src={`../assits/${v.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                          </div>
                          {(v.isSelected && !!v?.goals?.length) ?
                            <div className='contant'>
                              <table className='ProjectDetailTable sdg-table'>
                                <thead>
                                  <tr>
                                    <td>S.No</td>
                                    <td>
                                      <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                    </td>
                                    <td className='goalTd'>Goals</td>
                                    <td className='goal-descriptionTd'>Goals Description</td>
                                    <td className='Criteria-TypeTd'>Criteria Type</td>
                                    <td className='goals-Completed'>Percentage</td>
                                    <td className='goals-Completed'>Completed</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!!v?.goals?.length && v.goals.map((e, i) => {
                                    const data = e.attributes;
                                    return <React.Fragment key={e.id} >
                                      <tr className='goalsFormTbodyTr'>
                                        <td>{i + 1}</td>
                                        <td>
                                          <img src='../assits/tableMarkIcon.png' alt='' />
                                        </td>
                                        <td className='goalsName'>{data?.goalId?.data?.attributes?.Title || ""}</td>
                                        <td className='descriptionCont'>{data?.goalId?.data?.attributes?.Description || ""}</td>
                                        <td>{data.Criteria_Type}</td>
                                        <td>
                                          {data?.Completed_Percentage || 0}%
                                        </td>
                                        <td>
                                          {data?.isCompleted ? "Yes" : "No"}
                                        </td>
                                      </tr>
                                      {data?.isCompleted ? <React.Fragment>
                                        {data?.goalId?.data?.attributes?.Criteria ? <tr>
                                          <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                          <td colSpan={6}>{data?.goalId?.data?.attributes?.Criteria}</td>
                                        </tr> : null}
                                        <tr>
                                          <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                          <td colSpan={6}>{data.Comments}</td>
                                        </tr>
                                      </React.Fragment> : null}
                                    </React.Fragment>;
                                  })}
                                </tbody>
                              </table>
                            </div> : null}
                        </div> : null;
                      } else {
                        return (!!v?.goals?.length) ? <div key={i}>
                          <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${v.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => toggleFirstVisible(v.SharePointId)}>
                            <div>
                              <span style={{ color: `${v.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {v.Category_Name}</span>
                            </div>
                            <img src={`../assits/${v.isSelected ? 'upIcon.png' : 'downIcon.png'}`} alt='' />
                          </div>
                          {(v.isSelected && !!v?.goals?.length) ?
                            <div className='contant'>
                              <table className='ProjectDetailTable'>
                                <thead>
                                  <tr>
                                    <td>S.No</td>
                                    <td>
                                      <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                    </td>
                                    <td className='goalTd'>Goals</td>
                                    <td className='goal-descriptionTd'>Goals Description</td>
                                    <td className='Criteria-TypeTd'>Criteria Type</td>
                                    <td className='goals-Completed'>Percentage</td>
                                    <td className='goals-Completed'>Completed</td>
                                    <td className='goals-Comment'>Comment</td>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!!v?.goals?.length && v.goals.map((e, i) => {
                                    const data = e.attributes;
                                    return <tr key={e.id} className='goalsFormTbodyTr'>
                                      <td>{i + 1}</td>
                                      <td>
                                        <img src='../assits/tableMarkIcon.png' alt='' />
                                      </td>
                                      <td className='goalsName'>{data?.goalId?.data?.attributes?.Title || ""}</td>
                                      <td className='descriptionCont'>{data?.goalId?.data?.attributes?.Description || ""}</td>
                                      <td>{data.Criteria_Type}</td>
                                      <td>
                                        {data?.Completed_Percentage || 0}%
                                      </td>
                                      <td>
                                        {data?.isCompleted ? "Yes" : "No"}
                                      </td>
                                      <td className='commentCont'>
                                        {data?.Comments || "Nill"}
                                      </td>
                                    </tr>;
                                  })}
                                </tbody>
                              </table>
                            </div> : null}
                        </div> : null;
                      }
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div >
      <Footer />
    </div>
  );
};

export default ProjectDetail;
import React, { useEffect, useState } from 'react';
import './style.css';
import { Inputs } from '../../input';
import CustomSwitch from '../../customSwitch.js';

const GoalsAndDescription = ({
    projectDetails,
    sectorsList,
    CategorySelectInput,
    goals,
    goalCompleteMark,
    goalCommentHandler,
    goalCommentHandlerInNested,
    goalCompleteMarkInNested,
    handleScreeningPersValue,
    categoryId,
    categoryList,
    loadingFlag
}) => {
    const [openStep1, setOpenStep1] = useState(null); // State for open step1 goal
    const [openStep2, setOpenStep2] = useState(null); // State for open step2 goal

    const handleStep1RowClick = (index) => {
        if (openStep1 === index) {
            setOpenStep1(null);
        } else {
            setOpenStep1(index);
        }
    };

    const handleStep2RowClick = (index) => {
        if (openStep2 === index) {
            setOpenStep2(null);
        } else {
            setOpenStep2(index);
        }
    };


    // Function to adjust the heights of all textareas
    const adjustTextareaHeights = () => {
        const textareas = document.querySelectorAll('.auto-resize-textarea');
        for (let i = 0; i < textareas.length; i++) {
            const textarea = textareas[i];
            textarea.style.height = 'auto';
            if (textarea.value) {
                textarea.style.height = (textarea.scrollHeight === 32 ? 0 : textarea.scrollHeight) + 'px';
            } else {
                textarea.style.height = '0px';
            }
        }
    };

    //table pa click kar ka scroll karwany ki logic
    useEffect(() => {
        // Add event listeners when the component is mounted
        const tableContScroll = document.getElementById('tableContScroll');
        if (tableContScroll) {
            let isDragging = false;
            let startX, scrollLeft;

            const handleMouseDown = (e) => {
                // console.log('Mouse Down');
                isDragging = true;
                startX = e.pageX - tableContScroll.offsetLeft;
                scrollLeft = tableContScroll.scrollLeft;
            };

            const handleMouseUp = () => {
                // console.log('Mouse Up');
                isDragging = false;
            };

            const handleMouseLeave = () => {
                // console.log('Mouse Leave');
                isDragging = false;
            };

            const handleMouseMove = (e) => {
                if (!isDragging) return;
                e.preventDefault();
                const x = e.pageX - tableContScroll.offsetLeft;
                const walk = (x - startX) * 3; // You can adjust the scrolling speed here
                tableContScroll.Left = scrollLeft - walk;
            };

            tableContScroll.addEventListener('mousedown', handleMouseDown);
            tableContScroll.addEventListener('mouseup', handleMouseUp);
            tableContScroll.addEventListener('mouseleave', handleMouseLeave);
            tableContScroll.addEventListener('mousemove', handleMouseMove);

            // Clean up event listeners when the component is unmounted
            return () => {
                tableContScroll.removeEventListener('mousedown', handleMouseDown);
                tableContScroll.removeEventListener('mouseup', handleMouseUp);
                tableContScroll.removeEventListener('mouseleave', handleMouseLeave);
                tableContScroll.removeEventListener('mousemove', handleMouseMove);
            };
        }
    }, []); // The empty dependency array ensures this effect runs only once
    return (
        <div className='step2Cont'>
            {loadingFlag &&
                <div className="projectSubmintLoder">
                    <img className='loaderImg' src='/assits/loaderGif.gif' alt='LoaderImg' />
                </div>
            }
            <div className='scrollCont' id='tableContScroll'>
                <div className='tableCont'>
                    {categoryList?.length && categoryList.map((e, i) => {
                        let nmbr = 1;
                        if (e.name.toLowerCase() === "esg factors") {
                            return (goals && goals.find(item => item.categoryId === e.id)) ? (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => null}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td colSpan={3}>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td className='goalTd'>Goals</td>
                                                <td className='goal-descriptionTd'>Goal Description to Sub-Goals / Nested-Goals </td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td className='goals-Completed'>Completed</td>
                                                <td>Percentage</td>
                                                <td className='goals-Comment'>Comment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {goals.length ? goals?.map((v, j) => {
                                                // let truncatedDescription = v.Description;
                                                // if (truncatedDescription?.length > 300) {
                                                //     truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                // }
                                                if (v.categoryId === e.id) {
                                                    // console.log("categoryId", v)
                                                    return (
                                                        <>
                                                            <tr key={j} className='esgFectorgoalsFormTbodyTr' onClick={() => handleStep1RowClick(j)}>
                                                                <td >{nmbr++}</td>
                                                                <td></td>
                                                                <td></td>
                                                                <td >
                                                                    <img src='../assits/tableMarkIcon.png' alt='' />
                                                                </td>
                                                                <td className='goalsTitleCont'>
                                                                    {v.Title}
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        className='pointer-cursor'
                                                                        src={`../assits/${j === openStep1 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                                        alt=''
                                                                    />
                                                                </td>
                                                                {/* <td>{v.Criteria_Type}</td> */}
                                                                {/* <td>{v.completed ? "Yes" : "No"}</td> */}
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td className='goal-descriptionTd'></td>
                                                            </tr>
                                                            {v.subGaolsList.map((elem, idx) => {
                                                                return <> <tr key={idx} className={`step1Tr ${openStep1 === j ? '' : 'hidden'}`} onClick={() => handleStep2RowClick(idx)}>
                                                                    <td className='lineMarkTd'><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                    <td className='step1No' colSpan={2}>{idx + 1}</td>
                                                                    <td>
                                                                        <img src='../assits/tableMarkIcon.png' alt='' />
                                                                    </td>
                                                                    <td >
                                                                        {elem.Title}
                                                                    </td>
                                                                    <td>
                                                                        <img
                                                                            className='pointer-cursor'
                                                                            src={`../assits/${idx === openStep2 ? 'nestedUpArrow.png' : 'nestedDownArrow.png'}`}
                                                                            alt=''
                                                                        />
                                                                    </td>
                                                                    <td></td>
                                                                    <td>{elem.completed ? "Yes" : "No"}</td>
                                                                    <td>{elem.subGoalPercentage}</td>
                                                                    <td></td>
                                                                </tr>
                                                                    {
                                                                        elem.subGoalDescList.map((item, ind) => {
                                                                            // console.log(item)
                                                                            return <tr key={ind} className={`step2Tr ${openStep1 === j && openStep2 === idx ? '' : 'hidden'}`}>
                                                                                <td></td>
                                                                                <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                                <td>{ind + 1}</td>
                                                                                <td>
                                                                                    <img src='../assits/tableMarkIcon.png' alt='' />
                                                                                </td>
                                                                                <td colSpan={2} >
                                                                                    {item.Title}
                                                                                </td>
                                                                                <td>{item.Criteria_Type}</td>
                                                                                <td>
                                                                                    <CustomSwitch
                                                                                        checked={item.completed}
                                                                                        onChange={() => goalCompleteMarkInNested(j, idx, ind)}
                                                                                    />
                                                                                </td>
                                                                                <td></td>
                                                                                <td >
                                                                                    <textarea
                                                                                        onChange={(e) => goalCommentHandlerInNested(e, j, idx, ind)}
                                                                                        className='tableInput'
                                                                                        type='text'
                                                                                        placeholder='Add Comment'
                                                                                        value={item.comment}
                                                                                    />
                                                                                </td>
                                                                            </tr>;
                                                                        })
                                                                    }</>;
                                                            })}
                                                        </>
                                                    );
                                                }
                                            }) : null}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null;
                        } else if (e.name.toLowerCase() === "sdg lens alignment") {
                            return (goals && goals.find(item => item.categoryId === e.id)) ? (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => null}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                    </div>
                                    <table className='sdg-table'>
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td className='goalTd'>Goals</td>
                                                <td className='goal-descriptionTd'>Goals Description</td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td>Completed</td>
                                                <td>Percentage</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {goals.length ? goals?.map((v, i) => {
                                                let truncatedDescription = v.Description;
                                                if (truncatedDescription?.length > 300) {
                                                    truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                }
                                                if (v.categoryId === e.id) {
                                                    return <React.Fragment key={i} >
                                                        <tr className={`goalsFormTbodyTr`}>
                                                            <td>{nmbr++}</td>
                                                            <td>
                                                                <img src='../assits/tableMarkIcon.png' alt='' />
                                                            </td>
                                                            <td className='goalsTitleCont'>
                                                                {v.Title}
                                                            </td>
                                                            <td>{truncatedDescription}</td>
                                                            <td>{v.Criteria_Type}</td>
                                                            <td>
                                                                <CustomSwitch
                                                                    checked={v.completed}
                                                                    onChange={() => {
                                                                        if (Number(v.ScreeningPercentage) === 0) {
                                                                            goalCompleteMark(i);
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            {Number(v.ScreeningPercentage) === 0 ? (
                                                                <td>{v.percentage}</td>
                                                            ) : (
                                                                <td>
                                                                    <div style={{ width: 75 }} className={`inputAndImgCont`}>
                                                                        <div style={{ width: 40 }} className="imgInput">
                                                                            <input
                                                                                onChange={(e) => handleScreeningPersValue(e, i)}
                                                                                className="customInput"
                                                                                type='number'
                                                                                placeholder='0'
                                                                                value={v.ScreeningPerValue || ""}
                                                                                max={100}
                                                                            />
                                                                        </div>
                                                                        <div className="imgDiv">
                                                                            <img
                                                                                src="../assits/inputParsentIcon.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                        </tr>
                                                        {v.completed ? <React.Fragment>
                                                            {v.Criteria ? <tr >
                                                                <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                <td colSpan={6}>{v.Criteria}</td>
                                                            </tr> : null}
                                                            <tr >
                                                                <td><img className='lineImg' src='../assits/Icon/tableLineImg.png' alt='' /></td>
                                                                <td colSpan={6}>
                                                                    <textarea
                                                                        onChange={(e) => goalCommentHandler(e, i)}
                                                                        className='tableInput auto-resize-textarea'
                                                                        type='text'
                                                                        placeholder='please add mandatory comment'
                                                                        value={v.comment}
                                                                        rows={1}
                                                                        onInput={adjustTextareaHeights}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        </React.Fragment> : null}
                                                    </React.Fragment>;
                                                }
                                            }) : null}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null;
                        } else {
                            return (goals && goals.find(item => item.categoryId === e.id)) ? (
                                <div key={i}>
                                    <div className='barHeadingCont pointer-cursor' style={{ backgroundColor: `${e.isSelected ? '#B491BA' : '#B491BA'}`, cursor: "pointer" }} onClick={() => null}>
                                        <div>
                                            <span style={{ color: `${e.isSelected ? '#FFFFFF' : '#FFFFFF'}` }}>Category: {e.name}</span>
                                        </div>
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <td>S.No</td>
                                                <td>
                                                    <img className='tableFlleIcon' src='../assits/tableFileIcon.png' alt='' />
                                                </td>
                                                <td className='goalTd'>Goals</td>
                                                <td className='goal-descriptionTd'>Goals Description</td>
                                                <td className='Criteria-TypeTd'>Criteria Type</td>
                                                <td>Completed</td>
                                                <td>Percentage</td>
                                                <td>Comment</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {goals.length ? goals?.map((v, i) => {
                                                let truncatedDescription = v.Description;
                                                if (truncatedDescription?.length > 300) {
                                                    truncatedDescription = truncatedDescription?.substring(0, 280) + '....';
                                                }
                                                if (v.categoryId === e.id) {
                                                    return (
                                                        <tr key={i} className={`goalsFormTbodyTr`}>
                                                            <td>{nmbr++}</td>
                                                            <td>
                                                                <img src='../assits/tableMarkIcon.png' alt='' />
                                                            </td>
                                                            <td className='goalsTitleCont'>
                                                                {v.Title}
                                                            </td>
                                                            <td>{truncatedDescription}</td>
                                                            <td>{v.Criteria_Type}</td>
                                                            <td>
                                                                <CustomSwitch
                                                                    checked={v.completed}
                                                                    onChange={() => {
                                                                        if (Number(v.ScreeningPercentage) === 0) {
                                                                            goalCompleteMark(i);
                                                                        }
                                                                    }}
                                                                />
                                                            </td>
                                                            {Number(v.ScreeningPercentage) === 0 ? (
                                                                <td>{v.percentage}</td>
                                                            ) : (
                                                                <td>
                                                                    <div style={{ width: 75 }} className={`inputAndImgCont`}>
                                                                        <div style={{ width: 40 }} className="imgInput">
                                                                            <input
                                                                                onChange={(e) => handleScreeningPersValue(e, i)}
                                                                                className="customInput"
                                                                                type='number'
                                                                                placeholder='0'
                                                                                value={v.ScreeningPerValue || ""}
                                                                                max={100}
                                                                            />
                                                                        </div>
                                                                        <div className="imgDiv">
                                                                            <img
                                                                                src="../assits/inputParsentIcon.png"
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            )}
                                                            <td>
                                                                <textarea
                                                                    onChange={(e) => goalCommentHandler(e, i)}
                                                                    className='tableInput'
                                                                    type='text'
                                                                    placeholder='Add Comment'
                                                                    value={v.comment}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            }) : null}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null;
                        }
                    })}
                </div>
            </div>
        </div>
    );
};

export default GoalsAndDescription;

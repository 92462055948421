import React from 'react';
import Plot from 'react-plotly.js';
import "./sectorPerfomansAndBarChat.css"


const StackedBarChart = ({ data }) => {
  const sectorNames = Object.keys(data);
  const publishedCounts = sectorNames.map((sector) => data[sector].publishedCount);
  const totalCounts = sectorNames.map((sector) => data[sector].totalCount);
  const plotData = [
    {
      x: sectorNames,
      y: publishedCounts,
      type: 'bar',
      name: 'Published Projects',
      marker: { color: 'rgba(180, 145, 186, 0.7)' },
    },
    {
      x: sectorNames,
      y: totalCounts,
      type: 'bar',
      name: 'Total Projects',
      marker: { color: 'rgba(252, 156, 54, 0.7)' },
    },
  ];

  const layout = {
    xaxis: {
      title: 'Sectors',
    },
    yaxis: {
      title: 'Number of Projects',
      rangemode: 'tozero',
    },
    barmode: 'group', // Display bars side by side
  };

  return (
    <div className='scetorAndBar-chart-container'>
      <Plot data={plotData} layout={layout} className='scetorAndBarchart-element' />
    </div>
  );
};
export default StackedBarChart;

import React from 'react'
import "./index.css"
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';

const WhoWeAre = () => {
  return (
    <>
      <Navbar />
      <div className='WhoWeAreMainCont'>
        <h1>
          This is my who we are page
        </h1>
      </div>
      <Footer />
    </>
  )
}

export default WhoWeAre;

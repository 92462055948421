import React from 'react'
import "./thanksmodal.css"
import { useNavigate } from "react-router";
const ThanksModal = ({
  investmentFlag = false,
  completedPercent = 0,
  totalPercent = 0,
  investmentId,
  selectedRecommendation = {},
  criticalGols = [],
  criticalSubGols = [],
}) => {
  const navigate = useNavigate();
  const checkRecommendationAndCritical = () => {
    const allCriticalGoals = [...criticalGols, ...criticalSubGols]
    if (allCriticalGoals && allCriticalGoals.length && allCriticalGoals.every(item => item.completed)) {
      return `This project is ${selectedRecommendation.Title} and all critical goals are achieved`
    }
    if (allCriticalGoals && allCriticalGoals.length && allCriticalGoals.some(item => !item.completed)) {
      return `This project is ${selectedRecommendation.Title} and all critical goals are not achieved`
    }
    return `This project is ${selectedRecommendation.Title}`
  }
  const criticalMsg = investmentId ? null : checkRecommendationAndCritical()
  // const history = useHistory();
  return (
    <div className='ThanksMOdalCOnt'>
      <div className='thanksModalCantainer'>
        <div className='thanksModalNavbarCont'>
          <div onClick={(() => navigate("/", { replace: true }))} style={{ cursor: "pointer" }}>
            <img className='navLogo' src='/assits/thanksModalLogo.png' alt='thanksModalNavberLogo' />
          </div>
          <div className='ThanksMOdalCloseButtonCont' onClick={() => investmentFlag ? navigate("/projects", { replace: true }) : window.location.reload()}>
            <img src='/assits/Icon/thanksMOdalCloseIcon.png' alt='thanksModalNavberLogo' />
            <span>Close</span>
          </div>
        </div>
        <div className='thanksBody'>
          <img className='logoImg' src={investmentFlag ? '/assits/thanksScreenLogoProjectInterest.png' : '/assits/thanksScreenLogoProjectSubmint.gif'} alt='thanksModalLogo' />
          <span className='thanksHeading'>{investmentFlag ? "Thank you for showing your Interest in the Project! " : "Thank you for submitting the Project! "}</span>
          {!investmentFlag && <div className='parsantageCont'>
            <span>Total Percentage: {(totalPercent || 0)} <img className='PercentageIcon' src='/assits/Icon/persantageIcon.png' alt='PercentageIcon' /></span>
            <span>Completed Percentage: {(completedPercent || 0)} <img className='PercentageIcon' src='/assits/Icon/persantageIcon.png' alt='PercentageIcon' /></span>
          </div>}
          {criticalMsg ? <span className='thankpara'>{criticalMsg}</span> : null}
          <span className='thankpara'>
            We appreciate your time and effort in providing this information.<br />
            We will be in touch with you shortly.<br />
            Have a great day!
          </span>
          {investmentId &&
            <span className='thankpara' style={{ color: "#ffc107" }}>
              Your Investment ID: {investmentId}
            </span>}
          <div className='buttonCont'>
            <button className='ThankSubmitButton' onClick={() => investmentFlag ? navigate("/projects", { replace: true }) : window.location.reload()}>{investmentFlag ? "Check More Project" : "Submit New Project"}</button>
            <button className='thankBackButton' onClick={() => navigate("/", { replace: true })}>Back to Home</button>
          </div>
        </div>
        <div>
          <img className='bottomImg' src='/assits/thanksModalBackgroundBar.png' alt='thanksModalNavberLogo' />
          <img className='bottomImgMobil' src='/assits/ThanksModalBottomImgMobileScreen.png' alt='thanksModalNavberLogo' />
        </div>
      </div>
    </div>
  )
}

export default ThanksModal;
import React, { useEffect, useState } from 'react';
import "./index.css";
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination/pagination';
import { GetAllPublishedProjects, GetAllCountries } from '../../../services';
import { Inputs } from '../../components/input';
import { HiCheck, HiOutlineChevronDown } from 'react-icons/hi';
import { Box } from "@mui/material";
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';


const SubmitProjectList = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [publishedProjects, loadingPublishedData] = GetAllPublishedProjects();
  const counties = GetAllCountries();
  const [projects, setProjects] = useState([]);
  const [filterdProjects, setFilterdProjects] = useState([]);
  const [country, setCountry] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [searchedProjects, setSearchedProjects] = useState([]);
  // const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedCityValue, setSelectCityedValue] = useState(null);
  const [isCityDropDownVisible, setIsCityDropDownVisible] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);

  const itemsPerPage = 7; // Number of rows per page
  const totalPages = Math.ceil(publishedProjects?.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  useEffect(() => {
    setLoading(true);
    if (projects.length === 0 && publishedProjects?.length) {
      let array = [];
      for (let key in publishedProjects) {
        const data = publishedProjects[key]?.attributes;
        array.push({
          key,
          projectName: data?.Project_name,
          sector: data?.sector.data?.attributes.Sector_Name,
          sectorIcons: data?.sector.data?.attributes.File_Url,
          country: data?.country.data?.attributes.Country_Name,
          city: data?.city.data?.attributes.City_Name,
          // irr: data?.IRR,
          // roi: data?.ROI,
          EBIT_Margin: data?.EBIT_Margin,
          gpMargin: data?.GP_Margin,
          netProfit: data?.Net_Profit_Margin,
          TimeLine_Year: data?.TimeLine_Year,
          TimeLine_Month: data?.TimeLine_Month,
          ticketSize: data?.Ticket_Size,
          contactPerson: data?.Contact_Person,
          id: publishedProjects[key].id,
          status: data?.Project_Status
        });
      }
      setProjects(array);
      setFilterdProjects(array);
      setLoading(false);

    }
    if (!country.length && counties?.length) {
      let array = [{ name: "All Countries", id: '0', shId: '0', selected: true }];
      for (let key in counties) {
        const data = counties[key].attributes;
        array.push({
          name: data.Country_Name,
          shId: data.SharePointId,
          id: counties[key].id,
          selected: false
        });
      }
      setCountry(array);
      setSelectCityedValue(array[0].name);
    }
    // if (selectFilter) {
    if (selectedOptionIndex) {
      if (selectedOptionIndex !== '0') {
        const pro = [...projects];
        const selectedItem = country.filter((e) => e.id === selectedOptionIndex);
        const filter = pro.filter((e) => e.country === selectedItem[0].name);
        setFilterdProjects(filter);
      } else {
        setFilterdProjects(projects);
      }
    }
    // setLoading(false);
  }, [publishedProjects, counties, selectedOptionIndex]);
  // Handle search input change
  // useEffect(() => {
  //   if (searchValue) {
  //     const filteredByName = projects.find((project) => {
  //       return project.projectName.toLowerCase().includes(searchValue.toLowerCase()) || project.id === searchValue
  //     });
  //     console.log(filteredByName)
  //     // if (currentPage > 1) {
  //     //   setCurrentPage(1)
  //     // }
  //     // setFilterdProjects(filteredByName);
  //   } else {
  //     // setFilterdProjects(projects);
  //   }
  // }, [searchValue, projects]);
  useEffect(() => {
    if (searchValue) {
      const filtered = projects.filter((project) =>
        project.projectName.toLowerCase().includes(searchValue.toLowerCase()) ||
        project.id.includes(searchValue)
      );
      if (currentPage > 1) {
        setCurrentPage(1);
      }
      setFilterdProjects(filtered);
    } else {
      setFilterdProjects(projects);
    }
  }, [searchValue, projects]);
  const currentData = filterdProjects?.slice(startIndex, endIndex);
  const toggleCityDropDown = () => {
    setIsCityDropDownVisible(!isCityDropDownVisible);
  };
  const handleCityOptionClick = (index) => {
    const data = [...country];
    for (let key in data) {
      if (data[key].id === index) {
        data[key].selected = true;
        setSelectCityedValue(data[key].name);
        setSelectedOptionIndex(index);
        setIsCityDropDownVisible(false);
      } else {
        data[key].selected = false;
      }
    }
    setCountry(data);
  };

  //list pa click kar ka scroll karwana ki logic
  useEffect(() => {
    // Add event listeners when the component is mounted
    const scrollableContainer = document.getElementById('scrollableContainer');
    let isDragging = false;
    let startX, scrollLeft;

    scrollableContainer.addEventListener('mousedown', (e) => {
      isDragging = true;
      startX = e.pageX - scrollableContainer.offsetLeft;
      scrollLeft = scrollableContainer.scrollLeft;
    });

    scrollableContainer.addEventListener('mouseup', () => {
      isDragging = false;
    });

    scrollableContainer.addEventListener('mouseleave', () => {
      isDragging = false;
    });

    scrollableContainer.addEventListener('mousemove', (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - scrollableContainer.offsetLeft;
      const walk = (x - startX) * 3; // You can adjust the scrolling speed here
      scrollableContainer.scrollLeft = scrollLeft - walk;
    });
  }, []);

  return (
    <div className='submintprojectListCenter'>
      <Navbar />
      <div className='submitProjectListMainCont'>
        <div className='headCont'>
          <div className='logoCont'>
            <img className='projectListHeadingImg' src='/assits/projectDetailMainLogo.png' alt='Project-Logo' />
            <h2>
              Published Projects for Investment
            </h2>
          </div>
          <div className='searchAndCountriesFilterCont'>
            <div className='searchFilterCont'>
              <input
                type='text'
                placeholder='Search for Projects'
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <img src='/assits/Icon/filterProjectIcon.png' alt='searchIcon' />
            </div>
            <div className='FilterCont'>
              <div className="submitProjectCustomSelectInputCont pointer-cursor" onClick={toggleCityDropDown}>
                <div className="selectInput">
                  {selectedCityValue}
                </div>
                <div className="selectInputDownIconCont">
                  <HiOutlineChevronDown className="selectInputDownIcon" />
                </div>
              </div>
              {isCityDropDownVisible && (
                <div className="submitProjectCustomselectInputOptionCont">
                  {country.map((option, index) => (
                    <span
                      key={index}
                      onClick={() => handleCityOptionClick(option.id)}
                      className='pointer-cursor'
                    >
                      <span className='optionIconCont pointer-cursor'>{option.selected && <HiCheck className='optionCheckIcon' />}</span>
                      {option.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='submitListTableCont'>
          <div className='scrolCont' id="scrollableContainer">
            {loadingPublishedData ? ( // Display loading indicator when data is still loading
              <div className="submitListTableContLoder">
                <img className='loaderImg' src='/assits/loaderGif.gif' alt='LoaderImg' />
              </div>
            ) : (
              <table className='submitListtable'>
                <thead>
                  <tr>
                    <td>
                      <img className='submitListTableTheadIcon' src='/assits/Icon/projectListTableHeadIcon.png' alt='File-Icon' />
                    </td>
                    <td>S#</td>
                    <td>Project Id</td>
                    <td>Project Name</td>
                    <td>Sector</td>
                    <td>Country</td>
                    <td>City</td>
                    <td>EBIT Margin</td>
                    <td>GP Margin</td>
                    <td>Net Profit</td>
                    <td>Timeline</td>
                    {/* <td>Ticket Size</td> */}
                    <td>Contact Person</td>
                    <td>Status  </td>
                  </tr>
                </thead>
                <tbody className='scrollableTbody'>
                  {currentData
                    .filter((item) =>
                      searchValue
                        ? (
                          item.projectName.toLowerCase().includes(searchValue.toLowerCase()) ||
                          item.id.toString().includes(searchValue)
                        )
                        : true // Include all items if searchValue is empty
                    )
                    .map((item, index) => (
                      <tr key={index} className='pointer-cursor'
                        onClick={() => navigate(`/ProjectDetails/${item.id}`)}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f5f5f5')} // Change background color on hover
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'initial')}
                      >
                        <td className='chackboxCont'>
                          <img src='/assits/Icon/projectListTableBodyIcon.png' alt='File-Icon' />
                        </td>
                        <td>{Number(item.key) + 1}</td>
                        <td>{item.id}</td>
                        <td>{item.projectName}</td>
                        <td>
                          <div className='sectorListCont'>
                            {/* <img src='/assits/transportationIcon.png' alt='File-Icon' />
                      {item.sector} */}
                            {item.sector && (
                              <img
                                src={`https://admin.sdg.hexalyze.com${item?.sectorIcons}`}
                                className='sector-icon'
                                alt='Sector-Icon'
                              />
                            )}
                            {item.sector}
                          </div>
                        </td>
                        <td>{item.country}</td>
                        <td>{item.city}</td>
                        <td>{item.EBIT_Margin} %</td>
                        <td>{item.gpMargin} %</td>
                        <td>{item.netProfit} %</td>
                        <td>{item.TimeLine_Year}Y / {item.TimeLine_Month}M</td>
                        {/* <td>{item.ticketSize}</td> */}
                        <td>{item.contactPerson}</td>
                        <td>
                          <span>{item.status}</span>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            )}
            {!loadingPublishedData && !publishedProjects?.length && (
              <div style={{ display: 'flex', height: '80%', justifyContent: 'center', alignItems: 'center' }}>
                <img src='/assits/no_published_projects.png' alt="no projects" />
                {/* <span style={{ fontSize: '1.2em', color: 'lightgrey' }}>No Published Projects</span> */}
              </div>
            )}
          </div>
        </div>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      <Footer />
    </div>
  );
};

export default SubmitProjectList;

import { createNewProjectCompletion, createNewProjectMutation, createNewInvestment, newProjectCompletionSubGoal, newProjCompSubGoalDesc, uploadFiles, newAttachments, newInvestmentAttachments } from "./mutation";
import { listOfCompletedProject, filterCategoryBySector, filterGoalsByCategoryId, getCitiesFitler, getCountries, getSectors, getCategories, getFilterByGoals, getGoalsById, getAllPublishedProjects, getCurrencies, getSubGoalDescription, getSubGoals, filterProjectCompletionSubGoals, filterProjCompSubGoalDescs, filterFileAttachments, filterProjectByName, filterRecommendationByValue, getBenchMarking, getSectorById, getGoals,  getAllProjectToReview, getSectorScores, getInvestments, getBenchMarkings } from "./queries";

const API = {
  ApiSectors: getSectors,
  ApiCountries: getCountries,
  ApiGoals: getGoals,
  ApiCitiesFitler: getCitiesFitler,
  ApiCategories: getCategories,
  ApiFilterByGoals: getFilterByGoals,
  ApiGoalsById: getGoalsById,
  APiPublishedProjects: getAllPublishedProjects,
  ApiFilterCategoryBySector: filterCategoryBySector,
  ApiFilterGoalsByCategoryId: filterGoalsByCategoryId,
  ApiSubGoalDescription: getSubGoalDescription,
  ApiListOfCompletedProject: listOfCompletedProject,
  ApiGetCurrencies: getCurrencies,
  ApiGetSubGoals: getSubGoals,
  ApiBenchMarkings: getBenchMarkings,
  APiFilterProjectCompletionSubGoals: filterProjectCompletionSubGoals,
  APiFilterProjCompSubGoalDescs: filterProjCompSubGoalDescs,
  ApiFilterFileAttachments: filterFileAttachments,
  ApiProjectNameFilter: filterProjectByName,
  ApiRecommendationByValue: filterRecommendationByValue,
  ApiBenchMarking: getBenchMarking,
  ApiSectorById: getSectorById,
  ApiAllProjectToReview: getAllProjectToReview,
  ApiSectorScores: getSectorScores,
  ApiInvestments: getInvestments,
  // mutation----------------------------
  ApiCreateNewProjectMutation: createNewProjectMutation,
  ApiCreateNewProjectCompletion: createNewProjectCompletion,
  ApiCreateNewInvestment: createNewInvestment,
  ApiNewProjectCompletionSubGoal: newProjectCompletionSubGoal,
  ApiNewProjCompSubGoalDesc: newProjCompSubGoalDesc,
  ApiUploadFiles: uploadFiles,
  ApiNewAttachments: newAttachments,
  ApiNewInvestmentAttachments: newInvestmentAttachments
};

export default API;
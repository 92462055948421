import React, { useState } from 'react';
import "./pagination.css";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
    const [inputPage, setInputPage] = useState(currentPage);

    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setInputPage(e.target.value);
    };

    const handlePageClick = () => {
        const newPage = parseInt(inputPage, 10);
        if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handlePreviousPageClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
        
    };

    const handleNextPageClick = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    return (
        <div className='pagination'>
            <div>
                <button
                    className={`defultPagination pointer-cursor ${currentPage === 1 ? 'paginationButtonUnActives' : 'paginationButton'}`}
                    onClick={handlePreviousPageClick} // Use the handlePreviousPageClick function
                >
                    <HiOutlineChevronLeft
                        className={`${currentPage === 1 ? 'paginationIconUnActive' : 'paginationIconActive'}`}
                    />
                </button>
                <div className='paginationIndexCont'>
                    <input
                        className='paginationInput'
                        type='number'
                        id='input'
                        disabled={true}
                        placeholder={currentPage}
                        // onChange={handleInputChange}
                        // onBlur={handlePageClick}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handlePageClick();
                            }
                        }}
                    />
                    <span>{' of '}</span>
                    <span className='totalPage'>{String(totalPages ? totalPages : 0)}</span>
                </div>
                <button
                    className={`defultPagination pointer-cursor ${currentPage === totalPages ? 'paginationButtonUnActives' : 'paginationButton'}`}
                    onClick={handleNextPageClick} // Use the handleNextPageClick function
                >
                    <HiOutlineChevronRight
                        className={`${currentPage === totalPages ? 'paginationIconUnActive' : 'paginationIconActive'}`}
                    />
                </button>
            </div>
        </div>
    );
};

export default Pagination;

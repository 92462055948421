import React, { useEffect } from 'react'
import "./home.css"
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/navbar'
import Footer from '../../components/footer'

const Home = () => {
  const navigate = useNavigate()


  //card click scroll karwana ki logic
  useEffect(() => {
    // Add event listeners when the component is mounted
    const cardScrll = document.querySelector('.scroolCont');
    let isDragging = false;
    let startX, scrollLeft;

    const handleMouseDown = (e) => {
      isDragging = true;
      startX = e.pageX - cardScrll.offsetLeft;
      scrollLeft = cardScrll.scrollLeft;
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    const handleMouseLeave = () => {
      isDragging = false;
    };

    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX - cardScrll.offsetLeft;
      const walk = (x - startX) * 3; // You can adjust the scrolling speed here
      cardScrll.scrollLeft = scrollLeft - walk;
    };

    cardScrll.addEventListener('mousedown', handleMouseDown);
    cardScrll.addEventListener('mouseup', handleMouseUp);
    cardScrll.addEventListener('mouseleave', handleMouseLeave);
    cardScrll.addEventListener('mousemove', handleMouseMove);

    // Clean up event listeners when the component is unmounted
    return () => {
      if (cardScrll) {
        cardScrll.removeEventListener('mousedown', handleMouseDown);
        cardScrll.removeEventListener('mouseup', handleMouseUp);
        cardScrll.removeEventListener('mouseleave', handleMouseLeave);
        cardScrll.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);


  return (
    <>
      <Navbar />
      <div className='mainCont'>
        <div className='bannerMainCont'>
          <div className='bannerCont'>
            <div className='BannerHeadingCont'>
              <h1 className='bannerHeading'>
                Sustainable Development Goals <span>Investments
                  Dashboard</span> <img src='/assits/homeHeadingImg.png' alt='' />
              </h1>
            </div>
            <div className='bannerImgCont'>
              <img className='bannerImg' src='/assits/bannerImg.png' alt='BannerImg' />
              <img className='bannerImg-res' src='/assits/bannerImg_res.png' alt='BannerImg' />
            </div>
          </div>
        </div>
        <div className='scroolCont'>
          <div className='cardCont'>
            <div className='card pointer-cursor' onClick={()=> navigate('/dashboard')}>
              <div className='imgCont'>
                <img className='cardImg' src='/assits/dashboardCardImg.png' alt='dashBooardCardImg' />
              </div>
              <div className='textAndIconCont'>
                <div className='textCont'>
                  <h3>
                    Dashboard
                  </h3>
                  <span>
                    This is the dummy text for the display, this is the dummy text for the display.
                  </span>
                </div>
                <div className='IconCont'>
                  <img src='/assits/cardIcon1.png' alt='Card-Icon' />
                </div>
              </div>
            </div>
            <div className='card2Link' onClick={() => navigate("/create-new-project")}>
              <div className='card2 pointer-cursor'>
                <div className='imgCont'>
                  <img className='cardImg' src='/assits/submitCardImg.png' alt='dashBooardCardImg' />
                </div>
                <div className='textAndIconCont'>
                  <div className='textCont'>
                    <h3>
                      Submit the Project
                    </h3>
                    <span>
                      This is the dummy text for the display, this is the dummy text for the display.
                    </span>
                  </div>
                  <div className='IconCont'>
                    <img src='/assits/cardIcon2.png' alt='Card-Icon' />
                  </div>
                </div>
              </div>
            </div>
            <div className='card3 pointer-cursor' onClick={() => navigate('/projects')}>
              <div className='imgCont'>
                <img className='cardImg' src='/assits/investCardImg.png' alt='dashBooardCardImg' />
              </div>
              <div className='textAndIconCont'>
                <div className='textCont'>
                  <h3>
                    Invest in the Project
                  </h3>
                  <span>
                    This is the dummy text for the display, this is the dummy text for the display.
                  </span>
                </div>
                <div className='IconCont'>
                  <img src='/assits/cardIcon3.png' alt='Card-Icon' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home;
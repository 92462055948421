import React from 'react';
import './card.css'
const PercentageCard = ({ title, value, loading }) => {
  return (
    <div className='dasboardCard'>
      <img src='/assits/dashboardImg/dashBoardCardIcon.png' alt='card-Icon' />
      <h3>{title}</h3>
      {!loading ? (
        <span>{value.toFixed(2)}%</span>
      ) : (
        <span className='loadingCardSpan'>Loading...</span>
      )}
    </div>
  );
};

export default PercentageCard;

import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import pakistanMapData from './pk-all.topo.json';

const MapChartComponent = ({ data }) => {
  useEffect(() => {
    HighchartsMap(Highcharts);

    const groupedData = data.reduce((accumulator, currentItem) => {
      const cityName = currentItem.City;

      if (!accumulator[cityName]) {
        accumulator[cityName] = {
          name: currentItem.City,
          lat: parseFloat(currentItem.CityLatitude),
          lon: parseFloat(currentItem.CityLongitude),
          projectsCount: 1,
        };
      } else {
        accumulator[cityName].projectsCount += 1;
      }

      return accumulator;
    }, {});

    const mapData = Object.values(groupedData);

    Highcharts.mapChart('container', {
      chart: {
        backgroundColor: '#ebfaeb',
        map: pakistanMapData,
      },
      title: {
        text: '',
      },
      mapNavigation: {
        enabled: true,
        buttonOptions: {
          verticalAlign: 'bottom',
        },
      },
      series: [
        {
          type: 'map',
          name: 'Pakistan',
          nullColor: 'rgba(77, 208, 73, 0.7)',
          color: '#D3D3D3',
          showInLegend: true,
        },
        {
          type: 'mapline',
          nullColor: '#707070',
          showInLegend: false,
          enableMouseTracking: false,
        },
        {
          type: 'mappoint',
          name: 'Project Locations',
          data: mapData,
          tooltip: {
            pointFormatter: function () {
              // Customize the tooltip content
              return '<strong>' + this.name + '</strong><br>Number of Projects: ' + this.projectsCount;
            },
          },
          marker: {
            symbol: 'circle',
            radius: 6,
            fillColor: 'red',
            lineColor: 'white',
            lineWidth: 2,
          },
          states: {
            hover: {
              color: '#BADA55',
            },
          },
          dataLabels: {
            enabled: true,
            format: '{point.name}',
          },
        },
      ],
    });
  }, [data]);

  return (
    <div className="map-container">
      <div id="container" style={{ height: '100%', width: '100%' }}></div>
    </div>
  );
};

export default MapChartComponent;

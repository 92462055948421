import React from 'react';
import "./navbar.css";
import WebNevBar from './webNavbar';
import MobileNevBar from './mobileNavbar';

const Navbar = () => {
    return (
        <>
            <div className='webNevbarCont'>
                <WebNevBar />
            </div>
            <div className='MobileNevbarCont'>
                <MobileNevBar />
            </div>
        </>
    )
};

export default Navbar;
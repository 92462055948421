// import React from 'react';
// import './card.css'
// const Card = ({ title, value }) => {

//   return (
//       <div className='dasboardCard'>
//         <img src='/assits/dashboardImg/dashBoardCardIcon.png' alt='card-Icon' />
//         <h3>{title}</h3>
//         <span>{Math.round(value)}</span>
//       </div>
//   );
// };

// export default Card;




import React from 'react';
import './card.css';

const Card = ({ title, value, loading }) => {
  return (
    <div className='dasboardCard'>
      <img src='/assits/dashboardImg/dashBoardCardIcon.png' alt='card-Icon' />
      <h3>{title}</h3>
      {!loading ? (
        <span>{Math.round(value)}</span>
      ) : (
        <span className='loadingCardSpan'>Loading...</span>
      )}
    </div>
  );
};

export default Card;


import React from 'react';
import './style.css';

const ProgressBar = ({ activeStep }) => {
    return (
        <div className='SliderCont'>
            <div className={`tab1 ${activeStep === 1 ? 'active' : ''}`}>
                <div className='labelCont'>
                    {/* <div> */}
                    <img src='../assits/projectIcon.png' alt='' />
                    {/* </div> */}
                    <span className="webHeading">Project Info</span>
                    <span className="mobileHeading">Info</span>
                </div>
                <div className={`progressBar ${activeStep === 1 ? 'Active' : 'UnActive'}`}></div>
            </div>
            <div className={`tab2 ${activeStep === 2 ? 'active' : ''}`}>
                <div className='labelCont'>
                    <img src='../assits/goalsIcon.png' alt='' />
                    <span className="webHeading">Goals & Descriptions</span>
                    <span className="mobileHeading">Goals</span>
                </div>
                <div className={`progressBar ${activeStep === 2 ? 'Active' : 'UnActive'}`}></div>
            </div>
            <div className={`tab3 ${activeStep === 3 ? 'active' : ''}`}>
                <div className='labelCont'>
                    <img src='../assits/totalIcon.png' alt='' />
                    <span className="webHeading">Completed Percentage</span>
                    <span className="mobileHeading">Percentage</span>
                </div>
                <div className={`progressBar ${activeStep === 3 ? 'Active' : 'UnActive'}`}></div>
            </div>
        </div>
    );
};

export default ProgressBar;


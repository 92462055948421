import React, { useState } from "react";
import FileSelector from "../../customFileSelector";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

const ProjectForm = ({
  projectInput,
  sectorSelect,
  countrySelect,
  citySelect,
  irrInput,
  roiInput,
  gpMarginInput,
  EBITMarginInput,
  netProfitMarginInput,
  timeLineYearInput,
  timeLineMonthInput,
  contectPersonInput,
  mobileNoInput,
  emailInput,
  handleYearUpClick,
  handleYearDownClick,
  handleMonthUpClick,
  handleMonthDownClick,
  currencySelectInput,
  inputDiv,
  handleFileSelect
}) => {
  return (
    <div className="step1Cont">
      <div className="step1InnerCont">
        <div className="inputCont">
          <div className="inputDiv">
            <span>Project Name</span>
            {projectInput}
          </div>
          <div className="inputDiv">
            <span>Sector</span>
            {sectorSelect}
          </div>
          <div className="inputDiv">
            <span>Country</span>
            {countrySelect}
          </div>
          <div className="inputDiv">
            <span>City</span>
            {citySelect}
          </div>
          <div className="inputDiv">
            <span>GP Margin</span>
            <div className={`inputAndImgCont ${inputDiv.gp_margin ? 'customInputError' : 'inputAndImgCont'}`}>
              <div className="imgInput">
                {gpMarginInput}
              </div>
              <div className="imgDiv">
                <img
                  src="../assits/inputParsentIcon.png"
                  className="input-img"
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div className="inputDiv">
            <span>IRR</span>
            <div className={`inputAndImgCont ${inputDiv.irr ? 'customInputError' : 'inputAndImgCont'}`}>
              <div className="imgInput">
                {irrInput}
              </div>
              <div className="imgDiv">
                <img
                  src="../assits/inputParsentIcon.png"
                  alt=""
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className="inputCont">
          {/* <div className="inputDiv">
            <span>ROI</span>
            <div className={`inputAndImgCont ${inputDiv.roi ? 'customInputError' : 'inputAndImgCont'}`}>
              <div className="imgInput">
                {roiInput}
              </div>
              <div className="imgDiv">
                <img
                  src="../assits/inputParsentIcon.png"
                  alt=""
                />
              </div>
            </div>
          </div> */}
          <div className="inputDiv">
            <span>EBIT Margin</span>
            <div className={`inputAndImgCont ${inputDiv.eBIT_margin ? 'customInputError' : 'inputAndImgCont'}`}>
              <div className="imgInput">
                {EBITMarginInput}
              </div>
              <div className="imgDiv">
                <img
                  className="input-img"
                  src="../assits/inputParsentIcon.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="inputDiv">
            <span>Net Profit Margin</span>
            <div className={`inputAndImgCont ${inputDiv.net_profit_margin ? 'customInputError' : 'inputAndImgCont'}`}>
              <div className="imgInput">
                {netProfitMarginInput}
              </div>
              <div className="imgDiv">
                <img
                  src="../assits/inputParsentIcon.png"
                  className="input-img"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className='inputDiv'>
            <span>Timeline</span>
            <div className='timeLineInputWidth'>
              <div className={`timeLineInputCont ${inputDiv.timeLineYear ? 'timeLineInputContErrorBorder' : 'timeLineInputCont'}`}>
                <div>
                  {timeLineYearInput}
                </div>
                <div className='timeLineInputControl'>
                  <div onClick={handleYearUpClick}>
                    <img className="pointer-cursor" name="yearup" src='../assits/Icon/timeLineUPIcon.png' alt='' />
                  </div>
                  <div onClick={handleYearDownClick}>
                    <img className="pointer-cursor" name="yeardwon" src='../assits/Icon/timeLineDownIcon.png' alt='' />
                  </div>
                </div>
                <div className='timeLineInputLabel'>
                  <span>Year</span>
                </div>
              </div>
              <div className={`timeLineInputCont ${inputDiv.timeLineMonth ? 'timeLineInputContErrorBorder' : 'timeLineInputCont'}`}>
                <div>
                  {timeLineMonthInput}
                </div>
                <div className='timeLineInputControl'>
                  <div onClick={handleMonthUpClick}>
                    <img className="pointer-cursor" src='../assits/Icon/timeLineUPIcon.png' alt='' />
                  </div>
                  <div onClick={handleMonthDownClick}>
                    <img className="pointer-cursor" src='../assits/Icon/timeLineDownIcon.png' alt='' />
                  </div>
                </div>
                <div className='timeLineInputLabel'>
                  <span>Month</span>
                </div>
              </div>
            </div>
          </div>
          <div className="inputDiv">
            <span>Project Cost</span>
            {currencySelectInput}
          </div>
          <div className="inputDiv">
            <span>Contact Person</span>
            {contectPersonInput}
          </div>
        </div>
      </div>
      <div className="InnerCont">
        <div className="inputCont">
          
          <div className="inputDiv">
            <span>Mobile No</span>
            {mobileNoInput}
          </div>
          <div className="inputDiv">
            <span>Email</span>
            {emailInput}
          </div>
          <div className="inputDiv">
            <span className="atachmentHeadingCont">Add Attachments <h5> ( Word | Pdf | Excel )</h5></span>
            <div>
              <FileSelector onFileSelect={handleFileSelect} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectForm;

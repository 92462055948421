import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const clientUpload = new ApolloClient({
    cache: new InMemoryCache(),
    link: createUploadLink({
        uri: process.env.REACT_APP_PRO_URL
    })
});

export default clientUpload;

import React from 'react';
import "./style.css";
import { Link } from 'react-router-dom';

const WebNevBar = () => {
    return (
        <div className='navCont'>
            <div className='LogoCont'>
                <Link className='link pointer-cursor' to="/"><img className='image pointer-cursor' src="/assits/mainLogo.png" alt='Navbar Logo' /></Link>
            </div>
            <div className='optionCont'>
                <nav>
                    <ul className='navOption'>
                        <li>
                            <Link className='link pointer-cursor' to="/"><span>HOME</span></Link>
                        </li>
                        <li>
                            <Link className='link pointer-cursor' to="/whoweare"><span>WHO WE ARE</span></Link>
                        </li>
                        <li>
                            <Link className='link pointer-cursor' to="/"><span>WHAT WE DO</span></Link>
                        </li>
                        <li>
                            <Link className='link pointer-cursor' to="/"><span>OUR IMPACT</span></Link>
                        </li>
                        <li>
                            <Link className='link pointer-cursor' to="/"><span>CONTACT US</span></Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className='navSearchCont'>
                <img className='SearchIcon pointer-cursor' src="/assits/Icon/SearchIcon.png" alt='Navbar Logo' />
            </div>
        </div>
    )
};

export default WebNevBar;
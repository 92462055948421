import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import API from "../graphql"

export const FilterByCategoryId = () => {
    const [getCategoryId, { data, refetch, error }] = useLazyQuery(API.ApiFilterByGoals)
    const categoryAndGoalsId = data?.categoryGoals?.data
    if (error) {
        return error
    }
    return [categoryAndGoalsId, refetch, getCategoryId]
}

export const FilterByGoalsId = () => {
    const [getGoalsById, { data, refetch, error }] = useLazyQuery(API.ApiGoalsById, {
        fetchPolicy: 'network-only'
    })
    const dataGoalsId = data?.goals?.data
    if (error) {
        return error
    }
    return [dataGoalsId, refetch, getGoalsById]
}

export const GetSubGoal = () => {
    const [getSubGoals, { data, refetch, error }] = useLazyQuery(API.ApiGetSubGoals, {
        fetchPolicy: "network-only",
    });
    const project = data?.subGoals?.data;
    if (error) {
        return error
    }
    return [project, refetch]
}

export const GetSubGoalDescription = () => {
    const [getSubGoals, { data, refetch, error }] = useLazyQuery(API.ApiSubGoalDescription, {
        fetchPolicy: "network-only",
    });
    const project = data?.subGoalDescriptions?.data;
    if (error) {
        return error
    }
    return [project, refetch]
}


export const GetAllPublishedProjects = () => {
    const { loading, error, data } = useQuery(API.APiPublishedProjects, {
        variables: {
            "filter": {
                "Is_Active": {
                    "eq": true
                },
                "Project_Status": {
                    "eqi": "Published"
                }
            }
        }
    });
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error
    }
    return [data?.projects?.data, loading]
}

export const GetAllCountries = () => {
    const { loading, error, data } = useQuery(API.ApiCountries);
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error
    }
    return data?.countries?.data
}

export const FilterCategoryBySector = () => {
    const [getCategoryIdList, { data, refetch, error }] = useLazyQuery(API.ApiFilterCategoryBySector, {
        fetchPolicy: "network-only"
    });
    const categoryIdList = data?.sectorScores?.data;
    if (error) {
        return error
    }
    return [categoryIdList, refetch, getCategoryIdList]
}

export const FilterGoalsByCategoryId = () => {
    const [getGoalsIdList, { data, refetch, error }] = useLazyQuery(API.ApiFilterGoalsByCategoryId, {
        fetchPolicy: "network-only"
    });

    const goalsIdList = data?.benchMarkings?.data || []; // Use an empty array if goalsIdList is undefined

    if (error) {
        return error;
    }

    return [goalsIdList, refetch, getGoalsIdList];
}

export const ApiListOfCompletedProject = () => {
    const [getCompProject, { data, refetch, error }] = useLazyQuery(API.ApiListOfCompletedProject, {
        fetchPolicy: "network-only"
    });

    const list = data?.projectCompletions?.data;
    if (error) {
        return error
    }
    return [list, getCompProject]
}

export const FilterProCompletionSubGoal = () => {
    const [compSubGaols, { data, refetch, error }] = useLazyQuery(API.APiFilterProjectCompletionSubGoals, {
        fetchPolicy: "network-only"
    });
    const list = data?.projectCompletionSubGoals?.data;
    if (error) {
        return error
    }
    return [list, refetch]
}

export const FilterProCompletionSubGoalDesc = () => {
    const [compSubGaolsDesc, { data, refetch, error }] = useLazyQuery(API.APiFilterProjCompSubGoalDescs, {
        fetchPolicy: "network-only"
    });
    const list = data?.projCompSubGoalDescs?.data;
    if (error) {
        return error
    }
    return [list, refetch]
}

export const FilterFilesAttachments = () => {
    const [attachments, { data, refetch, error }] = useLazyQuery(API.ApiFilterFileAttachments, {
        fetchPolicy: "network-only"
    });
    const list = data?.attachments?.data;
    if (error) {
        return error
    }
    return [list, refetch, attachments]
};

export const VerifyProjectNameQuery = (name) => {
    const { data, loading, error } = useQuery(API.ApiProjectNameFilter, {
        fetchPolicy: "network-only",
        variables: {
            "filter": {
                "Is_Active": {
                    "eq": true
                },
                "Project_name": {
                    "eqi": name
                }
            }
        }
    });
    // // Project name already existed
    const projectNameV = data?.projects?.data;
    return projectNameV
}

// ======================================================================================================
// // mutations

export const CreateNewProjectQuery = () => {
    const [createNewProject, { data, loading, error }] = useMutation(API.ApiCreateNewProjectMutation);
    const newProject = data?.createProject?.data;
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error;
    }
    return [newProject, createNewProject]
}

export const CreateNewProjectCompletion = () => {
    const [newProjectCompletion, { data, loading, error }] = useMutation(API.ApiCreateNewProjectCompletion);
    const newProject = data?.createProjectCompletion?.data;
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error;
    }
    return [newProject, newProjectCompletion]
}

export const CreateNewInvestment = () => {
    const [newInvestment, { data, loading, error }] = useMutation(API.ApiCreateNewInvestment);
    const investment = data?.createInvestment?.data;
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error;
    }
    return [investment, newInvestment]
}

export const CreateNewProjectCompletionSubGoal = () => {
    const [NPCSG, { data, loading, error }] = useMutation(API.ApiNewProjectCompletionSubGoal);
    const arrayData = data?.createProjectCompletionSubGoal?.data;
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error;
    }
    return [arrayData, NPCSG]
}

export const CreateNewProjCompSubGoalDesc = () => {
    const [NPCSGD, { data, loading, error }] = useMutation(API.ApiNewProjCompSubGoalDesc);
    const arrayData = data?.createProjCompSubGoalDesc?.data;
    if (loading) {
        // console.log("loading data")
    }
    if (error) {
        return error;
    }
    return [arrayData, NPCSGD]
}

export const CreateNewAttachemnt = () => {
    const [uploads, { data, loading, error }] = useMutation(API.ApiNewAttachments);
    const fileData = data?.createAttachment?.data;
    if (error) {
        return error;
    }
    return [fileData, uploads]
}

export const CreateNewInvestmentAttachemnt = () => {
    const [uploads, { data, loading, error }] = useMutation(API.ApiNewInvestmentAttachments);
    const fileData = data?.createInvetmentAttachment?.data;
    if (error) {
        return error;
    }
    return [fileData, uploads]
}

export const SectorFilterByBenchmarking = () => {
    const [getSectorById, { data, refetch, error }] = useLazyQuery(API.ApiSectorById, {
        fetchPolicy: 'network-only'
    })
    const SectorData = data?.sectors?.data
    if (error) {
        return error
    }
    return [SectorData, refetch, getSectorById]
}
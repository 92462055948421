import React, { useEffect } from 'react';
var Plotly = require('plotly.js-dist-min');

const PercentagebySector = (props) => {
    const { sectorScores, benchMarkings } = props
    const elementId = 'PercentagebySector';
    const data = setData(sectorScores, benchMarkings)
    useEffect(() => {
        if (data) {
            const uniqueCategories = [];
            const uniqueSectors = [];
            for (let i = 0; i < data.length; i++) {
                const category = data[i].category;
                const sector = data[i].sector;

                if (category !== null && category !== undefined && uniqueCategories.indexOf(category) === -1) {
                    uniqueCategories.push(category);
                }
                if (sector !== null && sector !== undefined && uniqueSectors.indexOf(sector) === -1) {
                    uniqueSectors.push(sector);
                }
            }
            const traces = uniqueCategories.map((category) => {
                const categoryData = data.filter((item) => item.category === category);
                const percentages = uniqueSectors.map((sector) => {
                    const sectorData = categoryData.find((item) => item.sector === sector);
                    return sectorData ? sectorData.percentage : 0;
                });
                return {
                    x: uniqueSectors,
                    y: percentages,
                    type: 'bar',
                    width: 0.4,
                    orientation: 'v',
                    hoverinfo: 'text+percent',
                    hovertemplate: `<b>%{label}</b><br>${category}  %{text}`, // Customize the tooltip content
                    text: percentages.map((percentage, index) => {
                        return `${percentage.toFixed(2)}%`;
                    }),
                    name: category,
                };
            });

            const layout = {
                barmode: 'stack',
                height: 500,
                margin: { t: 40, l: 20, r: 20, b: 40 }, // Add some margin for x-axis labels
                showlegend: true,
                xaxis: {
                    tickmode: 'array',
                    tickvals: uniqueSectors, // Set tick values to display all sectors
                    ticktext: uniqueSectors, // Set tick text to display sector names
                },
                autosize: true,      // Set autosize to responsive
                width: undefined,
            };

            const config = {
                displayModeBar: false,
            };

            Plotly.react(elementId, traces, layout, config);
        }
    }, [data, elementId]);
    return (
        <div>
            <div id={elementId} style={{ margin: '20px' }}></div>
        </div>
    );
};

export default PercentagebySector;

const setData = (sectorScores, benchMarkings) => {
    const benchMarkingsList = benchMarkings?.data?.benchMarkings?.data;
    const sectorScoresList = sectorScores?.data?.sectorScores?.data;
    const sortedSectorScoresList = [...sectorScoresList].sort((a, b) => {
        const getSequence = item => item?.attributes?.Category_Id?.data?.attributes?.CategorySequence;
        return (getSequence(a) ?? Infinity) - (getSequence(b) ?? Infinity);
    });
    const data = []
    for (let i = 0; i < sortedSectorScoresList.length; i++) {
        const scores = sortedSectorScoresList[i];
        const bench = benchMarkingsList.filter(item => item?.attributes?.Sector_Id?.data?.id === scores?.attributes?.Sector_Id?.data?.id)
        if (bench?.length) {
            data.push({
                category: scores?.attributes?.Category_Id?.data?.attributes?.Category_Name,
                sector: scores?.attributes?.Sector_Id?.data?.attributes?.Sector_Name,
                percentage: Number(scores?.attributes?.Percentage),
            })
        }
    }
    return data
}
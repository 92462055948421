import React, { useEffect, useState } from "react";
import "./tabs-style.css";
import { Link } from "react-router-dom";
import Tabs from "../../components/form-tabs";
import ProgressBar from "../../components/ProgressBar";
import { CreateNewAttachemnt, CreateNewProjCompSubGoalDesc, CreateNewProjectCompletion, CreateNewProjectCompletionSubGoal, CreateNewProjectQuery, VerifyProjectNameQuery } from "../../../services";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import ThanksModal from "../../components/thanksModal";
import Footer from "../../components/footer";
import API from "../../../graphql";
import clientUpload from "../../../helpers/uploader";
import { useLazyQuery, useQuery } from "@apollo/client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MultistepApp = () => {
  //  required user location
  const [coords, setCoords] = useState();
  //show thanks modal
  const [mainPercentage, setMainPercentage] = useState(0);
  const [isThanksModalOpen, setisThanksModalOpen] = useState(false);
  // handle materialui snacksbar for display messages 
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyProjectNameFlag, setVerifyProjectNameFlag] = useState(false);
  // //================================
  const navigate = useNavigate();
  const [progress, setProgress] = useState(1);
  const [selectedRecommendation, setSelectedRecommendation] = useState({});
  const [criticalGols, setCriticalGols] = useState([]);
  const [criticalSubGols, setCriticalSubGols] = useState([]);
  const [inputDivErrors, setInputDivErrors] = useState({
    irr: false,
    sector: false,
    country: false,
    city: false,
    roi: false,
    gp_margin: false,
    eBIT_margin: false,
    net_profit_margin: false,
    timeLineYear: false,
    // timeLineMonth: false,
    project_cost: false,
  });
  const [inputErrors, setInputErrors] = useState({
    pName: false,
    contect_person: false,
    mobile_no: false,
    email: false,
    sector: false,
    city: false,
    gp_margin: false,
    eBIT_margin: false,
    net_profit_margin: false,
    timeLineYear: false,
    // timeLineMonth: false,
    project_cost: false,
    // Add more fields as needed
  });
  // }
  const [loadingFlag, setLoading] = useState(false);
  const [newProjectInfo, addNewProject] = CreateNewProjectQuery();
  const [projectCompletion, addNewCompletion] = CreateNewProjectCompletion();
  const [projectCompSubGoals, addProjectCompSubGoals] = CreateNewProjectCompletionSubGoal();
  const [projectCompSubGoalsDes, addProjectCompSubGoalsDes] = CreateNewProjCompSubGoalDesc();
  const [a, addAttachmentData] = CreateNewAttachemnt();
  const [totalsPercentage, setTotalsPercentage] = useState(0); // Default currency symbol
  const [secendFormInputErrors, setSecendFormInputErrors] = useState({
    pName: false,
    sector: false,
    categoryId: false,
    goals: false
    // Add more fields as needed
  });
  const [step, setStep] = useState("First");
  const [newProject, setNewProject] = useState(null);
  const [projectGoals, setProjectGoals] = useState(null);
  // Add Mobile Formet
  // const mobileRegex = /03[0-9]{2}-(?!1234567)(?!1111111)(?!7654321)[0-9]{7}/;
  const mobileRegex = /^\+92\d{10}$/; // Pakistani mobile number format with +92
  // const mobileRegex = /^03[0-4]\d{8}$/; // Pakistani mobile number format without +92
  // Email validation functiou
  const validateEmailFormat = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const [projectNameV, setProjectNameV] = useState('');
  const ProjectNameFilter = useQuery(API.ApiProjectNameFilter, {
    fetchPolicy: "network-only",
    variables: {
      "filter": {
        "Is_Active": {
          "eq": true
        },
        "Project_name": {
          "eqi": projectNameV
        }
      }
    }
  });
  const [searchRecommendation, recommendationByValue] = useLazyQuery(API.ApiRecommendationByValue, {
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    const list = ProjectNameFilter?.data?.projects?.data;
    if (list?.length) {
      setLoading(false);
      setProjectNameV('');
      setErrorMessage("Project name already exist..!");
    }
    if (list?.length === 0 && loadingFlag) {
      // console.log("bila waja nhi chlna")
      setLoading(false);
      setProjectNameV('');
      setVerifyProjectNameFlag(true);
      setErrorMessage("");
      onNextClick();
    }
  }, [ProjectNameFilter]);
  const onNextClick = async () => {
    if (progress === 1) {
      const { pName,
        sector,
        country,
        city,
        IRR,
        ROI,
        gp_margin,
        eBIT_margin,
        net_profit_margin,
        timeLineYear,
        // timeLineMonth,
        project_cost,
        contect_person,
        mobile_no,
        email
      } = newProject;
      const isNameValid = pName && pName.length > 2;
      const isContactPersonValid = contect_person && contect_person.length > 2;
      const isMobileValid = mobile_no && mobileRegex.test(mobile_no);
      const isEmailValid = email && validateEmailFormat(email);
      const isIrrValid = IRR && IRR.length > 0 && IRR <= 100;
      const isROIFValid = ROI && ROI.length > 0 && ROI <= 100;
      const isGPMarginValid = gp_margin && gp_margin.length > 0 && gp_margin <= 100;
      const isEBITmarginValid = eBIT_margin && eBIT_margin.length > 0 && eBIT_margin <= 100;
      const isNetProfitMarginValid = net_profit_margin && net_profit_margin.length > 0 && net_profit_margin <= 100;
      const isTimelineYearValid = timeLineYear && timeLineYear.length >= 0;
      // const isTimelineMonthValid = timeLineMonth && timeLineMonth.length >= 0 && timeLineMonth.length <= 12;
      const isSectorValid = sector && sector.length > 0;
      const isCountryValid = country && country.length > 0;
      const isCityValid = city && city.length > 0;
      const isProjectCostValid = project_cost && project_cost.length > 0;
      if (
        isIrrValid &&
        isROIFValid &&
        isGPMarginValid &&
        isEBITmarginValid &&
        isNetProfitMarginValid &&
        isTimelineYearValid &&
        isSectorValid &&
        isCountryValid &&
        isCityValid &&
        isProjectCostValid
      ) {
        setInputDivErrors({
          ...inputDivErrors,
          irr: false,
          sector: false,
          country: false,
          city: false,
          roi: false,
          gp_margin: false,
          eBIT_margin: false,
          net_profit_margin: false,
          timeLineYear: false,
          project_cost: false,
        });
      } else {
        setInputDivErrors({
          ...inputDivErrors,
          irr: !isIrrValid,
          sector: !isSectorValid,
          country: !isCountryValid,
          city: !isCityValid,
          roi: !isROIFValid,
          gp_margin: !isGPMarginValid,
          eBIT_margin: !isEBITmarginValid,
          net_profit_margin: !isNetProfitMarginValid,
          timeLineYear: !isTimelineYearValid,
          project_cost: !isProjectCostValid,
        });
      }
      // // -----------handling project verification---------------
      if (isNameValid && !verifyProjectNameFlag) {
        setLoading(true);
        setTimeout(() => {
          setProjectNameV(pName);
        }, 500);
      }
      if (
        isNameValid && verifyProjectNameFlag &&
        isContactPersonValid &&
        isMobileValid &&
        isEmailValid &&
        isGPMarginValid &&
        isEBITmarginValid &&
        isNetProfitMarginValid &&
        isTimelineYearValid &&
        isCityValid &&
        isSectorValid &&
        isProjectCostValid
      ) {
        setInputErrors({
          ...inputErrors,
          pName: false,
          contect_person: false,
          mobile_no: false,
          email: false,
          sector: false,
          project_cost: false,
          gp_margin: false,
          eBIT_margin: false,
          net_profit_margin: false,
          city: false,
          timeLineYear: false,
        });
        setProgress(2);
        setStep("Second");
      } else {
        setInputErrors({
          ...inputErrors,
          pName: !isNameValid,
          contect_person: !isContactPersonValid,
          mobile_no: !isMobileValid,
          email: !isEmailValid,
          city: !isCityValid,
          sector: !isSectorValid,
          gp_margin: !isGPMarginValid,
          eBIT_margin: !isEBITmarginValid,
          net_profit_margin: !isNetProfitMarginValid,
          timeLineYear: !isTimelineYearValid,
          project_cost: !isProjectCostValid,
        });
      }
    }
    if (progress === 2) {
      const { pName,
        sector,
        categoryId,
        goals
      } = projectGoals;

      const isGoalsValid = goals && goals.length > 0 && (true || (categoryId && categoryId.length > 0));
      const iscategoryIdValid = true || (categoryId && categoryId.length > 0);
      const checkSDGValid = goals.filter(item => item?.categoryName?.toLowerCase() === "sdg lens alignment" && item.completed);
      if (checkSDGValid.some(item => !item.comment)) {
        toast.info('Kindly add comment in completed goals on SDG Lens Alignment', {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }
      if (isGoalsValid &&
        iscategoryIdValid
      ) {
        setSecendFormInputErrors({
          ...secendFormInputErrors,
          pName: false,
          sector: false,
          categoryId: false,
          goals: false
        });
        setProgress(3);
        setStep("Third");
      } else {
        setSecendFormInputErrors({
          ...secendFormInputErrors,
          pName: false,
          sector: false,
          categoryId: !iscategoryIdValid,
          goals: !isGoalsValid
        });
      }
    }
  };
  const onPreviousClick = () => {
    if (progress === 3) {
      setProgress(2);
      setStep("Second");
    } else if (progress === 2) {
      setProgress(1);
      setStep("First");
    }
  };
  // const completed_percentage = !!projectGoals?.goals?.length ? (((mainPercentage || 0) / projectGoals.goals.length) * projectGoals.goals.filter(goal => goal.completed).length).toFixed(2) : '0';
  // const sumOfCompletedPercentages = projectGoals?.goals?.reduce((sum, goal) =>
  //   goal.completed ? sum + (+goal.percentage || 0) : sum
  //   , 0);
  // const roundedSum = sumOfCompletedPercentages ? sumOfCompletedPercentages.toFixed(2) : 0;
  // const completed_percentage = roundedSum;

  const completed_percentage = (projectGoals?.goals || []).reduce((sum, goal) =>
    sum + (
      goal?.haveSubGoal
        ? (goal?.subGaolsList || []).filter(subGoal => subGoal?.completed).reduce((subSum, subGoal) => subSum + (+subGoal?.subGoalPercentage || 0), 0)
        : (goal?.completed ? +goal?.percentage : 0)
    ), 0).toFixed(2);

  const createNewProjectFn = async () => {
    try {
      if (newProject && projectGoals) {
        setLoading(true);
        const recommendationByValueResult = await recommendationByValue.refetch({
          "filter": {
            "Is_Active": {
              "eq": true
            },
            "MinValue": {
              "lte": Number(Number(completed_percentage).toFixed()),
            },
            "MaxValue": {
              "gte": Number(Number(completed_percentage).toFixed()),
            },
          }
        });
        const Recommendation = recommendationByValueResult?.data?.recommendations?.data[0]?.attributes;
        const RecommendationId = recommendationByValueResult?.data?.recommendations?.data[0]?.id;
        setSelectedRecommendation(Recommendation);
        const proj = await addNewProject({
          variables: {
            "Is_Active": true,
            "Project_name": newProject.pName,
            "Contact_Person": newProject.contect_person,
            "Email": newProject.email,
            "GP_Margin": newProject.gp_margin,
            // "IRR": Number(newProject.IRR),
            "Mobile_No": newProject.mobile_no,
            "Net_Profit_Margin": newProject.net_profit_margin,
            "Project_Status": "PENDING APPROVAL",
            // "ROI": Number(newProject.ROI),
            "SharePointId": 123,
            // "Ticket_Size": "0",
            "TimeLine_Month": Number(newProject.timeLineMonth),
            "TimeLine_Year": Number(newProject.timeLineYear),
            "city": Number(newProject.city),
            "country": Number(newProject.country),
            "sector": Number(newProject.sector),
            "Project_Cost": newProject.project_cost,
            "Latitude": coords?.let ? String(coords?.let) : "24.8608",
            "Longitude": coords?.long ? String(coords?.long) : "67.0104",
            "currencyId": Number(newProject.currencyID),
            "EBIT_Margin": newProject.eBIT_margin,
            "RecommendationStatusId": Number(RecommendationId)
          }
        });
        const project = proj?.data?.createProject?.data;
        if (project) {
          // console.log("newProject", newProject)
          if (newProject?.filesData?.length) {
            const uploads = [...newProject?.filesData];
            for (let key in uploads) {
              clientUpload
                .mutate({
                  mutation: API.ApiUploadFiles,
                  variables: {
                    file: uploads[key]
                  }
                })
                .then(async (res) => {
                  // console.log("res>>>>>>>>>>>>>>>>>", res)
                  const attLinks = res?.data?.upload.data;
                  const get = await addAttachmentData({
                    variables: {
                      "url": attLinks?.attributes?.url,
                      "name": attLinks?.attributes?.name,
                      "ext": attLinks?.attributes?.ext,
                      "size": String(attLinks?.attributes?.size),
                      "projectId": Number(project.id)
                    }
                  });
                  // console.log("addAttachmentData", get)
                })
                .catch(err => {
                  throw err;
                });
            }
          }
          if (projectGoals.goals) {
            const goals = [...projectGoals.goals];
            let count = 0;
            const allCriticalGoals = goals.filter(item => item.Criteria_Type === 'Critical');
            setCriticalGols(allCriticalGoals);
            let concatenatedArray = [...criticalSubGols];
            for (let key in goals) {
              const goalRes = await addNewCompletion({
                variables: {
                  "projectId": Number(project.id),
                  "sectorId": Number(newProject.sector),
                  "categoryId": Number(goals[key].categoryId),
                  "SharePointId": 321,
                  "Comments": goals[key].comment,
                  "isCompleted": goals[key].completed,
                  "goalId": Number(goals[key].id),
                  "Completed_Percentage": goals[key].percentage,
                  "ScreeningPercentage": String(goals[key].ScreeningPercentage),
                  "ScreeningPerValue": String(goals[key].ScreeningPerValue),
                  "Criteria_Type": goals[key]?.Criteria_Type ?? null,
                  "Criteria_Type_Id": goals[key]?.Criteria_Type_Id ?? null,
                  "CategorySequence": goals[key]?.CategorySequence,
                }
              });
              if (goalRes?.data && goals[key]?.haveSubGoal) {
                // console.log("createProjectCompletion", goalRes?.data?.createProjectCompletion?.data)
                const compGoal = goalRes?.data?.createProjectCompletion?.data;
                // const totalP = goals[key]?.percentage
                const subGoals = [...goals[key]?.subGaolsList];
                if (subGoals?.length) {
                  for (let sgl in subGoals) {
                    const subGaolRes = await addProjectCompSubGoals({
                      variables: {
                        "sub_goal_id": subGoals[sgl]?.id,
                        "Percentage_sub": subGoals[sgl]?.subGoalPercentage,
                        "project_completion_id": compGoal.id,
                        "CategorySequence": subGoals[sgl]?.CategorySequence,
                        "isCompleted": subGoals[sgl]?.completed,
                      }
                    });
                    if (subGaolRes?.data) {
                      // console.log("createProjectCompletionSubGoal", subGaolRes?.data?.createProjectCompletionSubGoal?.data)
                      const compSubGoal = subGaolRes?.data?.createProjectCompletionSubGoal?.data;
                      const subGoalsDesList = [...subGoals[sgl]?.subGoalDescList];
                      const allCriticalSubGoals = subGoalsDesList.filter(item => item.Criteria_Type === 'Critical');
                      // Assuming allCriticalSubGoals is an array
                      concatenatedArray = allCriticalSubGoals.length > 0
                        ? [...concatenatedArray, ...allCriticalSubGoals]
                        : concatenatedArray;
                      setCriticalSubGols(concatenatedArray);
                      for (let sgld in subGoalsDesList) {
                        const subGoalDesRes = await addProjectCompSubGoalsDes({
                          variables: {
                            "project_completion_sub_goal_id": compSubGoal?.id,
                            "sub_goal_description_id": subGoalsDesList[sgld]?.id,
                            "isCompleted": subGoalsDesList[sgld]?.completed,
                            "Comments": subGoalsDesList[sgld]?.comment,
                            "Criteria_Type": subGoalsDesList[sgld]?.Criteria_Type ?? null,
                            "Criteria_Type_Id": subGoalsDesList[sgld]?.Criteria_Type_Id ?? null,
                            "CategorySequence": subGoalsDesList[sgld]?.CategorySequence
                          }
                        });
                        if (subGoalDesRes?.data) {
                          // console.log("createProjCompSubGoalDesc", subGoalDesRes?.data?.createProjCompSubGoalDesc?.data)
                        }
                      }
                    }
                  }
                }
              }
              count++;
            }
            if (count === goals.length) {
              setLoading(false);
              setisThanksModalOpen(true);
              // navigate(-1)
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Extract relevant information from the data, such as city, region, country, etc.
    if (!coords) {
      let key = process.env.REACT_APP_search_address_ip;
      if (key) {
        fetch(`https://ipinfo.io?token=${key}`)
          .then(response => response.json())
          .then(data => {
            let leti = data.loc.slice(0, data.loc.indexOf(','));
            let longi = data.loc.slice(data.loc.indexOf(',') + 1);
            setCoords({
              let: leti,
              long: longi
            });
            // window?.localStorage.setItem("items", JSON.stringify({
            //   let: leti,
            //   long: longi
            // }))
          })
          .catch(error => {
            console.error("Error fetching location:", error);
          });
      }
    }
  }, [coords]);
  return (
    <>
      <div className="container">
        {isThanksModalOpen && <ThanksModal
          investmentFlag={false}
          completedPercent={completed_percentage}
          // totalPercent={mainPercentage || 0}
          totalPercent={totalsPercentage || 0}
          selectedRecommendation={selectedRecommendation}
          criticalGols={criticalGols}
          criticalSubGols={criticalSubGols}
        />}
        <div className="NewProjectHeadCont">
          <div className="NewProjectLogoCont">
            <div className="NewProjectMainLogoCont">
              <Link className='link pointer-cursor' to="/"><img src="/assits/mainLogo.png" alt="Logo" /></Link>
            </div>
            <img className="Project-Logo" src="/assits/submintListLogo.png" alt="Project-Logo" />
            {/* <h1 className="NewProjectHeading">New Project</h1> */}
            <h1 className="NewProjectHeading">{progress === 2 || progress === 3 && !!newProject?.pName ? newProject.pName : 'New Project'} |</h1>
            <span className="sectorName">{progress === 2 || progress === 3 && !!newProject?.sector ? newProject.sectorName : ''}</span>
          </div>
          <div className="CloseCont" onClick={() => navigate(-1)}>
            <div className="closeLink pointer-cursor">
              <img src="/assits/Icon/closeIcon.png" alt="Delet-Icon" />
              <span className="pointer-cursor">Close</span>
            </div>
          </div>
        </div>
        <div className="formMainCont">
          <div className="formtabsCont pageScroll">
            <div className="tabs">
              <ProgressBar activeStep={Math.ceil(progress / 1)} />
            </div>
            <Tabs setTotalsPercentage={setTotalsPercentage} progress={progress} setVerifyProjectNameFlag={setVerifyProjectNameFlag} setMainPercentage={setMainPercentage} mainPercentage={mainPercentage} step={step} setNewProject={setNewProject} setProjectGoals={setProjectGoals} inputClass={inputErrors} inputDiv={inputDivErrors} secendInputError={secendFormInputErrors} />
          </div>
          <div className="btn">
            {errorMessage && (
              <div style={{ width: "calc(100vw / 4)", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span style={{ fontSize: "1em", color: 'red', fontFamily: 'inter' }}>{errorMessage}</span>
              </div>
            )}
            <button
              className="PreviousBtn pointer-cursor"
              onClick={onPreviousClick}
              disabled={progress === 1}
              style={{ pointerEvents: progress === 1 ? "none" : "auto", opacity: progress === 1 ? 0.0 : 1 }}
            >
              Previous Step
            </button>
            {progress === 3 ? (
              <button
                disabled={loadingFlag}
                className="nextBtn"
                onClick={() => createNewProjectFn()}
              >
                {/* Create New Project */}
                Submit
              </button>
            ) : (
              <button
                className="nextBtn pointer-cursor"
                onClick={() => onNextClick()}
              >
                Save and Continue
              </button>
            )}

          </div>
        </div>
        {loadingFlag &&
          <div className="projectSubmintLoder">
            <img className='loaderImg' src='/assits/loaderGif.gif' alt='LoaderImg' />
          </div>
        }
      </div>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default MultistepApp;

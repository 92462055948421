import React from 'react';
import Plot from 'react-plotly.js';
import "./sectorPerfomansAndBarChat.css"


const SectorPerformanceChart = ({ data }) => {
  // Group projects by sector and calculate average ROI for each sector
  const sectors = Array.from(new Set(data.map((project) => project.Sector_name)));
  const sectorAvgROI = sectors.map((sector) => {
    const sectorProjects = data.filter((project) => project.Sector_name === sector);
    const avgROI = sectorProjects.reduce((sum, project) => sum + project.EBIT_Margin, 0) / sectorProjects.length;
    return avgROI;
  });

  // Combine sectors and average ROIs into an array of objects
  const sectorData = sectors.map((sector, index) => ({
    sectorName: sector,
    avgROI: sectorAvgROI[index],
  }));

  // Sort the data in descending order based on average ROI
  sectorData.sort((a, b) => b.avgROI - a.avgROI);

  // Extract sorted sector names and average ROIs
  const sortedSectors = sectorData.map((item) => item.sectorName);
  const sortedAvgROI = sectorData.map((item) => item.avgROI);

  // Define data trace for the bar chart
  const trace = {
    x: sortedSectors,
    y: sortedAvgROI,
    type: 'bar',
    marker: {
      color: 'rgba(77, 208, 73, 0.7)',
    },
  };

  // Define the layout for the chart
  const layout = {
    // title: 'Sector-wise Performance Chart',
    xaxis: { title: 'Sector Names' },
    yaxis: { title: 'Average EBIT Margin' },
  };

  return (
    <div className='scetorAndBar-chart-container'>
      <Plot data={[trace]} layout={layout} className='scetorAndBarchart-element' />
    </div>
  );
};

export default SectorPerformanceChart;

import React from 'react';
import Navigation from './navigation';

function App() {
  return (
    <>
      <Navigation />    
    </>
  );
}

export default App;

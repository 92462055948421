import { gql } from "@apollo/client";

export const getSectors = gql`
  query getSectors {
    sectors(pagination: { limit: 100 }, filters: { Is_Active: { eq: true } }) {
      data {
        id
        attributes {
          Sector_Name
          SharePointId
          File_Url
        }
      }
    }
  }
`;

export const getCountries = gql`
  query getCountries{
    countries(pagination: { limit: 100 }, filters: { Is_Active: { eq: true } }) {
      data {
        id
        attributes {
          Country_Name
          SharePointId
        }
      }
    }
  }
`;

export const getCitiesFitler = gql`
  query citiesFilters($filter: CityFiltersInput) {
    cities(filters: $filter,pagination: { limit: 10000 }) {
      data {
        id
        attributes {
          City_Name
          CountryId
          SharePointId
        }
      }
    }
  }
`;
// CategorySequence
export const getCategories = gql`
  query getCategories{
    categories(pagination: { limit: 10000 }, filters: { Is_Active: { eq: true } }){
      data{
        id
        attributes{
          SharePointId
          Category_Name
          CategorySequence
        }
      }
    }
  }
`;

export const getFilterByGoals = gql`
  query getGoalsId($filter: CategoryGoalFiltersInput){
    categoryGoals(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          CategoryId
          GoalId
          SharePointId
        }
      }
    }
  }
`

export const getGoalsById = gql`
  query getGoalsById($filter: GoalFiltersInput){
    goals(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          Title
          Description
          SharePointId
          Criteria
        }
      }
    }
  }
`;

export const getAllPublishedProjects = gql`
query getAllPublishedProjects($filter: ProjectFiltersInput){
  projects(pagination: { limit: 500 }, filters: $filter){
    data{
      id
      attributes{
        Project_name
        Project_Status
        GP_Margin
        Net_Profit_Margin
        TimeLine_Year
        TimeLine_Month
        Ticket_Size
        EBIT_Margin
        Contact_Person
        Email
        Project_Cost
        Mobile_No
        Latitude
        Longitude
        sector{
          data{
            id
            attributes{
              Sector_Name
              SharePointId
              File_Url
            }
          }
        }
        country{
          data{
            id
            attributes{
              Country_Name
              SharePointId
            }
          }
        }
        city{
          data{
            id
            attributes{
              City_Name
              Latitude
              Longitude
              SharePointId
            }
          }
        }
        SharePointId
      }
    }
  }
}
`;

export const filterCategoryBySector = gql`
  query filterCategoryBySector($filter: SectorScoreFiltersInput){
    sectorScores(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          Sector_Id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          Category_Id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          Percentage
          SharePointId
        }
      }
    }
  }
`;
// CategorySequence
export const filterGoalsByCategoryId = gql`
  query filterGoalsByCategoryId($filter: BenchMarkingFiltersInput){
    benchMarkings(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          Category_Id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          Goal_Id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          Sector_Id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          Is_Selected
          Percentage
          SharePointId
          ScreeningPercentage
          CategorySequence 
          Criteria_Type
          Criteria_Type_Id
        }
      }
    }
  }
`;
export const filterProjectById = gql`
  query filterProjectById($id: ID){
    project(id: $id){
      data{
        id
        attributes{
          Project_name
          Project_Status
          Project_Cost
          GP_Margin
          Net_Profit_Margin
          TimeLine_Year
          TimeLine_Month
          Ticket_Size
          EBIT_Margin
          Contact_Person
          Email
          Mobile_No
          ProjectComments
          sector{
            data{
              id
              attributes{
                Sector_Name
                SharePointId
              }
            }
          }
          country{
            data{
              id
              attributes{
                Country_Name
                SharePointId
              }
            }
          }
          currencyId{
            data{
              id
              attributes{
                Title
                Symbol
              }
            }
          }
          city{
            data{
              id
              attributes{
                City_Name
                SharePointId
              }
            }
          }
          SharePointId
          RecommendationStatusId{
            data{
              id
              attributes{
                Title
              }
            }
          }
        }
      }
    }
  }
`;

export const filterProjectByName = gql`
query filterProjectByName($filter: ProjectFiltersInput){
  projects(filters: $filter, pagination: { limit: 10000 }){
    data{
      id
      attributes{
        Project_name
      }
    }
  }
}
`

export const listOfCompletedProject = gql`
query projectCompletions($filter: ProjectCompletionFiltersInput){
  projectCompletions(filters:$filter,pagination: { limit: 100000 }){
      data{
          id
          attributes{
            projectId{
                data{
                    id
                    attributes{
                        Project_name
                        SharePointId
                    }
                }
            }
            sectorId{
                data{
                    id
                    attributes{
                        Sector_Name
                        SharePointId
                    }
                }
            }
            categoryId{
                data{
                    id
                    attributes{
                        Category_Name
                        SharePointId
                    }
                }
            }
            goalId{
                data{
                    id
                    attributes{
                        SharePointId
                        Description
                        Title
                        Criteria
                    }
                }
            }
            SharePointId
            Comments
            isCompleted
            Completed_Percentage
            ScreeningPerValue
            ScreeningPercentage
            Criteria_Type
            Criteria_Type_Id
            CategorySequence
          }
      }
  }
}
`;
export const getCurrencies = gql`
    query getCurrencies{
        currencies(pagination: { limit: 100000 }, filters: { Is_Active: { eq: true } }){
            data{
                id
                attributes{
                    SharePointId
                    Symbol
                    Title
                }
            }
        }
    }
`;

export const getSubGoalDescription = gql`
  query getSubGoalDescription($filter: SubGoalDescriptionFiltersInput){
    subGoalDescriptions(filters:$filter, pagination: { limit: 100000 }){
      data{
        id
        attributes{
          Title
          SharePointId
          Criteria_Type
          Criteria_Type_Id
          CategorySequence
          sub_goal_id{
            data{
              id
              attributes{
                Title
                SharePointId
              }
            }
          }
        }
      }
    }
  }
`
// CategorySequence
export const getSubGoals = gql`
  query getSubGoals($filter: SubGoalFiltersInput){
    subGoals(filters:$filter, pagination: { limit: 100000 }){
      data{
        id
        attributes{
          goalId{
            data{
              id
              attributes{
                Title
                SharePointId
                Description
              }
            }
          }
          Title
          SharePointId
          CategorySequence
        }
      }
    }
  }
`
export const getProjectCompletionSubGoals = gql`
  query projectCompletionSubGoals{
    projectCompletionSubGoals(pagination: { limit: 100000 }){
      data{
        id
        attributes{
          Title
          SharePointId
          Percentage_sub
          sub_goal_id{
            data{
              id
              attributes{
                Title
              }
            }
          }
          project_completion{
            data{
              id
              attributes{
                projectId{
                  data{
                    id
                    attributes{
                      Project_name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const getProjCompSubGoalDescs = gql`
  query projCompSubGoalDescs{
    projCompSubGoalDescs(pagination: { limit: 100000000 }){
      data{
        id
        attributes{
          Title
          project_completion_sub_goal_id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          sub_goal_description_id{
            data{
              id
              attributes{
                Criteria_Type
                SharePointId
              }
            }
          }
          SharePointId
          isCompleted
          Comments
        }
      }
    }
  }
`;
// CategorySequence
export const filterProjectCompletionSubGoals = gql`
  query filterProjectCompletionSubGoals($filter: ProjectCompletionSubGoalFiltersInput){
    projectCompletionSubGoals(filters: $filter, pagination: { limit: 100000 }){
      data{
        id
        attributes{
          SharePointId
          Percentage_sub
          CategorySequence
          isCompleted
          sub_goal_id{
            data{
              attributes{
                Title
                SharePointId
                goalId{
                  data{
                    id
                    attributes{
                      Title
                      SharePointId
                    }
                  }
                }
              }
            }
          }
          project_completion{
            data{
              id
              attributes{
                isCompleted
                Completed_Percentage
                projectId{
                  data{
                    id
                    attributes{
                      Project_name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const filterProjCompSubGoalDescs = gql`
  query filterProjCompSubGoalDescs($filter: ProjCompSubGoalDescFiltersInput){
    projCompSubGoalDescs(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          SharePointId
          isCompleted
          Comments
          SharePointId
          CategorySequence
          Criteria_Type
          project_completion_sub_goal_id{
            data{
              id
              attributes{
                SharePointId
              }
            }
          }
          sub_goal_description_id{
            data{
              id
              attributes{
                sub_goal_id{
                  data{
                    id
                    attributes{
                      SharePointId
                      Title
                    }
                  }
                }
                Title
              }
            }
          }
        }
      }
    }
  }
`;

export const filterFileAttachments = gql`
query filterFileAttachments($filter: AttachmentFiltersInput){
  attachments(filters:$filter){
    data{
      id
      attributes{
        url
        name
        ext
        projectId{
          data{
            id
            attributes{
              Project_name
            }
          }
        }
      }
    }
  }
}
`;

export const filterRecommendationByValue = gql`
  query filterRecommendationByValue($filter: RecommendationFiltersInput){
    recommendations(filters: $filter, pagination: { limit: 10000 }){
      data{
        id
        attributes{
          SharePointId
          Title
          MinValue
          MaxValue
        }
      }
    }
  }
`;

export const getBenchMarking = gql`
query getBenchMarking{
  benchMarkings(pagination: { limit: 100000 }, filters: { Is_Active: { eq: true } }){
          data{
          id
              attributes{
                 Sector_Id{
                      data{
                      id
                  }
              }
          }
      }
  }
}
`;

export const getSectorById = gql`
query getSectorById($filter: SectorFiltersInput){
    sectors(filters: $filter, pagination: { limit: 100000 }){
      data{
        id
        attributes{
          Sector_Name
          SharePointId
          File_Url
        }
      }
    }
  }
`;

export const getGoals = gql`
query getGoals{
  goals(pagination: { limit: 100 }, filters: { Is_Active: { eq: true } }) {
    data {
      id
      attributes {
        Title
        SharePointId
      }
    }
  }
}
`;

export const getAllProjectToReview = gql`
query getAllProjectToReview{
  projects(pagination: { limit: 500 }, filters: { Project_Status: { eqi: "PENDING APPROVAL" } }){
    data{
      id
      attributes{
        Project_name
        Project_Status
        GP_Margin
        Net_Profit_Margin
        TimeLine_Year
        TimeLine_Month
        Ticket_Size
        EBIT_Margin
        Contact_Person
        Email
        Project_Cost
        Mobile_No
        Latitude
        Longitude
        sector{
          data{
            id
            attributes{
              Sector_Name
              SharePointId
              File_Url
            }
          }
        }
        country{
          data{
            id
            attributes{
              Country_Name
              SharePointId
            }
          }
        }
        city{
          data{
            id
            attributes{
              City_Name
              Latitude
              Longitude
              SharePointId
            }
          }
        }
        SharePointId
      }
    }
  }
}
`;

export const getSectorScores = gql`
query getSectorScores {
  sectorScores(pagination: { limit: 1000 }, filters: { Is_Active: { eq: true } }) {
      data {
      id
        attributes {
        Percentage
        SharePointId
        Category_Id{
          data{
            attributes{
              CategorySequence
              Category_Name
            }
          }
        }
        Sector_Id{
            data{
            id
              attributes{
              Sector_Name
            }
          }
        }
      }
    }
  }
}
`;

export const getInvestments = gql`
query getInvestments {
  investments(pagination: { limit: 100000 }) {
      data {
      id
        attributes {
        project{
          data{
            attributes{
              Project_name
              sector{
              data{
               attributes{
                    Sector_Name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const getBenchMarkings = gql`
query getBenchMarkings{
  benchMarkings(filters: { Is_Active: { eq: true } }, pagination: { limit: 100000 }){
    data{
      id
      attributes{
        Category_Id{
          data{
            id
            attributes{
              SharePointId
            }
          }
        }
        Goal_Id{
          data{
            id
            attributes{
              SharePointId
            }
          }
        }
        Sector_Id{
          data{
            id
            attributes{
              SharePointId
            }
          }
        }
        Is_Selected
        Percentage
        SharePointId
        ScreeningPercentage
        CategorySequence 
        Criteria_Type
        Criteria_Type_Id
      }
    }
  }
}
`;
import React from 'react';
import Plot from 'react-plotly.js';

const NetProfitMarginChart = ({ data }) => {
  // Calculate net profit margin for each project
  const netProfitMargins = data.map((project) => ({
    name: project.Project_Name,
    margin: (project.Net_Profit_Margin),
  }));

  // Sort the data in ascending order based on net profit margin
  netProfitMargins.sort((b, a) => a.margin - b.margin);

  // Extract project names for labels
  const projectNames = netProfitMargins.map((project) => project.name);
  const sortedMargins = netProfitMargins.map((project) => project.margin);

  // Define data trace for the bar chart
  const trace = {
    x: projectNames,
    y: sortedMargins,
    type: 'bar',
    marker: {
      color: 'rgba(75, 192, 192, 0.6)',
    },
  };

  // Define the layout for the chart
  const layout = {
    xaxis: { title: 'Project Names' },
    yaxis: { title: 'Net Profit Margin (%)' },
  };

  return (
    <div >
      <Plot data={[trace]} layout={layout} className='scetorAndBarchart-element' />
    </div>
  );
};

export default NetProfitMarginChart;

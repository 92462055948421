import * as React from 'react';
import { useEffect } from 'react';
var Plotly = require('plotly.js-dist-min')

const PublishedProjectsChartCityBased = ({ projectDetails }) => {
    const elementId = 'pie-chart-byCity'
    useEffect(() => {
        if (projectDetails && projectDetails.length) {
            const groupedData = projectDetails.reduce((accumulator, currentItem) => {
                const cityName = currentItem && currentItem.City;
                if (cityName) {
                    if (!accumulator[cityName]) {
                        accumulator[cityName] = {
                            city: cityName,
                            count: 1,
                        };
                    } else {
                        accumulator[cityName].count += 1;
                    }
                }
                return accumulator;
            }, {});
            const values = objectValues(groupedData).map(item => item.count);
            const labels = objectValues(groupedData).map(item => item.city);

            const trace = {
                values: values,
                labels: labels,
                type: 'pie',
                textinfo: 'value', // Set textinfo to 'none' to avoid displaying percentages
                hoverinfo: 'label+text', // Set hoverinfo to 'label+text' to display both label and custom text
                hovertemplate: '<b>%{label}</b><br>No of projects: %{text}', // Customize the tooltip content
                text: objectValues(groupedData).map(item => item.count),
                // marker: {
                //   colors: ['rgb(23, 131, 255)', /* add more colors as needed */],
                // },
            };
            const layout = {
                margin: { t: 0, l: 0, r: 0, b: 0 },
                showlegend: true,
                legend: {
                    x: 1, // Adjust the x-coordinate to move the legend horizontally
                    y: 1, // Adjust the y-coordinate to move the legend vertically
                },
                autosize: true,      // Set autosize to responsive
                width: undefined,
            };
            const config = {
                displayModeBar: false, // Hide the entire mode bar
            };
            const plotData = [trace];
            Plotly.react(elementId, plotData, layout, config);
        } else {
            // Handle the case when there is no data
            const layout = {
                margin: { t: 0, l: 0, r: 0, b: 0 },
                showlegend: true,
                legend: {
                    x: 1,
                    y: 1,
                },
                autosize: true,
                width: undefined,
                xaxis: {
                    showticklabels: false,
                },
            };
            const config = {
                displayModeBar: false,
            };
            const emptyPlotData = [
                {
                    values: [1],
                    labels: ['No data'],
                    type: 'pie',
                    textinfo: 'text',
                    hoverinfo: 'label+text',
                    hovertemplate: 'No data',
                    text: [0],
                },
            ];
            Plotly.react(elementId, emptyPlotData, layout, config);
        }
    }, [projectDetails, '', elementId]);
    return (
        <div>
            <div id={elementId} style={{ height: '260px', margin: '20px' }}></div>
        </div>
    );
};

export default PublishedProjectsChartCityBased;


const objectValues = (obj) => {
    return Object.keys(obj).map(key => obj[key]);
}
import { gql } from "@apollo/client";

export const createNewProjectMutation = gql`
    mutation createNewProject(
        $Project_name: String,
        $GP_Margin: String,
        $Net_Profit_Margin: String,
        $TimeLine_Year: Int,
        $TimeLine_Month: Int,
        $city: ID,
        $country: ID,
        $sector: ID,
        $SharePointId: Long,
        $Project_Status: String,
        $Contact_Person: String,
        $Email: String,
        $Mobile_No: String,
        $Project_Cost: String
        $Latitude: String,
        $Longitude: String,
        $currencyId: ID,
        $EBIT_Margin: String,
        $RecommendationStatusId: ID,
        $Is_Active: Boolean
    ){
        createProject(data:{ 
            Project_name:$Project_name, 
            GP_Margin:$GP_Margin,
            Net_Profit_Margin:$Net_Profit_Margin,
            TimeLine_Year: $TimeLine_Year,
            TimeLine_Month: $TimeLine_Month,
            city:$city,
            country: $country,
            sector:$sector,
            SharePointId:$SharePointId,
            Project_Status:$Project_Status,
            Contact_Person: $Contact_Person,
            Email:$Email,
            Mobile_No: $Mobile_No,
            Project_Cost: $Project_Cost,
            Latitude:$Latitude,
            Longitude:$Longitude,
            currencyId: $currencyId,
            EBIT_Margin: $EBIT_Margin,
            RecommendationStatusId: $RecommendationStatusId
            Is_Active: $Is_Active
        }){
            data{
                id
                attributes {
                    Project_name
                    SharePointId
                    Project_Status
                }
            }
        }
    }
`;

export const createNewProjectCompletion = gql`
mutation newProjectCompletion(
    $projectId: ID,
    $sectorId: ID,
    $categoryId: ID,
    $goalId: ID,
    $SharePointId: Long,
    $Comments: String,
    $isCompleted: Boolean,
    $Completed_Percentage: String,
    $ScreeningPerValue: String,
    $ScreeningPercentage: String,
    $Criteria_Type: String,
    $Criteria_Type_Id: Int
    $CategorySequence: Int
){
    createProjectCompletion(data:{
        projectId: $projectId,
        sectorId: $sectorId,
        categoryId: $categoryId,
        goalId: $goalId,
        SharePointId: $SharePointId,
        Comments: $Comments,
        isCompleted: $isCompleted,
        Completed_Percentage: $Completed_Percentage,
        ScreeningPerValue: $ScreeningPerValue,
        ScreeningPercentage: $ScreeningPercentage,
        Criteria_Type: $Criteria_Type,
        Criteria_Type_Id: $Criteria_Type_Id
        CategorySequence: $CategorySequence
    }){
        data{
            id
            attributes{
                SharePointId
                projectId{
                    data{
                        id
                        attributes{
                            Project_name
                        }
                    }
                }
            }
        }
    }
}
`;

export const createNewInvestment = gql`
    mutation createInvestment(
        $Investor_Name: String,
        $Investor_Email:String,
        $Investor_Number:String,
        $country: ID,
        $Investor_Address:String,
        $Investor_Intent: String,
        $projectId: ID,
        $SharePointId: Long,
        $Latitude: String,
        $Longitude: String
    ){
        createInvestment(data:{
            Investor_Name: $Investor_Name,
            Investor_Email: $Investor_Email,
            Investor_Number: $Investor_Number,
            country: $country,
            Investor_Address: $Investor_Address,
            Investor_Intent: $Investor_Intent,
            project: $projectId,
            SharePointId: $SharePointId,
            Latitude: $Latitude,
            Longitude: $Longitude
            }){
                data{
                    id
                    attributes{
                    Investor_Name
                    SharePointId
                    project{
                        data{
                            id
                            attributes{
                                Project_name
                                SharePointId
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const newProjectCompletionSubGoal = gql`
    mutation newProjectCompletionSubGoal(
        $sub_goal_id: ID,
        $project_completion_id: ID,
        $Percentage_sub: String,
        $CategorySequence: Int,
        $isCompleted: Boolean
    ){
        createProjectCompletionSubGoal(data:{
            sub_goal_id: $sub_goal_id,
            project_completion: $project_completion_id,
            Percentage_sub: $Percentage_sub,
            CategorySequence: $CategorySequence,
            isCompleted: $isCompleted
        }){
            data{
                id
                attributes{
                    SharePointId
                    sub_goal_id{
                        data{
                            id
                            attributes{
                                SharePointId
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const newProjCompSubGoalDesc = gql`
    mutation createProjCompSubGoalDesc(
        $project_completion_sub_goal_id: ID,
        $sub_goal_description_id: ID,
        $isCompleted: Boolean,
        $Comments: String,
        $Criteria_Type: String,
        $Criteria_Type_Id: Int,
        $CategorySequence: Int
    ){
        createProjCompSubGoalDesc(data:{
            isCompleted: $isCompleted,
            project_completion_sub_goal_id: $project_completion_sub_goal_id,
            sub_goal_description_id: $sub_goal_description_id,
            Comments: $Comments,
            Criteria_Type: $Criteria_Type,
            Criteria_Type_Id: $Criteria_Type_Id,
            CategorySequence: $CategorySequence
        }){
            data{
                id
                attributes{
                    sub_goal_description_id{
                        data{
                            id
                            attributes{
                                SharePointId
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const uploadFiles = gql`
    mutation uploadFiles($file: Upload!){
        upload(file:$file){
            data{
                id
                attributes{
                    url
                    name
                    ext
                    size
                    formats
                }
            }
        }
    }
`;

export const newAttachments = gql`
    mutation createAttachments($url: String, $name: String, $ext: String, $size: String, $projectId: ID){
        createAttachment(data:{
            url: $url,
            name: $name,
            ext: $ext,
            size: $size,
            projectId: $projectId
        }){
            data{
                id
                attributes{
                    projectId{
                        data{
                            id
                            attributes{
                                Project_name
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const newInvestmentAttachments = gql`
    mutation newInvestmentAttachments($url: String, $name: String, $ext: String, $size: String, $investmentId: ID){
        createInvetmentAttachment(data:{
            url: $url,
            name: $name,
            ext: $ext,
            size: $size,
            investmentId: $investmentId
        }){
            data{
                id
                attributes{
                    investmentId{
                        data{
                            id
                        }
                    }
                }
            }
        }
    }
`;
import React, { useCallback, useState, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import './fileInput.css';

const AcceptedFileTypes = [
  'application/pdf', // Accepts PDF files
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Accepts Excel files
  'application/msword', // For .doc files
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // For .docx files
];

const FileSelector = ({ onFileSelect }) => {
  const fileInputRef = useRef(null); // Create a ref for the file input
  const [selectedFiles, setSelectedFiles] = useState([]); // Store selected file details

  const onDrop = useCallback((acceptedFiles) => {
    // Filter out files that are not of the accepted types
    const validFiles = acceptedFiles.filter((file) =>
      AcceptedFileTypes.includes(file.type)
    );
    const renameFile = (originalFile, newName) => {
      return new File([originalFile], newName + originalFile.name.replace(/\s/g, ''), {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
      });
    }
    const arr = [renameFile(validFiles[0], new Date().getTime())]
    // Combine the valid files with existing selected files
    const updatedSelectedFiles = [...selectedFiles, ...arr];
    // Pass the valid files to the callback function
    onFileSelect(updatedSelectedFiles);
    // Update the selected file details
    setSelectedFiles(updatedSelectedFiles);
  }, [onFileSelect, selectedFiles]);

  const removeFile = (file) => {
    // Remove the selected file based on its details
    const updatedFiles = selectedFiles.filter((item) => item !== file);
    setSelectedFiles(updatedFiles);
    // Notify the parent component about the removed file if needed
    // You can pass updatedFiles to a callback if necessary
  };

  const truncateFileName = (fileName, maxLength) => {
    if (!fileName) {
      return ''; // Handle undefined/null fileName
    }
    return fileName.length > maxLength
      ? fileName.substring(0, maxLength) + '...'
      : fileName;
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: AcceptedFileTypes.join(','), // Specify accepted file types
    multiple: true, // Allow multiple file selection
  });

  const openFileSelector = () => {
    // Trigger the file input element
    fileInputRef.current.click();
  };

  return (
    <div className="fileInputCont">
      <div className="fileNameCont">
        {selectedFiles.map((file, index) => (
          <span key={index} title={file.name}>
            {truncateFileName(file.name, 15)}
            <img
              className='cancelIcon'
              src="/assits/Icon/fileCancelIcon.png"
              alt="file-Icon"
              onClick={() => removeFile(file)} // Add onClick handler for cancel icon
            />
          </span>
        ))}
      </div>
      <div {...getRootProps()} className="fileButton" onClick={openFileSelector}>
        <img
                  className="input-img"
                  src="/assits/Icon/fileSelectIcon.png" alt="file-Icon" />
      </div>
      <input {...getInputProps()} ref={fileInputRef} style={{ display: 'none' }} />
    </div>
  );
};

export default FileSelector;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .MobileNevbarCont{
    display: none !important;
  }

  @media only screen and (max-width: 900px) {
    .webNevbarCont{
        display: none !important;
    }
    .MobileNevbarCont{
        display: block !important;
      }
  }`, "",{"version":3,"sources":["webpack://./src/App/components/navbar/navbar.css"],"names":[],"mappings":"EAAE;IACE,wBAAwB;EAC1B;;EAEA;IACE;QACI,wBAAwB;IAC5B;IACA;QACI,yBAAyB;MAC3B;EACJ","sourcesContent":["  .MobileNevbarCont{\n    display: none !important;\n  }\n\n  @media only screen and (max-width: 900px) {\n    .webNevbarCont{\n        display: none !important;\n    }\n    .MobileNevbarCont{\n        display: block !important;\n      }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-switch {
    width: 60px;
    height: 30px;
    position: relative;
    border-radius: 18px;
    /* background-color: #1976d2; */
    border: 1px solid #1976d2;
    transition: background-color 0.3s;
    cursor: pointer;
}

.custom-switch.checked {
    border: 1px solid #4caf50;
    background-color: #4caf50;
}

.thumb {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 3px;
    left: 5px;
    border-radius: 50%;
    background-color: #1976d2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s; /* Change the property to transform */
}

.custom-switch.checked .thumb {
    background-color: #FFFFFF;
    transform: translateX(100%); /* Move the button to the right */
}
`, "",{"version":3,"sources":["webpack://./src/App/components/customSwitch.js/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,+BAA+B;IAC/B,yBAAyB;IACzB,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,yBAAyB;IACzB,0CAA0C;IAC1C,0BAA0B,EAAE,qCAAqC;AACrE;;AAEA;IACI,yBAAyB;IACzB,2BAA2B,EAAE,iCAAiC;AAClE","sourcesContent":[".custom-switch {\n    width: 60px;\n    height: 30px;\n    position: relative;\n    border-radius: 18px;\n    /* background-color: #1976d2; */\n    border: 1px solid #1976d2;\n    transition: background-color 0.3s;\n    cursor: pointer;\n}\n\n.custom-switch.checked {\n    border: 1px solid #4caf50;\n    background-color: #4caf50;\n}\n\n.thumb {\n    width: 24px;\n    height: 24px;\n    position: absolute;\n    top: 3px;\n    left: 5px;\n    border-radius: 50%;\n    background-color: #1976d2;\n    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);\n    transition: transform 0.3s; /* Change the property to transform */\n}\n\n.custom-switch.checked .thumb {\n    background-color: #FFFFFF;\n    transform: translateX(100%); /* Move the button to the right */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

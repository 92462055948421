import React, { useState } from 'react';
import './table.css'; // Import your CSS file
import Pagination from '../components/pagination/pagination';

function Table({ data }) {
  const [dashBoardTablecurrentPage, setDashBoardTableCurrentPage] = useState(1);

  // Ensure there is data before rendering the table
  if (!data || data.length === 0) {
    return <p>No data to display.</p>;
  }

  // Get the column names from the first data object
  const columns = Object.keys(data[0]);

  // Number of rows per page
  const itemsPerPage = 6;
  // Calculate total pages based on data length and itemsPerPage
  const totalPages = Math.ceil(data.length / itemsPerPage);
  // Calculate the start and end index for the current page
  const startIndex = (dashBoardTablecurrentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, data.length);
  // Slice the data for the current page
  const slicedData = data.slice(startIndex, endIndex);

  return (
    <>
    <div className='DashBoardMainCont'>
      <div className='tableMainCont'>
        <div className="table-container">
          <table className='dashBoard-Table'>
            <thead>
              <tr>
                {columns.map(column => (
                  <th className='dashBoard-Table-th' key={column}>{column}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {slicedData.map((rowData, index) => (
                <tr key={index}>
                  {columns.map(column => (
                    <td className='dashBoard-Table-td' key={column}>{rowData[column]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
        <div className='DashBoardpaginationCont'>
          <Pagination
            currentPage={dashBoardTablecurrentPage}
            totalPages={totalPages}
            setCurrentPage={setDashBoardTableCurrentPage}
          />
        </div>
    </>
  );
}

export default Table;


// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footerCont{
    display: flex;
    justify-content: center;
    background-color: #1F5A95;
    height: 7.9vh;

    /* margin-top: 4px; */
}

.footerCont p{
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
}

@media only screen and (max-width: 900px) {
    .footerCont {
        height: 9vh;
    }
}

@media only screen and (max-width: 500px) {
    .footerCont p{
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
    }
}

@media only screen and (max-width: 400px) {
    .footerCont p{
        font-size: 15px;
        font-weight: 400;
        color: #FFFFFF;
    }
}`, "",{"version":3,"sources":["webpack://./src/App/components/footer/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,yBAAyB;IACzB,aAAa;;IAEb,qBAAqB;AACzB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,eAAe;QACf,gBAAgB;QAChB,cAAc;IAClB;AACJ;;AAEA;IACI;QACI,eAAe;QACf,gBAAgB;QAChB,cAAc;IAClB;AACJ","sourcesContent":[".footerCont{\n    display: flex;\n    justify-content: center;\n    background-color: #1F5A95;\n    height: 7.9vh;\n\n    /* margin-top: 4px; */\n}\n\n.footerCont p{\n    font-size: 18px;\n    font-weight: 400;\n    color: #FFFFFF;\n}\n\n@media only screen and (max-width: 900px) {\n    .footerCont {\n        height: 9vh;\n    }\n}\n\n@media only screen and (max-width: 500px) {\n    .footerCont p{\n        font-size: 16px;\n        font-weight: 400;\n        color: #FFFFFF;\n    }\n}\n\n@media only screen and (max-width: 400px) {\n    .footerCont p{\n        font-size: 15px;\n        font-weight: 400;\n        color: #FFFFFF;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

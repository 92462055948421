import React from 'react';
import PropTypes from 'prop-types';
import './style.css'

const CustomSwitch = ({ checked, onChange }) => {
    return (
        <div className={`custom-switch pointer-cursor ${checked ? 'checked' : ''}`} onClick={onChange}>
            <div className="thumb"></div>
        </div>
    );
};

CustomSwitch.propTypes = {
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default CustomSwitch;

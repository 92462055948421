import React, { useEffect, useState } from "react";
import GoalsAndDescription from "../project-form/goals-and-description";
import OverView from "../project-form/overview";
import ProjectForm from "../project-form/project";
import { useLazyQuery, useQuery } from "@apollo/client";
import API from "../../../graphql";
import { Inputs } from "../input";
import { FilterByGoalsId, FilterCategoryBySector, FilterGoalsByCategoryId, GetSubGoal, GetSubGoalDescription, SectorFilterByBenchmarking } from '../../../services';

const Tabs = (props) => {
  const { setTotalsPercentage, progress, setVerifyProjectNameFlag, step, setNewProject, setProjectGoals, inputClass, inputDiv, secendInputError, setMainPercentage, mainPercentage } = props;
  // graph ql queries
  const BenchMarkingQuery = useQuery(API.ApiBenchMarking);
  const countriesQuery = useQuery(API.ApiCountries);
  const [loadingFlag, setLoading] = useState(false);// for refetch sector
  const [searchCities, { data, refetch }] = useLazyQuery(API.ApiCitiesFitler, {
    fetchPolicy: 'network-only'
  });
  const citiesData = data?.cities?.data;
  // API for goals and description
  const categories = useQuery(API.ApiCategories);
  const [GoalsById, filterByGoals] = FilterByGoalsId();
  const [SectorById, GetSectorByIds] = SectorFilterByBenchmarking();
  const [subGoals, filterSubGaols] = GetSubGoal();
  const [subGoalsDesc, filterSubGaolsDesc] = GetSubGoalDescription();
  const [categoryListIds, apiFilterBySector] = FilterCategoryBySector();
  const [GoalIds, fetchGoalsIdByCategory] = FilterGoalsByCategoryId();
  // data state and array
  const [sectors, setSectors] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [goals, setGoals] = useState([]);
  const currencies = useQuery(API.ApiGetCurrencies);
  const [selectedCurrency, setSelectedCurrency] = useState([]); // Default currency symbol
  // input states.......................
  const [pName, projectInput] = Inputs.UseInput({
    type: "text",
    placeholder: "",
    className: inputClass.pName ? "inputError" : "input",
    defaultValue: "",
  });
  useEffect(() => {
    if (pName) {
      setVerifyProjectNameFlag(false);
    }
  }, [pName]);
  const [sector, sectorSelect, sectorName] = Inputs.UseSelectSector({
    placeholder: "Please select sector",
    options: sectors,
    feildName: "sector",
    inputDiv: inputDiv,
  });
  const [country, countrySelect, setCountry] = Inputs.UseSelectCountry({
    placeholder: "Please select country",
    options: countries,
    feildName: "country",
    className: "selectInput parsentInput",
    inputDiv: inputDiv,
  });

  useEffect(() => {
    if (currencies) {
      const list = currencies?.data?.currencies.data;
      let arrayCountryList = [];
      if (list) {
        for (let key in list) {
          arrayCountryList.push({
            id: list[key].id,
            symbol: list[key].attributes.Symbol,
            shId: list[key].attributes.SharePointId,
            title: list[key].attributes.Title,
            selected: list[key].attributes.Symbol === "$" ? true : false
          });
        }
        setSelectedCurrency(arrayCountryList);
      }
    }
  }, [currencies]);
  useEffect(() => {
    //filter city base on selected country
    if (country !== 0) {
      setCity("Please select city");
      const list = countries?.find((e) => e.id === country);
      refetch({
        "filter": {
          "Is_Active": {
            "eq": true
          },
          "CountryId": {
            "in": list.shId
          }
        }
      });
    }
  }, [country]);
  const getDataBySector = async () => {
    const arrayOfCategoryList = [...categoryList];
    const filter = sectors?.find((e) => e.id === sector);
    const sectorsData = await apiFilterBySector({
      "filter": {
        "Is_Active": {
          "eq": true
        },
        "Sector_Id": {
          "SharePointId": {
            "eq": filter.shId
          },
        }
      }
    });
    if (sectorsData?.data) {
      let sectorList = sectorsData?.data?.sectorScores?.data;
      // set data base on sector
      if (sectorList?.length) {
        let totalPercentage = 0;
        const categoriesData = categories?.data?.categories?.data;
        for (let key in sectorList) {
          totalPercentage += parseFloat(sectorList[key]?.attributes.Percentage);
          let shId = sectorList[key]?.attributes.Category_Id.data?.attributes.SharePointId;
          const list = categoriesData.find((e) => e.attributes.SharePointId === shId);
          let dontHave;
          if (arrayOfCategoryList?.length) {
            dontHave = await arrayOfCategoryList.find((e) => e.id === list?.id);
          }
          if (!dontHave && list && list.id) {
            arrayOfCategoryList.push({
              id: list?.id,
              name: list?.attributes?.Category_Name,
              shId: list?.attributes?.SharePointId,
              CategorySequence: list?.attributes?.CategorySequence,
              percentage: sectorList[key]?.attributes.Percentage,
              projectSector: sectorName// for refetch sector
              // sId: list?.attributes.Sort_id
            });
          }
          const copy = [...arrayOfCategoryList];
          // copy.sort((a, b) => a.sId - b.sId)
          const sortedArray = copy.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
          setCategoryList(sortedArray);
        }
        setTotalsPercentage(totalPercentage);
      }
    }
  };
  useEffect(() => {
    //filter Category base on selected sector
    if (sector) {
      if (sector !== 0) {
        getDataBySector();
      }
    }
  }, [sector]);
  const [city, citySelect, setCity] = Inputs.UseSelectCity({
    placeholder: "Please select city",
    options: cities,
    feildName: "city",
    inputDiv: inputDiv,

  });
  const [IRR, irrInput] = Inputs.UseNumberInput({
    type: "text",
    placeholder: "",
    className: "customInput",
    defaultValue: ""
  });
  const [ROI, roiInput] = Inputs.UseNumberInput({
    type: "text",
    placeholder: "",
    className: "customInput",
    defaultValue: ""
  });
  const [gp_margin, gpMarginInput] = Inputs.UseNumberInput({
    type: "text",
    placeholder: "",
    className: "customInput",
    defaultValue: ""
  });
  const [eBIT_margin, EBITMarginInput] = Inputs.UseNumberInput({
    type: "text",
    placeholder: "",
    className: "customInput",
    defaultValue: ""
  });
  const [net_profit_margin, netProfitMarginInput] = Inputs.UseNumberInput({
    type: "text",
    placeholder: "",
    className: "customInput",
    defaultValue: ""
  });
  const [timeLineYear, timeLineYearInput, setTimeLineYear] = Inputs.UseTimeLineNumberInput({
    type: "text",
    className: "timeLineInput",
    defaultValue: 0,
    placeholder: 'year'
  });
  const [timeLineMonth, timeLineMonthInput, setTimeLineMonth] = Inputs.UseTimeLineNumberInput({
    type: "text",
    className: "timeLineInput",
    defaultValue: 0,
    placeholder: 'month'
  });
  const togglePriceDropDown = (id) => {
    const arrayCurrencyList = [...selectedCurrency];
    for (let e in arrayCurrencyList) {
      if (arrayCurrencyList[e].id === id) {
        arrayCurrencyList[e].selected = true;
      } else {
        arrayCurrencyList[e].selected = false;
      }
    }
    setSelectedCurrency(arrayCurrencyList);
  };
  const [currencyID, project_cost, currencySelectInput, setProjectCost] = Inputs.CurrencySelect({
    options: selectedCurrency,
    togglePriceDropDown: togglePriceDropDown,
    inputDiv: inputDiv,
  });
  const [contect_person, contectPersonInput] = Inputs.UseAlphabeticInput({
    type: "text",
    className: inputClass.contect_person ? "inputError" : "input",
    defaultValue: "",
    placeholder: ""
  });
  const [mobile_no, mobileNoInput] = Inputs.UseMobileNumberInput({
    type: "text",
    className: inputClass.mobile_no ? "inputError" : "input",
    placeholder: "+92**********",
    defaultValue: "+92"
  });
  const [email, emailInput] = Inputs.UseEmailInput({
    type: "text",
    className: inputClass.email ? "inputError" : "input",
    defaultValue: "",
    placeholder: ""
  });
  const [categoryId, CategorySelectInput] = Inputs.UseSelectCategory({
    placeholder: "Please select category",
    options: categoryList,
    feildName: "sector",
    secendInputError: secendInputError
  });
  // handling dynamic data
  useEffect(() => {
    const fetchData = async () => {
      const BenchMarkingsData = BenchMarkingQuery?.data?.benchMarkings?.data;
      const uniqueSectorIds = Array.from(new Set(BenchMarkingsData?.map(record => record?.attributes?.Sector_Id?.data?.id)));
      if (uniqueSectorIds?.length) {
        const sectorsQuery = await GetSectorByIds({
          "filter": {
            "id": {
              "in": uniqueSectorIds
            }
          }
        });
        const sectorsData = sectorsQuery?.data?.sectors?.data;
        if (sectors?.length < 1 && sectorsData?.length > 0) {
          let array = [];
          for (let key in sectorsData) {
            const prop = sectorsData[key];
            array.push({
              id: prop?.id,
              name: prop?.attributes?.Sector_Name,
              shId: prop?.attributes?.SharePointId,
              File_Url: prop?.attributes?.File_Url ?? ''
            });
          }
          setSectors(array);
        }
      }
      const countriesData = countriesQuery?.data?.countries?.data;
      if (countries?.length < 1 && countriesData?.length > 0) {
        let array = [...countries];
        for (let key in countriesData) {
          const prop = countriesData[key];
          array.push({
            id: prop?.id,
            name: prop?.attributes?.Country_Name,
            shId: prop?.attributes?.SharePointId,
          });
        }
        setCountries(array);
      }
      let citiesArray = [];
      for (let key in citiesData) {
        const prop = citiesData[key];
        const list = countries?.find((e) => e.id === country);
        if (String(list.shId) === String(prop?.attributes?.CountryId)) {
          citiesArray.push({
            id: prop?.id,
            name: prop?.attributes?.City_Name,
            shId: prop?.attributes?.SharePointId,
            cId: prop?.attributes?.CountryId,
          });
        }
      }
      setCities(citiesArray);
    };
    fetchData(); // Call the async function immediately
  }, [countriesQuery, citiesData, BenchMarkingQuery]);
  const handleYearUpClick = (e) => {
    setTimeLineYear((prevValue) => {
      const newValue = parseInt(prevValue) + 1;
      return Math.min(newValue, 12).toString();
    });
  };
  const handleYearDownClick = (e) => {
    setTimeLineYear((prevValue) => Math.max(prevValue - 1, 0).toString());
  };
  const handleMonthUpClick = (e) => {
    setTimeLineMonth((prevValue) => {
      const newMonthValue = parseInt(prevValue) + 1;
      return Math.min(newMonthValue, 12).toString();
    });
  };
  const handleMonthDownClick = (e) => {
    setTimeLineMonth((prevValue) => Math.max(prevValue - 1, 0).toString());
  };
  const [running, setRunning] = useState(false);
  // // // its getting Goals data using Category Id's
  const asynceFuncForUseEffect = async () => {
    setRunning(true);
    // let arrayOfGoals = [...goals];
    let arrayOfGoals = [];// for refetch sector
    if (categoryList && categoryList.length) {
      setLoading(true);// for refetch sector
      for (let categoryKey in categoryList) {
        const _categoryId = categoryList[categoryKey].id;
        const sectorData = sectors.find(e => e.id === sector);
        const res = await fetchGoalsIdByCategory({
          "filter": {
            "Is_Active": {
              "eq": true
            },
            "Is_Selected": {
              "eq": true
            },
            "Category_Id": {
              "SharePointId": {
                "eq": categoryList[categoryKey].shId
              }
            },
            "Sector_Id": {
              "SharePointId": {
                "eq": sectorData?.shId
              }
            }
          }
        });
        // // // its containing all goals data get by category id
        if (res?.data?.benchMarkings?.data) {
          const GoalIdsArr = res?.data?.benchMarkings?.data || false;
          if (GoalIdsArr) {
            const list = await GoalIdsArr.filter((e) => e.attributes.Is_Selected === true);
            if (list) {
              const listd = await list.map((e) => e.attributes.Goal_Id.data.attributes.SharePointId);
              // // // its getting goals data by available goals id
              const res2 = await filterByGoals({
                "filter": {
                  "SharePointId": {
                    "in": listd
                  }
                }
              });
              // // // its containing all goals data get by available goals id
              if (res2?.data?.goals?.data) {
                const GoalsByIdsArr = res2?.data?.goals?.data || false;
                //  // // ====================================================
                if (GoalsByIdsArr) {
                  for (let key in GoalsByIdsArr) {
                    // // // listing screening values
                    const SCPercentage = GoalIdsArr.find((e) => e.attributes.Goal_Id.data.id === GoalsByIdsArr[key].id);
                    const percentage = await GoalIdsArr?.find((e) => e.attributes.Goal_Id.data.attributes.SharePointId === GoalsByIdsArr[key].attributes.SharePointId);
                    let dontHave;
                    if (arrayOfGoals?.length) {
                      dontHave = await arrayOfGoals.find((e) => e.categoryId === _categoryId && e.id === GoalsByIdsArr[key].id);
                    }
                    if (!dontHave) {
                      // // // its containing all Sub goals data by Goal id
                      const sub_Goals = await filterSubGaols({
                        "filter": {
                          "Is_Active": {
                            "eq": true
                          },
                          "goalId": {
                            "id": {
                              "in": GoalsByIdsArr[key].id
                            }
                          }
                        }
                      });
                      const makeSubGoalsAndDesc = [];
                      // // // its containing all sub goals data get by available goal id's
                      if (sub_Goals?.data) {
                        const sub_Goal_list = sub_Goals?.data?.subGoals.data;
                        if (sub_Goal_list?.length) {
                          for (let SG in sub_Goal_list) {
                            // // // its geting all sub goals description data get by available sub goal id
                            const sub_Goals_Desc = await filterSubGaolsDesc({
                              "filter": {
                                "Is_Active": {
                                  "eq": true
                                },
                                "sub_goal_id": {
                                  "id": {
                                    "in": sub_Goal_list[SG]?.id
                                  }
                                }
                              }
                            });
                            const makeItSubDesc = [];
                            // // // its containing all sub goals description data get by available sub goal id's
                            if (sub_Goals_Desc?.data) {
                              const sub_Goals_Desc_List = sub_Goals_Desc?.data?.subGoalDescriptions?.data;
                              for (let SGD in sub_Goals_Desc_List) {
                                makeItSubDesc.push({
                                  Title: sub_Goals_Desc_List[SGD]?.attributes.Title,
                                  SharePointId: sub_Goals_Desc_List[SGD]?.attributes.SharePointId,
                                  sub_goal_id: sub_Goals_Desc_List[SGD]?.attributes?.sub_goal_id?.data.id,
                                  id: sub_Goals_Desc_List[SGD]?.id,
                                  Criteria_Type: sub_Goals_Desc_List[SGD]?.attributes?.Criteria_Type ?? null,
                                  Criteria_Type_Id: sub_Goals_Desc_List[SGD]?.attributes?.Criteria_Type_Id ?? null,
                                  completed: false,
                                  comment: null,
                                  CategorySequence: sub_Goals_Desc_List[SGD]?.attributes.CategorySequence,
                                });
                              }
                            }
                            let subPer = Number(percentage?.attributes.Percentage) / sub_Goal_list?.length;
                            const sortedSubDesc = makeItSubDesc.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
                            makeSubGoalsAndDesc.push({
                              CategorySequence: sub_Goal_list[SG]?.attributes.CategorySequence,
                              SharePointId: sub_Goal_list[SG]?.attributes.SharePointId,
                              Title: sub_Goal_list[SG]?.attributes.Title,
                              id: sub_Goal_list[SG]?.id,
                              goalId: sub_Goal_list[SG]?.attributes?.goalId?.data?.id,
                              completed: false,
                              subGoalDescList: sortedSubDesc,
                              subGoalPercentage: subPer?.toFixed(2),
                            });
                          }
                        }
                      }
                      const sortedArray = makeSubGoalsAndDesc.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
                      arrayOfGoals.push({
                        Description: GoalsByIdsArr[key].attributes.Description,
                        SharePointId: GoalsByIdsArr[key].attributes.SharePointId,
                        Criteria: GoalsByIdsArr[key].attributes.Criteria,
                        Title: GoalsByIdsArr[key].attributes.Title,
                        id: GoalsByIdsArr[key].id,
                        completed: false,
                        categoryId: _categoryId,
                        categoryName: categoryList[categoryKey].name,
                        comment: "",
                        percentage: Number(percentage?.attributes?.Percentage)?.toFixed(2),
                        haveSubGoal: sortedArray?.length ? true : false,
                        subGaolsList: sortedArray,
                        ScreeningPercentage: SCPercentage?.attributes.ScreeningPercentage,
                        CategorySequence: SCPercentage?.attributes.CategorySequence,
                        Criteria_Type: SCPercentage?.attributes.Criteria_Type,
                        Criteria_Type_Id: SCPercentage?.attributes.Criteria_Type_Id,
                        ScreeningPerValue: 0
                      });
                    }
                  }
                }
              }
            }
          }
        };
      }
    }
    const sortedArray = arrayOfGoals.sort((a, b) => (a.CategorySequence || Infinity) - (b.CategorySequence || Infinity));
    setGoals(sortedArray);
    setLoading(false);// for refetch sector
    setRunning(false);
  };
  useEffect(() => {
    if (categoryList.length && !running) {
      asynceFuncForUseEffect();
    }
  }, [categoryList]);
  // handle toggle button for completed mark by id
  const goalCompleteMark = (value) => {
    const copy = [...goals];
    copy[value].completed = !copy[value].completed;
    setGoals(copy);
  };
  const goalCompleteMarkInNested = (level1stIndex, level2ndIndex, level3rdIndex) => {
    const copy = [...goals];
    copy[level1stIndex].subGaolsList[level2ndIndex].subGoalDescList[level3rdIndex].completed = !copy[level1stIndex].subGaolsList[level2ndIndex].subGoalDescList[level3rdIndex].completed;
    // // handling nested sub goal completed mark
    for (let key in copy) {
      if (copy[key].haveSubGoal) {
        const SBGList = [...copy[key].subGaolsList];
        let trueCount = 0;
        for (let sb in SBGList) {
          const SBGDList = [...SBGList[sb].subGoalDescList];
          const finds = SBGDList.map((e) => e.completed);
          const found = finds.find(e => e === true);
          if (found) {
            SBGList[sb].completed = true;
            trueCount++;
          } else {
            SBGList[sb].completed = false;
          }
        }
        // if (trueCount === SBGList.length) {
        //   copy[key].completed = true;
        // } else {
        //   copy[key].completed = false;
        // }
      }
    }
    setGoals(copy);
  };
  const goalCommentHandlerInNested = (e, level1stIndex, level2ndIndex, level3rdIndex) => {
    const copy = [...goals];
    copy[level1stIndex].subGaolsList[level2ndIndex].subGoalDescList[level3rdIndex].comment = e.target.value;
    setGoals(copy);
  };
  // handle goals comment by id
  const goalCommentHandler = (e, id) => {
    const copy = [...goals];
    copy[id].comment = e.target.value;
    setGoals(copy);
  };
  // handle goals comment by id
  const handleScreeningPersValue = (e, id) => {
    const copy = [...goals];
    if (e.target.value > 100 || e.target.value < 0) {
      return false;
    }
    copy[id].ScreeningPerValue = e.target.value;
    if (Number(e.target.value) >= Number(copy[id].ScreeningPercentage)) {
      copy[id].completed = true;
    }
    if (copy[id].completed && Number(e.target.value) < Number(copy[id].ScreeningPercentage)) {
      copy[id].completed = false;
    }
    setGoals(copy);
  };
  const [files, attch] = useState([]);
  const newProjectData = {
    pName,
    sector,
    country,
    city,
    IRR,
    ROI,
    gp_margin,
    net_profit_margin,
    timeLineYear,
    timeLineMonth,
    project_cost,
    contect_person,
    mobile_no,
    email,
    currencyID,
    filesData: files,
    eBIT_margin,
    sectorName
  };
  const projectGoals = {
    pName,
    sector,
    categoryId,
    goals
  };
  const handleFileSelect = (selectedFiles) => {
    attch(selectedFiles);
  };
  useEffect(() => {
    setNewProject(newProjectData);
    setProjectGoals(projectGoals);
  }, [files, pName, sector, currencyID, country, city, IRR, ROI, gp_margin, eBIT_margin, net_profit_margin, timeLineYear, timeLineMonth, project_cost, contect_person, mobile_no, email, categoryId, goals]);
  const steps = {
    First: (
      <ProjectForm
        handleFileSelect={handleFileSelect}
        projectInput={projectInput}
        sectorSelect={sectorSelect}
        countrySelect={countrySelect}
        citySelect={citySelect}
        irrInput={irrInput}
        roiInput={roiInput}
        gpMarginInput={gpMarginInput}
        EBITMarginInput={EBITMarginInput}
        netProfitMarginInput={netProfitMarginInput}
        timeLineYearInput={timeLineYearInput}
        timeLineMonthInput={timeLineMonthInput}
        contectPersonInput={contectPersonInput}
        mobileNoInput={mobileNoInput}
        emailInput={emailInput}
        handleYearUpClick={handleYearUpClick}
        handleYearDownClick={handleYearDownClick}
        handleMonthUpClick={handleMonthUpClick}
        handleMonthDownClick={handleMonthDownClick}
        currencySelectInput={currencySelectInput}
        inputDiv={inputDiv}
      />
    ),
    Second: <GoalsAndDescription
      sectorsList={sectors}
      projectDetails={newProjectData}
      CategorySelectInput={CategorySelectInput}
      categoryId={categoryId}
      goals={goals}
      categoryList={categoryList}
      loadingFlag={loadingFlag}
      goalCompleteMark={goalCompleteMark}
      goalCommentHandler={goalCommentHandler}
      handleScreeningPersValue={handleScreeningPersValue}
      goalCommentHandlerInNested={goalCommentHandlerInNested}
      goalCompleteMarkInNested={goalCompleteMarkInNested}
    />,
    Third: <OverView
      setMainPercentage={setMainPercentage}
      mainPercentage={mainPercentage}
      sectorsList={sectors}
      categories={categoryList}
      projectDetails={newProjectData}
      projectGoals={projectGoals}
    />,
  };
  return <div className="data">{steps[step]}</div>;// for refetch sector
};

export default Tabs;
